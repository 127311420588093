import React from 'react';
import UndoIcon from '@mui/icons-material/Undo';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
function Numpad(props) {


  return (
    <>
    {props.numpadvisible &&
    <div className='numpad'>
      <div className='flex flex-row w-[100%] space-x-1'>
        <button className='p-3 rounded bg-purple-800 w-[100%] border-[1px] border-slate-400 hover:bg-purple-900' value="1" onClick={props.handleNumpad}>1</button>
        <button className='p-3 rounded bg-purple-800 w-[100%] border-[1px] border-slate-400 hover:bg-purple-900' value="2" onClick={props.handleNumpad}>2</button>
        <button className='p-3 rounded bg-purple-800 w-[100%] border-[1px] border-slate-400 hover:bg-purple-900' value="3" onClick={props.handleNumpad}>3</button>
      </div>
      <div className='flex flex-row w-[100%] space-x-1'>
        <button className='p-3 rounded bg-purple-800 w-[100%] border-[1px] border-slate-400 hover:bg-purple-900' value="4" onClick={props.handleNumpad}>4</button>
        <button className='p-3 rounded bg-purple-800 w-[100%] border-[1px] border-slate-400 hover:bg-purple-900' value="5" onClick={props.handleNumpad}>5</button>
        <button className='p-3 rounded bg-purple-800 w-[100%] border-[1px] border-slate-400 hover:bg-purple-900' value="6" onClick={props.handleNumpad}>6</button>
      </div>
      <div className='flex flex-row w-[100%] space-x-1'>
        <button className='p-3 rounded bg-purple-800 w-[100%] border-[1px] border-slate-400 hover:bg-purple-900' value="7" onClick={props.handleNumpad}>7</button>
        <button className='p-3 rounded bg-purple-800 w-[100%] border-[1px] border-slate-400 hover:bg-purple-900' value="8" onClick={props.handleNumpad}>8</button>
        <button className='p-3 rounded bg-purple-800 w-[100%] border-[1px] border-slate-400 hover:bg-purple-900' value="9" onClick={props.handleNumpad}>9</button>
      </div>
      <div className='flex flex-row w-[100%] space-x-1'>
        <button className='p-3 rounded bg-red-800 w-[100%] border-[1px] border-slate-400 hover:bg-red-900 flex flex-row' onClick={props.handleCancelLastest}><UndoIcon/>
        <span className='md:block hidden'>ยกเลิกรายการล่าสุด</span>
        </button>
        <button className='p-3 rounded bg-purple-800 w-[100%] border-[1px] border-slate-400 hover:bg-purple-900' value="0" onClick={props.handleNumpad}>0</button>
        <button className='p-3 rounded bg-red-800 w-[100%] border-[1px] border-slate-400 hover:bg-red-900' onClick={props.handleundo} ><KeyboardBackspaceIcon /></button>
      </div>
    </div>
      }
    </>
  );
}
export default Numpad