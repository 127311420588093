import React, { useState } from 'react'
import { Button } from '@mui/material';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function Lottotypeformodal(props) {

  const handleDelete = props.handleDelete;
  const handleChange = props.handleChange;

  return (
    (
      <div className='flex flex-col space-y-2 border-dashed border-b-[1px] border-slate-400'>
        {/* makeall this in the same row */}

        {props.grouparray.run_top &&
          <div>
            <div className='flex flex-row frex-wrap w-[100%] h-[40px] bg-slate-100'>
              <div className='md:md:w-[3%] w-[10%] w-[10%] text-left p-1'>
                <span className='text-black font-bold'>#</span>
              </div>
              <div className='md:w-[45%] w-[30%] text-left p-1'>
                <span className='text-red-500 font-bold'>วิ่งบน</span>
              </div>
              <div className='md:flex-1 md:w-[100%] flex-basis w-[22%] text-left p-1'>
                <span className='text-blue-500 font-bold'>อัตราคูณ</span>
              </div>
              <div className='flex-1 text-left p-1'>
                <span className='text-slate-600 font-bold'>เรทชนะ</span>
              </div>
            </div>
            {props.grouparray.run_top.map((data ,index) => {
              return (
                <div key={data.id}>
                  <div className='flex flex-row frex-wrap w-[100%] h-[40px] bg-white'>
                    <div className='md:md:w-[3%] w-[10%] w-[10%] bg-slate-300 text-center p-2'>
                      <span className='text-black font-bold'>{index+1}.</span>
                    </div>
                    <div className='md:w-[45%] w-[30%] text-left p-1 flex flex-row space-x-1'>
                      <div className='bg-green-500 px-[0.5rem] p-1'>
                        <span className='text-white font-bold'>{data.number}</span>
                      </div>
                      <input 
                      type='text' 
                      className='w-[80%] border-[1px] border-black rounded p-1'
                        value={data.bet_amout === 0 ? '' : data.bet_amout} // If bet_amout is 0, show an empty string as value
  placeholder={data.bet_amout === 0 ? 'ใส่ราคา' : ''} 
                      onChange={(e) => handleChange(e, data.id)} 
                      min={1} 
                      ></input>
                    </div>
                    <div className='md:flex-1 md:w-[100%] flex-basis w-[15%] text-left p-1'>
                      <span className='text-black font-bold'>{data.bet_payrate}</span>
                    </div>
                    <div className='flex-1 text-left p-1 flex flex-row space-x-1'>
                      <input type='text' readonly="readonly" value={data.bet_amout * data.bet_payrate} className='bg-slate-300 w-[80%] border-[1px] border-black rounded p-1'></input>
                      <button onClick={() => handleDelete(data.id)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-[0.125rem] px-4 rounded">
                        <DeleteForeverIcon />
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
            {/* ////////////////////////////////////////////////////////////// */}

          </div>
        }

        {props.grouparray.run_bot &&
          <div>
            <div className='flex flex-row frex-wrap w-[100%] h-[40px] bg-slate-100'>
              <div className='md:w-[3%] w-[10%] text-left p-1'>
                <span className='text-black font-bold'>#</span>
              </div>
              <div className='md:w-[45%] w-[30%] text-left p-1'>
                <span className='text-red-500 font-bold'>วิ่งล่าง</span>
              </div>
              <div className='md:flex-1 md:w-[100%] flex-basis w-[22%] text-left p-1'>
                <span className='text-blue-500 font-bold'>อัตราคูณ</span>
              </div>
              <div className='flex-1 text-left p-1'>
                <span className='text-slate-600 font-bold'>เรทชนะ</span>
              </div>
            </div>
            {props.grouparray.run_bot.map((data, index) => {
              return (
                <div key={data.id}>
                  <div className='flex flex-row frex-wrap w-[100%] h-[40px] bg-white'>
                    <div className='md:w-[3%] w-[10%] bg-slate-300 text-center p-2'>
                      <span className='text-black font-bold'>{index + 1}.</span>
                    </div>
                    <div className='md:w-[45%] w-[30%] text-left p-1 flex flex-row space-x-1'>
                      <div className='bg-green-500 px-[0.5rem] p-1'>
                        <span className='text-white font-bold'>{data.number}</span>
                      </div>
                      <input
                        type='text'
                        className='w-[80%] border-[1px] border-black rounded p-1'
                          value={data.bet_amout === 0 ? '' : data.bet_amout} // If bet_amout is 0, show an empty string as value
  placeholder={data.bet_amout === 0 ? 'ใส่ราคา' : ''}
                        onChange={(e) => handleChange(e, data.id)}
                        min={1}
                      ></input>
                    </div>
                    <div className='md:flex-1 md:w-[100%] flex-basis w-[15%] text-left p-1'>
                      <span className='text-black font-bold'>{data.bet_payrate}</span>
                    </div>
                    <div className='flex-1 text-left p-1 flex flex-row space-x-1'>
                      <input type='text' readonly="readonly" value={data.bet_amout * data.bet_payrate} className='bg-slate-300 w-[80%] border-[1px] border-black rounded p-1'></input>
                      <button onClick={() => handleDelete(data.id)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-[0.125] px-4 rounded">
                        <DeleteForeverIcon />
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
            {/* ////////////////////////////////////////////////////////////// */}

          </div>
        }

        {props.grouparray.two_top &&
          <div>
            <div className='flex flex-row frex-wrap w-[100%] h-[40px] bg-slate-100'>
              <div className='md:w-[3%] w-[10%] text-left p-1'>
                <span className='text-black font-bold'>#</span>
              </div>
              <div className='md:w-[45%] w-[30%] text-left p-1'>
                <span className='text-red-500 font-bold'>สองตัวบน</span>
              </div>
              <div className='md:flex-1 md:w-[100%] flex-basis w-[22%] text-left p-1'>
                <span className='text-blue-500 font-bold'>อัตราคูณ</span>
              </div>
              <div className='flex-1 text-left p-1'>
                <span className='text-slate-600 font-bold'>เรทชนะ</span>
              </div>
            </div>
            {props.grouparray.two_top.map((data, index) => {
              return (
                <div key={data.id}>
                  <div className='flex flex-row frex-wrap w-[100%] h-[40px] bg-white'>
                    <div className='md:w-[3%] w-[10%] bg-slate-300 text-center p-2'>
                      <span className='text-black font-bold'>{index + 1}.</span>
                    </div>
                    <div className='md:w-[45%] w-[30%] text-left p-1 flex flex-row space-x-1'>
                      <div className='bg-green-500 px-[0.5rem] p-1'>
                        <span className='text-white font-bold'>{data.number}</span>
                      </div>
                      <input
                        type='text'
                        className='w-[80%] border-[1px] border-black rounded p-1'
                          value={data.bet_amout === 0 ? '' : data.bet_amout} // If bet_amout is 0, show an empty string as value
  placeholder={data.bet_amout === 0 ? 'ใส่ราคา' : ''}
                        onChange={(e) => handleChange(e, data.id)}
                        min={1}
                      ></input>
                    </div>
                    <div className='md:flex-1 md:w-[100%] flex-basis w-[15%] text-left p-1'>
                      <span className='text-black font-bold'>{data.bet_payrate}</span>
                    </div>
                    <div className='flex-1 text-left p-1 flex flex-row space-x-1'>
                      <input type='text' readonly="readonly" value={data.bet_amout * data.bet_payrate} className='bg-slate-300 w-[80%] border-[1px] border-black rounded p-1'></input>
                      <button onClick={() => handleDelete(data.id)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-[0.125] px-4 rounded">
                        <DeleteForeverIcon />
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
            {/* ////////////////////////////////////////////////////////////// */}

          </div>
        }


        {/* twobot */}

        {props.grouparray.two_bot &&
          <div>
            <div className='flex flex-row frex-wrap w-[100%] h-[40px] bg-slate-100'>
              <div className='md:w-[3%] w-[10%] text-left p-1'>
                <span className='text-black font-bold'>#</span>
              </div>
              <div className='md:w-[45%] w-[30%] text-left p-1'>
                <span className='text-red-500 font-bold'>สองตัวล่าง</span>
              </div>
              <div className='md:flex-1 md:w-[100%] flex-basis w-[22%] text-left p-1'>
                <span className='text-blue-500 font-bold'>อัตราคูณ</span>
              </div>
              <div className='flex-1 text-left p-1'>
                <span className='text-slate-600 font-bold'>เรทชนะ</span>
              </div>
            </div>
            {props.grouparray.two_bot.map((data, index) => {
              return (
                <div key={data.id}>
                  <div className='flex flex-row frex-wrap w-[100%] h-[40px] bg-white'>
                    <div className='md:w-[3%] w-[10%] bg-slate-300 text-center p-2'>
                      <span className='text-black font-bold'>{index + 1}.</span>
                    </div>
                    <div className='md:w-[45%] w-[30%] text-left p-1 flex flex-row space-x-1'>
                      <div className='bg-green-500 px-[0.5rem] p-1'>
                        <span className='text-white font-bold'>{data.number}</span>
                      </div>
                      <input
                        type='text'
                        className='w-[80%] border-[1px] border-black rounded p-1'
                          value={data.bet_amout === 0 ? '' : data.bet_amout} // If bet_amout is 0, show an empty string as value
  placeholder={data.bet_amout === 0 ? 'ใส่ราคา' : ''}
                        onChange={(e) => handleChange(e, data.id)}
                        min={1}
                      ></input>
                    </div>
                    <div className='md:flex-1 md:w-[100%] flex-basis w-[15%] text-left p-1'>
                      <span className='text-black font-bold'>{data.bet_payrate}</span>
                    </div>
                    <div className='flex-1 text-left p-1 flex flex-row space-x-1'>
                      <input type='text' readonly="readonly" value={data.bet_amout * data.bet_payrate} className='bg-slate-300 w-[80%] border-[1px] border-black rounded p-1'></input>
                      <button onClick={() => handleDelete(data.id)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-[0.125] px-4 rounded">
                        <DeleteForeverIcon />
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
            {/* ////////////////////////////////////////////////////////////// */}

          </div>
        }


        {/* three top */}

        {props.grouparray.three_top &&
          <div>
            <div className='flex flex-row frex-wrap w-[100%] h-[40px] bg-slate-100'>
              <div className='md:w-[3%] w-[10%] text-left p-1'>
                <span className='text-black font-bold'>#</span>
              </div>
              <div className='md:w-[45%] w-[30%] text-left p-1'>
                <span className='text-red-500 font-bold'>สามตัวบน</span>
              </div>
              <div className='md:flex-1 md:w-[100%] flex-basis w-[22%] text-left p-1'>
                <span className='text-blue-500 font-bold'>อัตราคูณ</span>
              </div>
              <div className='flex-1 text-left p-1'>
                <span className='text-slate-600 font-bold'>เรทชนะ</span>
              </div>
            </div>
            {props.grouparray.three_top.map((data, index) => {
              return (
                <div key={data.id}>
                  <div className='flex flex-row frex-wrap w-[100%] h-[40px] bg-white'>
                    <div className='md:w-[3%] w-[10%] bg-slate-300 text-center p-2'>
                      <span className='text-black font-bold'>{index + 1}.</span>
                    </div>
                    <div className='md:w-[45%] w-[30%] text-left p-1 flex flex-row space-x-1'>
                      <div className='bg-green-500 px-[0.5rem] p-1'>
                        <span className='text-white font-bold'>{data.number}</span>
                      </div>
                      <input
                        type='text'
                        className='w-[80%] border-[1px] border-black rounded p-1'
                          value={data.bet_amout === 0 ? '' : data.bet_amout} // If bet_amout is 0, show an empty string as value
  placeholder={data.bet_amout === 0 ? 'ใส่ราคา' : ''}
                        onChange={(e) => handleChange(e, data.id)}
                        min={1}
                      ></input>
                    </div>
                    <div className='md:flex-1 md:w-[100%] flex-basis w-[15%] text-left p-1'>
                      <span className='text-black font-bold'>{data.bet_payrate}</span>
                    </div>
                    <div className='flex-1 text-left p-1 flex flex-row space-x-1'>
                      <input type='text' readonly="readonly" value={data.bet_amout * data.bet_payrate} className='bg-slate-300 w-[80%] border-[1px] border-black rounded p-1'></input>
                      <button onClick={() => handleDelete(data.id)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-[0.125] px-4 rounded">
                        <DeleteForeverIcon />
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
            {/* ////////////////////////////////////////////////////////////// */}

          </div>
        }

        {/* three front */}

        {props.grouparray.three_front &&
          <div>
            <div className='flex flex-row frex-wrap w-[100%] h-[40px] bg-slate-100'>
              <div className='md:w-[3%] w-[10%] text-left p-1'>
                <span className='text-black font-bold'>#</span>
              </div>
              <div className='md:w-[45%] w-[30%] text-left p-1'>
                <span className='text-red-500 font-bold'>สามตัวหน้า</span>
              </div>
              <div className='md:flex-1 md:w-[100%] flex-basis w-[22%] text-left p-1'>
                <span className='text-blue-500 font-bold'>อัตราคูณ</span>
              </div>
              <div className='flex-1 text-left p-1'>
                <span className='text-slate-600 font-bold'>เรทชนะ</span>
              </div>
            </div>
            {props.grouparray.three_front.map((data, index) => {
              return (
                <div key={data.id}>
                  <div className='flex flex-row frex-wrap w-[100%] h-[40px] bg-white'>
                    <div className='md:w-[3%] w-[10%] bg-slate-300 text-center p-2'>
                      <span className='text-black font-bold'>{index + 1}.</span>
                    </div>
                    <div className='md:w-[45%] w-[30%] text-left p-1 flex flex-row space-x-1'>
                      <div className='bg-green-500 px-[0.5rem] p-1'>
                        <span className='text-white font-bold'>{data.number}</span>
                      </div>
                      <input
                        type='text'
                        className='w-[80%] border-[1px] border-black rounded p-1'
                          value={data.bet_amout === 0 ? '' : data.bet_amout} // If bet_amout is 0, show an empty string as value
  placeholder={data.bet_amout === 0 ? 'ใส่ราคา' : ''}
                        onChange={(e) => handleChange(e, data.id)}
                        min={1}
                      ></input>
                    </div>
                    <div className='md:flex-1 md:w-[100%] flex-basis w-[15%] text-left p-1'>
                      <span className='text-black font-bold'>{data.bet_payrate}</span>
                    </div>
                    <div className='flex-1 text-left p-1 flex flex-row space-x-1'>
                      <input type='text' readonly="readonly" value={data.bet_amout * data.bet_payrate} className='bg-slate-300 w-[80%] border-[1px] border-black rounded p-1'></input>
                      <button onClick={() => handleDelete(data.id)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-[0.125] px-4 rounded">
                        <DeleteForeverIcon />
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
            {/* ////////////////////////////////////////////////////////////// */}

          </div>
        }

        {/* three bot */}

        {props.grouparray.three_bot &&
          <div>
            <div className='flex flex-row frex-wrap w-[100%] h-[40px] bg-slate-100'>
              <div className='md:w-[3%] w-[10%] text-left p-1'>
                <span className='text-black font-bold'>#</span>
              </div>
              <div className='md:w-[45%] w-[30%] text-left p-1'>
                <span className='text-red-500 font-bold'>สามตัวหลัง</span>
              </div>
              <div className='md:flex-1 md:w-[100%] flex-basis w-[22%] text-left p-1'>
                <span className='text-blue-500 font-bold'>อัตราคูณ</span>
              </div>
              <div className='flex-1 text-left p-1'>
                <span className='text-slate-600 font-bold'>เรทชนะ</span>
              </div>
            </div>
            {props.grouparray.three_bot.map((data, index) => {
              return (
                <div key={data.id}>
                  <div className='flex flex-row frex-wrap w-[100%] h-[40px] bg-white'>
                    <div className='md:w-[3%] w-[10%] bg-slate-300 text-center p-2'>
                      <span className='text-black font-bold'>{index + 1}.</span>
                    </div>
                    <div className='md:w-[45%] w-[30%] text-left p-1 flex flex-row space-x-1'>
                      <div className='bg-green-500 px-[0.5rem] p-1'>
                        <span className='text-white font-bold'>{data.number}</span>
                      </div>
                      <input
                        type='text'
                        className='w-[80%] border-[1px] border-black rounded p-1'
                          value={data.bet_amout === 0 ? '' : data.bet_amout} // If bet_amout is 0, show an empty string as value
  placeholder={data.bet_amout === 0 ? 'ใส่ราคา' : ''}
                        onChange={(e) => handleChange(e, data.id)}
                        min={1}
                      ></input>
                    </div>
                    <div className='md:flex-1 md:w-[100%] flex-basis w-[15%] text-left p-1'>
                      <span className='text-black font-bold'>{data.bet_payrate}</span>
                    </div>
                    <div className='flex-1 text-left p-1 flex flex-row space-x-1'>
                      <input type='text' readonly="readonly" value={data.bet_amout * data.bet_payrate} className='bg-slate-300 w-[80%] border-[1px] border-black rounded p-1'></input>
                      <button onClick={() => handleDelete(data.id)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-[0.125] px-4 rounded">
                        <DeleteForeverIcon />
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
            {/* ////////////////////////////////////////////////////////////// */}

          </div>
        }

        {/* three bot */}

        {props.grouparray.three_tod &&
          <div>
            <div className='flex flex-row frex-wrap w-[100%] h-[40px] bg-slate-100'>
              <div className='md:w-[3%] w-[10%] text-left p-1'>
                <span className='text-black font-bold'>#</span>
              </div>
              <div className='md:w-[45%] w-[30%] text-left p-1'>
                <span className='text-red-500 font-bold'>สามตัวโต๊ด</span>
              </div>
              <div className='md:flex-1 md:w-[100%] flex-basis w-[22%] text-left p-1'>
                <span className='text-blue-500 font-bold'>อัตราคูณ</span>
              </div>
              <div className='flex-1 text-left p-1'>
                <span className='text-slate-600 font-bold'>เรทชนะ</span>
              </div>
            </div>
            {props.grouparray.three_tod.map((data, index) => {
              return (
                <div key={data.id}>
                  <div className='flex flex-row frex-wrap w-[100%] h-[40px] bg-white'>
                    <div className='md:w-[3%] w-[10%] bg-slate-300 text-center p-2'>
                      <span className='text-black font-bold'>{index + 1}.</span>
                    </div>
                    <div className='md:w-[45%] w-[30%] text-left p-1 flex flex-row space-x-1'>
                      <div className='bg-green-500 px-[0.5rem] p-1'>
                        <span className='text-white font-bold'>{data.number}</span>
                      </div>
                      <input
                        type='text'
                        className='w-[80%] border-[1px] border-black rounded p-1'
                          value={data.bet_amout === 0 ? '' : data.bet_amout} // If bet_amout is 0, show an empty string as value
  placeholder={data.bet_amout === 0 ? 'ใส่ราคา' : ''}
                        onChange={(e) => handleChange(e, data.id)}
                        min={1}
                      ></input>
                    </div>
                    <div className='md:flex-1 md:w-[100%] flex-basis w-[15%] text-left p-1'>
                      <span className='text-black font-bold'>{data.bet_payrate}</span>
                    </div>
                    <div className='flex-1 text-left p-1 flex flex-row space-x-1'>
                      <input type='text' readonly="readonly" value={data.bet_amout * data.bet_payrate} className='bg-slate-300 w-[80%] border-[1px] border-black rounded p-1'></input>
                      <button onClick={() => handleDelete(data.id)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-[0.125] px-4 rounded">
                        <DeleteForeverIcon />
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
            {/* ////////////////////////////////////////////////////////////// */}

          </div>
        }


        {/* four top */}

        {props.grouparray.four_top &&
          <div>
            <div className='flex flex-row frex-wrap w-[100%] h-[40px] bg-slate-100'>
              <div className='md:w-[3%] w-[10%] text-left p-1'>
                <span className='text-black font-bold'>#</span>
              </div>
              <div className='md:w-[45%] w-[30%] text-left p-1'>
                <span className='text-red-500 font-bold'>สี่ตัวบน</span>
              </div>
              <div className='md:flex-1 md:w-[100%] flex-basis w-[22%] text-left p-1'>
                <span className='text-blue-500 font-bold'>อัตราคูณ</span>
              </div>
              <div className='flex-1 text-left p-1'>
                <span className='text-slate-600 font-bold'>เรทชนะ</span>
              </div>
            </div>
            {props.grouparray.four_top.map((data, index) => {
              return (
                <div key={data.id}>
                  <div className='flex flex-row frex-wrap w-[100%] h-[40px] bg-white'>
                    <div className='md:w-[3%] w-[10%] bg-slate-300 text-center p-2'>
                      <span className='text-black font-bold'>{index + 1}.</span>
                    </div>
                    <div className='md:w-[45%] w-[30%] text-left p-1 flex flex-row space-x-1'>
                      <div className='bg-green-500 px-[0.5rem] p-1'>
                        <span className='text-white font-bold'>{data.number}</span>
                      </div>
                      <input
                        type='text'
                        className='w-[80%] border-[1px] border-black rounded p-1'
                          value={data.bet_amout === 0 ? '' : data.bet_amout} // If bet_amout is 0, show an empty string as value
  placeholder={data.bet_amout === 0 ? 'ใส่ราคา' : ''}
                        onChange={(e) => handleChange(e, data.id)}
                        min={1}
                      ></input>
                    </div>
                    <div className='md:flex-1 md:w-[100%] flex-basis w-[15%] text-left p-1'>
                      <span className='text-black font-bold'>{data.bet_payrate}</span>
                    </div>
                    <div className='flex-1 text-left p-1 flex flex-row space-x-1'>
                      <input type='text' readonly="readonly" value={data.bet_amout * data.bet_payrate} className='bg-slate-300 w-[80%] border-[1px] border-black rounded p-1'></input>
                      <button onClick={() => handleDelete(data.id)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-[0.125] px-4 rounded">
                        <DeleteForeverIcon />
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
            {/* ////////////////////////////////////////////////////////////// */}

          </div>
        }

        {/* four tod */}

        {props.grouparray.four_tod &&
          <div>
            <div className='flex flex-row frex-wrap w-[100%] h-[40px] bg-slate-100'>
              <div className='md:w-[3%] w-[10%] text-left p-1'>
                <span className='text-black font-bold'>#</span>
              </div>
              <div className='md:w-[45%] w-[30%] text-left p-1'>
                <span className='text-red-500 font-bold'>สี่ตัวโต๊ด</span>
              </div>
              <div className='md:flex-1 md:w-[100%] flex-basis w-[22%] text-left p-1'>
                <span className='text-blue-500 font-bold'>อัตราคูณ</span>
              </div>
              <div className='flex-1 text-left p-1'>
                <span className='text-slate-600 font-bold'>เรทชนะ</span>
              </div>
            </div>
            {props.grouparray.four_tod.map((data, index) => {
              return (
                <div key={data.id}>
                  <div className='flex flex-row frex-wrap w-[100%] h-[40px] bg-white'>
                    <div className='md:w-[3%] w-[10%] bg-slate-300 text-center p-2'>
                      <span className='text-black font-bold'>{index + 1}.</span>
                    </div>
                    <div className='md:w-[45%] w-[30%] text-left p-1 flex flex-row space-x-1'>
                      <div className='bg-green-500 px-[0.5rem] p-1'>
                        <span className='text-white font-bold'>{data.number}</span>
                      </div>
                      <input
                        type='text'
                        className='w-[80%] border-[1px] border-black rounded p-1'
                          value={data.bet_amout === 0 ? '' : data.bet_amout} // If bet_amout is 0, show an empty string as value
  placeholder={data.bet_amout === 0 ? 'ใส่ราคา' : ''}
                        onChange={(e) => handleChange(e, data.id)}
                        min={1}
                      ></input>
                    </div>
                    <div className='md:flex-1 md:w-[100%] flex-basis w-[15%] text-left p-1'>
                      <span className='text-black font-bold'>{data.bet_payrate}</span>
                    </div>
                    <div className='flex-1 text-left p-1 flex flex-row space-x-1'>
                      <input type='text' readonly="readonly" value={data.bet_amout * data.bet_payrate} className='bg-slate-300 w-[80%] border-[1px] border-black rounded p-1'></input>
                      <button onClick={() => handleDelete(data.id)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-[0.125] px-4 rounded">
                        <DeleteForeverIcon />
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
            {/* ////////////////////////////////////////////////////////////// */}

          </div>
        }

        {/* first */}

        {props.grouparray.first &&
          <div>
            <div className='flex flex-row frex-wrap w-[100%] h-[40px] bg-slate-100'>
              <div className='md:w-[3%] w-[10%] text-left p-1'>
                <span className='text-black font-bold'>#</span>
              </div>
              <div className='md:w-[45%] w-[30%] text-left p-1'>
                <span className='text-red-500 font-bold'>รางวัลที่1</span>
              </div>
              <div className='md:flex-1 md:w-[100%] flex-basis w-[22%] text-left p-1'>
                <span className='text-blue-500 font-bold'>อัตราคูณ</span>
              </div>
              <div className='flex-1 text-left p-1'>
                <span className='text-slate-600 font-bold'>เรทชนะ</span>
              </div>
            </div>
            {props.grouparray.first.map((data, index) => {
              return (
                <div key={data.id}>
                  <div className='flex flex-row frex-wrap w-[100%] h-[40px] bg-white'>
                    <div className='md:w-[3%] w-[10%] bg-slate-300 text-center p-2'>
                      <span className='text-black font-bold'>{index + 1}.</span>
                    </div>
                    <div className='md:w-[45%] w-[30%] text-left p-1 flex flex-row space-x-1'>
                      <div className='bg-green-500 px-[0.5rem] p-1'>
                        <span className='text-white font-bold'>{data.number}</span>
                      </div>
                      <input
                        type='text'
                        className='w-[80%] border-[1px] border-black rounded p-1'
                          value={data.bet_amout === 0 ? '' : data.bet_amout} // If bet_amout is 0, show an empty string as value
  placeholder={data.bet_amout === 0 ? 'ใส่ราคา' : ''}
                        onChange={(e) => handleChange(e, data.id)}
                        min={1}
                      ></input>
                    </div>
                    <div className='md:flex-1 md:w-[100%] flex-basis w-[15%] text-left p-1'>
                      <span className='text-black font-bold'>{data.bet_payrate}</span>
                    </div>
                    <div className='flex-1 text-left p-1 flex flex-row space-x-1'>
                      <input type='text' readonly="readonly" value={data.bet_amout * data.bet_payrate} className='bg-slate-300 w-[80%] border-[1px] border-black rounded p-1'></input>
                      <button onClick={() => handleDelete(data.id)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-[0.125] px-4 rounded">
                        <DeleteForeverIcon />
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
            {/* ////////////////////////////////////////////////////////////// */}

          </div>
        }

        

      </div>
    )

  )
}

export default Lottotypeformodal