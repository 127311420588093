import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import LoadingComponent from './LoadingComponent'; // Replace with the correct path
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BadgeIcon from '@mui/icons-material/Badge';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import styled from '@mui/system/styled';
import { Box } from '@mui/system';


function Profile() {
  const user = localStorage.getItem('user');
  const [loading, setLoading] = useState(true); // Loading state
  const json = { user: user }
  const [profile, setProfile] = useState()

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_KEY}/profile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    })
      .then(data => data.json())
      .then(
        (result) => {
          setProfile(result[0])
          setLoading(false)
        }
      )
  }, []);
  // console.log(profile)


  const Item = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    border: '1px solid',
    borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
    padding: theme.spacing(1),
    borderRadius: '4px',
    textAlign: 'center',
  }));

  return (
    <motion.div
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
     className="flex flex-col w-[100%] h-[auto] justify-center">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className='flex flex-col justify-center'>
          <div className='w-[100%] flex flex-row h-[40px] p-2 bg-slate-700 space-x-3 border-[1px] border-slate-700 rounded-t'>
            <span className='text-white font-bold text-m'>ข้อมูลผู้ใช้งาน </span>
          </div>
          <div className='flex flex-row justify-center space-x-2 bg-slate-200 border-slate-900 border-[1px] p-2 w-[100%] h-[auto] drop-shadow-2xl'>
          {loading ? (
                    <LoadingComponent />
                  ) : (
                    <div className='w-[100%] bg-slate-100 border-r-[2px] border-slate-900 rounded'>
              <Box className="p-2 rounded">
                <Box sx={{ borderBottom: 1, mb: 2 }}>
                  <Typography className='font-bold text-left text-slate-700'>
                    <AccountCircleIcon />  Profile
                  </Typography>
                </Box>
                <Grid container spacing={3} sx={{ borderRadius: '16px' }}>
                  <Grid item xs={12} sm={6} >
                    <Typography className='font-bold text-left text-slate-700'>
                      <BadgeIcon fontSize="20" />  ชื่อ นามสกุล :
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <Typography className='font-bold text-left text-slate-700'>
                      <Item >{profile && profile.f_name}  {profile && profile.l_name}</Item>
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} >
                    <Typography className='font-bold text-left text-slate-700'>
                      <PermContactCalendarIcon fontSize="20" />  ชื่อผู้ใช้ :
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography className='font-bold text-left text-slate-700'>
                      <Item >{profile && profile.Username}</Item>
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} >
                    <Typography className='font-bold text-left text-slate-700'>
                      <PhoneIphoneIcon fontSize="20" />  โทรศัพท์ :
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography className='font-bold text-left text-slate-700'>
                      <Item >{profile && profile.phone}</Item>
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} >
                    <Typography className='font-bold text-left text-slate-700'>
                      <AccountBalanceIcon fontSize="20" /> ธนาคาร :
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography className='font-bold text-left text-slate-700'>
                      <Item >{profile && profile.bank}</Item>
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} >
                    <Typography className='font-bold text-left text-slate-700'>
                      <AccountBalanceWalletIcon fontSize="20" /> เลขที่บัญชี :
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h8" gutterBottom color={'black'}>
                      <Item >{profile && profile.bankaccoutnumber}</Item>

                    </Typography>
                  </Grid>

                </Grid>
              </Box>
            </div>
                  )}
            
          </div>
          
        </div>
      </div>

    </motion.div>
  )
}

export default Profile