import { Link } from 'react-router-dom'
import React from 'react'
import './lotteryselect.css'
import { motion } from 'framer-motion';
import LoadingComponent from './LoadingComponent'; // Replace with the correct path
import AllOutIcon from '@mui/icons-material/AllOut';
import moment from 'moment'
import { useEffect, useState } from 'react'

async function fetchData() {
  const response = await fetch(`${process.env.REACT_APP_API_KEY}/nextresultdate`);
  const data = await response.json();
  return data;
}


function Lotteryselect(props) {

  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const user = localStorage.getItem('user')

  const [date, setDate] = useState('');
  const [thclose, setThclose] = useState();
  const [laclose, setLaclose] = useState();

  const [targetdt, setTargetdt] = useState();

  const dateGet = () => {
    fetch(`${process.env.REACT_APP_API_KEY}/nextresultdate`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          setDate(moment(result).format('YYYY-MM-DD'))
        }
      )
  }

  const OrderGet = () => {
    const json =
    {
      memberuser: user
    }
    fetch(`${process.env.REACT_APP_API_KEY}/Getmemberpayrate`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(json),

    })
      .then(res => res.json())
      .then(
        (result) => {
          setStatus(result)

          const th_gov = result.filter(item => item.lotto_type === 'th_gov');
          setThclose(th_gov[0].close_time)

          const laos_gov = result.filter(item => item.lotto_type === 'laos_gov');
          setLaclose(laos_gov[0].close_time)

          // console.log(th_gov[0].close_time,"       ",laos_gov[0].close_time)
          setTargetdt(moment(`${date} ${thclose}`, 'YYYY/MM/DD HH:mm:ss'))

        }
      )
  }
  //#region all status

  const th_gov = status.filter(item => item.lotto_type === 'th_gov');
  const thgovstatus = th_gov.map((row) => (row.status))

  const laos_gov = status.filter(item => item.lotto_type === 'laos_gov');
  const laosgovstatus = laos_gov.map((row) => (row.status))

  const jackpotrate = status.filter(item => item.lotto_type === 'hanoy_PhuQua');
  const jackpot_rate = jackpotrate.map((row) => (row.status))

  const yeekee_5 = status.filter(item => item.lotto_type === 'Yeekee_5');
  const yeekee_5_status = yeekee_5.map((row) => (row.status))

  const yeekee_10 = status.filter(item => item.lotto_type === 'Yeekee_10');
  const yeekee_10_status = yeekee_10.map((row) => (row.status))

  const yeekee_15 = status.filter(item => item.lotto_type === 'Yeekee_15');
  const yeekee_15_status = yeekee_15.map((row) => (row.status))

  //#endregion

  function getNextRoutine() {
    const today = new Date();
    const dayOfWeek = today.toLocaleDateString('en-US', { weekday: 'long' });

    switch (dayOfWeek) {
      case "Monday":
        if (today.getHours() >= 21) {
          const nextWednesday = new Date();
          nextWednesday.setDate(today.getDate() + (3 + 7 - today.getDay()) % 7);
          return nextWednesday;
        } else {
          return today;
        }
        break;
      case "Tuesday":
        const nextWednesday = new Date();
        nextWednesday.setDate(today.getDate() + (3 + 7 - today.getDay()) % 7);
        return nextWednesday;
        break;
      case "Wednesday":
        if (today.getHours() >= 21) {
          const nextFriday = new Date();
          nextFriday.setDate(today.getDate() + (5 + 7 - today.getDay()) % 7);
          return nextFriday;
        } else {
          return today;
        }
        break;
      case "Thursday":
        const nextFriday = new Date();
        nextFriday.setDate(today.getDate() + (5 + 7 - today.getDay()) % 7);
        return nextFriday;
        break;
      case "Friday":
        if (today.getHours() >= 21) {
          const nextMonday = new Date();
          nextMonday.setDate(today.getDate() + (1 + 7 - today.getDay()) % 7);
          return nextMonday;
        } else {
          const nextToday = new Date(today);
          nextToday.setDate(today.getDate() + 1);
          return nextToday;
        }
        break;
      case "Saturday":
        const nextMonday = new Date();
        nextMonday.setDate(today.getDate() + (1 + 7 - today.getDay()) % 7);
        return nextMonday;
        break;
      case "Sunday":
        const nextMonday2 = new Date();
        nextMonday2.setDate(today.getDate() + (1 + 7 - today.getDay()) % 7);
        return nextMonday2;
        break;
    }
  }

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  const [countdownla, setCountdownla] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  const [countdownEnded, setCountdownEnded] = useState(true);
  // const [targetDate, setTargetDate] = useState(moment());


  const hanoiresultdate = moment().format('YYYY-MM-DD')
  const targetDatehanoi = moment(`${hanoiresultdate} 18:15`, 'YYYY/MM/DD HH:mm:ss');

  const laosresultdate = moment(getNextRoutine()).format('YYYY-MM-DD')

  const targetDatelaos = moment(`${laosresultdate} ${laclose}`, 'YYYY/MM/DD HH:mm:ss');
  const [countdownEndedlaos, setCountdownEndedlaos] = useState(true);

  // let targetDate
  useEffect(() => {
    dateGet()
    OrderGet()
    setLoading(false)

    // const intervalcc = setInterval(() => {
    //   const diff = targetdt.diff(moment());
    //   const duration = moment.duration(diff);
    //   setCountdown({
    //     days: Math.floor(duration.days()),
    //     hours: duration.hours(),
    //     minutes: duration.minutes(),
    //     seconds: duration.seconds(),
    //   });
    //   if (diff <= 0) {
    //     setCountdownEnded(false)
    //     clearInterval(intervalcc)
    //   }

    //   // const diffla = targetDatelaos.diff(moment());
    //   // const durationla = moment.duration(diffla);
    //   // setCountdownla({
    //   //   days: Math.floor(durationla.days()),
    //   //   hours: durationla.hours(),
    //   //   minutes: durationla.minutes(),
    //   //   seconds: durationla.seconds(),
    //   // });
    //   // if (diffla <= 0) {
    //   //   setCountdownEndedlaos(false);
    //   // }
    // }, 1000);

    // setInterval(() => {

    //   // const diff = targetDate.diff(moment());
    //   // const duration = moment.duration(diff);
    //   // setCountdown({
    //   //   days: Math.floor(duration.days()),
    //   //   hours: duration.hours(),
    //   //   minutes: duration.minutes(),
    //   //   seconds: duration.seconds(),
    //   // });
    //   // if (diff <= 0) {
    //   //   setCountdownEnded(false);
    //   // }

    //   const diffla = targetDatelaos.diff(moment());
    //   const durationla = moment.duration(diffla);
    //   setCountdownla({
    //     days: Math.floor(durationla.days()),
    //     hours: durationla.hours(),
    //     minutes: durationla.minutes(),
    //     seconds: durationla.seconds(),
    //   });
    //   if (diffla <= 0) {
    //     setCountdownEndedlaos(false);
    //   }
    // }, 1000);

  }, []);

  useEffect(() => {

    setTargetdt(moment(`${date} ${thclose}`, 'YYYY/MM/DD HH:mm:ss'))

    // const intervalcc = setInterval(() => {
    //   const diff = targetdt.diff(moment());
    //   const duration = moment.duration(diff);
    //   setCountdown({
    //     days: Math.floor(duration.days()),
    //     hours: duration.hours(),
    //     minutes: duration.minutes(),
    //     seconds: duration.seconds(),
    //   });
    //   if (diff <= 0) {
    //     setCountdownEnded(false)
    //     clearInterval(intervalcc)
    //   }

    //   // const diffla = targetDatelaos.diff(moment());
    //   // const durationla = moment.duration(diffla);
    //   // setCountdownla({
    //   //   days: Math.floor(durationla.days()),
    //   //   hours: durationla.hours(),
    //   //   minutes: durationla.minutes(),
    //   //   seconds: durationla.seconds(),
    //   // });
    //   // if (diffla <= 0) {
    //   //   setCountdownEndedlaos(false);
    //   // }
    // }, 1000);

    // setInterval(() => {

    //   // const diff = targetDate.diff(moment());
    //   // const duration = moment.duration(diff);
    //   // setCountdown({
    //   //   days: Math.floor(duration.days()),
    //   //   hours: duration.hours(),
    //   //   minutes: duration.minutes(),
    //   //   seconds: duration.seconds(),
    //   // });
    //   // if (diff <= 0) {
    //   //   setCountdownEnded(false);
    //   // }

    //   const diffla = targetDatelaos.diff(moment());
    //   const durationla = moment.duration(diffla);
    //   setCountdownla({
    //     days: Math.floor(durationla.days()),
    //     hours: durationla.hours(),
    //     minutes: durationla.minutes(),
    //     seconds: durationla.seconds(),
    //   });
    //   if (diffla <= 0) {
    //     setCountdownEndedlaos(false);
    //   }
    // }, 1000);

  }, [date, thclose]);

  useEffect(() => {
    if (moment(targetdt).format('YYYY-MM-DD') === date && moment(targetdt).format('HH:mm:ss') === thclose) {

      const intervalcc = setInterval(() => {
        const diff = targetdt.diff(moment());
        const duration = moment.duration(diff);
        setCountdown({
          days: Math.floor(duration.days()),
          hours: duration.hours(),
          minutes: duration.minutes(),
          seconds: duration.seconds(),
        });
        if (diff <= 0) {
          setCountdownEnded(false)
          clearInterval(intervalcc)
        }

        const diffla = targetDatelaos.diff(moment());
        const durationla = moment.duration(diffla);
        setCountdownla({
          days: Math.floor(durationla.days()),
          hours: durationla.hours(),
          minutes: durationla.minutes(),
          seconds: durationla.seconds(),
        });
        if (diffla <= 0) {
          setCountdownEndedlaos(false);
        }
      }, 1000);

    }
  }, [targetdt])


  return (
    <>

      <div className='w-[100%] h-[40px] p-2 bg-slate-700 border-[1px] border-slate-700 rounded-t'>
        <span className='text-white font-bold text-m'>แทงหวย
        </span>
      </div>

      {loading ? (
        <LoadingComponent />
      ) : (
        <div className='bg-slate-200 border-[1px] border-slate-700 w-[100%] h-[auto] drop-shadow-2xl'>
          <div className='flex flex-col flex-wrap bg-gradient-to-r from-purple-400 from-30% via-purple-450 via-50% to-purple-700 to-90% p-1 w-[100%] h-[auto] drop-shadow-2xl'>
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className='flex md:flex-row flex-col justify-around md:space-x-2 md:p-1 w-[100%] md:space-y-0 space-y-2'>

              {thgovstatus[0] && countdownEnded ? <Link className='link' to='/dashboard/thgov'>
                <div className="flex flex-col md:w-[250px] w-full h-[100px] bg-black border-[1px] border-black drop-shadow-xl rounded">
                  <div className="flex flex-row w-[100%] space-x-1 h-[50%] pl-1" >
                    <div className='h-[50%] w-[30px] bg-white rounded mt-1'>
                      <img src='/images/th_gov.jpg'></img>
                    </div>
                    <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded'>
                      <div className='text-right bg-gradient-to-r from-yellow-500 from-10% via-yellow-600 via-30% to-yellow-10 to-90% h-[50%] rounded-t'>
                        <span className='text-black mr-1'>หวยรัฐบาลไทย</span>
                      </div>
                      <div className='text-right bg-gradient-to-r from-amber-500 from-10% via-amber-600 via-30% to-amber-10 to-90% h-[50%] rounded-b'>
                        <span className='text-red-600 text-xs mr-1'>ปิดรับ  {moment(date).format('ll')} {thclose}</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-[100%] h-[50%]" >
                    <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded-b'>
                      <div className='text-right bg-slate-700 h-[100%] p-3 rounded-b'>
                        <span className='text-slate-500 font-bold text-l'>
                          {countdown.days} วัน {countdown.hours}:{countdown.minutes}:{countdown.seconds}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
                :
                <div className="gray flex flex-col md:w-[250px] w-full h-[100px] bg-black border-[1px] border-black drop-shadow-xl rounded">
                  <div className="flex flex-row w-[100%] space-x-1 h-[50%] pl-1" >
                    <div className='h-[50%] w-[30px] bg-white rounded mt-1'>
                      <img src='/images/th_gov.jpg'></img>
                    </div>
                    <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded'>
                      <div className='text-right bg-gradient-to-r from-yellow-500 from-10% via-yellow-600 via-30% to-yellow-10 to-90% h-[50%] rounded-t'>
                        <span className='text-black mr-1'>หวยรัฐบาลไทย</span>
                      </div>
                      <div className='text-right bg-gradient-to-r from-amber-500 from-10% via-amber-600 via-30% to-amber-10 to-90% h-[50%] rounded-b'>
                        <span className='text-black text-xs mr-1'>ปิดรับ {thclose}</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-[100%] h-[50%]" >
                    <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded-b'>
                      <div className='text-right bg-slate-700 h-[100%] p-3 rounded-b'>
                        <span className='text-slate-500 font-bold text-l'>
                          ปิดรับแทง
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              }


              {laosgovstatus[0] && countdownEndedlaos ? <Link className='link' to='/dashboard/laosgov'>
                <div className="flex flex-col md:w-[250px] w-full h-[100px] bg-black border-[1px] border-black drop-shadow-xl rounded">
                  <div className="flex flex-row w-[100%] space-x-1 h-[50%] pl-1" >
                    <div className='h-[50%] w-[30px] bg-white rounded mt-1'>
                      <img src='/images/laoslogo.png'></img>
                    </div>
                    <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded'>
                      <div className='text-right bg-gradient-to-r from-yellow-500 from-10% via-yellow-600 via-30% to-yellow-10 to-90% h-[50%] rounded-t'>
                        <span className='text-black mr-1'>หวยลาวรัฐบาล</span>
                      </div>
                      <div className='text-right bg-gradient-to-r from-amber-500 from-10% via-amber-600 via-30% to-amber-10 to-90% h-[50%] rounded-b'>
                        <span className='text-red-600 text-xs mr-1'>ปิดรับ  {moment(laosresultdate).format('ll')} {laclose}</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-[100%] h-[50%]" >
                    <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded-b'>
                      <div className='text-right bg-slate-700 h-[100%] p-3 rounded-b'>
                        <span className='text-slate-500 font-bold text-l'>
                          {countdownEndedlaos && <a>{countdownla.days} วัน {countdownla.hours}:{countdownla.minutes}:{countdownla.seconds}</a>
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
                :
                <div className="gray flex flex-col md:w-[250px] w-full h-[100px] bg-black border-[1px] border-black drop-shadow-xl rounded">
                  <div className="flex flex-row w-[100%] space-x-1 h-[50%] pl-1" >
                    <div className='h-[50%] w-[30px] bg-white rounded mt-1'>
                      <img src='/images/laoslogo.png'></img>
                    </div>
                    <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded'>
                      <div className='text-right bg-gradient-to-r from-yellow-500 from-10% via-yellow-600 via-30% to-yellow-10 to-90% h-[50%] rounded-t'>
                        <span className='text-black mr-1'>หวยลาวรัฐบาล</span>
                      </div>
                      <div className='text-right bg-gradient-to-r from-amber-500 from-10% via-amber-600 via-30% to-amber-10 to-90% h-[50%] rounded-b'>
                        <span className='text-black text-xs mr-1'>ปิดรับ {laclose}</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-[100%] h-[50%]" >
                    <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded-b'>
                      <div className='text-right bg-slate-700 h-[100%] p-3 rounded-b'>
                        <span className='text-slate-500 font-bold text-l'>
                          ปิดรับแทง
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              }

              {jackpot_rate[0] ? <Link className='link' to='/dashboard/hanoi'>
                <div className="flex flex-col md:w-[250px] w-full h-[100px] bg-black border-[1px] border-black drop-shadow-xl rounded">
                  <div className="flex flex-row w-[100%] space-x-1 h-[50%] pl-1" >
                    <div className='h-[50%] w-[30px] bg-white rounded mt-1'>
                    <img src='/images/hanoilogo.png'></img>
                    </div>
                    <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded'>
                      <div className='text-right bg-gradient-to-r from-yellow-500 from-10% via-yellow-600 via-30% to-yellow-10 to-90% h-[50%] rounded-t'>
                        <span className='text-black mr-1'>หวยฮานอย</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-[100%] h-[50%]" >
                    <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded-b'>
                      <div className='text-right bg-slate-700 h-[100%] p-3 rounded-b'>
                        <span className='text-slate-500 font-bold text-l'>
                          เปิดรับแทง
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
                :
                <div className="gray flex flex-col md:w-[250px] w-full h-[100px] bg-black border-[1px] border-black drop-shadow-xl rounded">
                  <div className="flex flex-row w-[100%] space-x-1 h-[50%] pl-1" >
                    <div className='h-[50%] w-[30px] bg-white rounded mt-1'>
                    <img src='/images/hanoilogo.png'></img>
                    </div>
                    <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded'>
                      <div className='text-right bg-gradient-to-r from-yellow-500 from-10% via-yellow-600 via-30% to-yellow-10 to-90% h-[50%] rounded-t'>
                        <span className='text-black mr-1'>หวยฮานอย</span>
                      </div>
                      <div className='text-right bg-gradient-to-r from-amber-500 from-10% via-amber-600 via-30% to-amber-10 to-90% h-[50%] rounded-b'>
                        <span className='text-black text-xs mr-1'>ปิดรับ 21.00</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-[100%] h-[50%]" >
                    <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded-b'>
                      <div className='text-right bg-slate-700 h-[100%] p-3 rounded-b'>
                        <span className='text-slate-500 font-bold text-l'>
                          ปิดรับแทง
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              }




            </motion.div>
          </div>
        </div>
      )}

      <div className='w-[100%] h-[40px] mt-2 p-2 bg-slate-700 border-[1px] border-slate-700 rounded-t'>
        <span className='text-white font-bold text-m'>แทงหวย จับยี่กี
        </span>
      </div>

      {loading ? (
        <LoadingComponent />
      ) : (
        <div className='bg-slate-200 border-[1px] border-slate-700 w-[100%] h-[auto] drop-shadow-2xl'>
          <div className='flex flex-col flex-wrap bg-gradient-to-r from-purple-400 from-10% via-purple-450 via-30% to-purple-700 to-90% p-1 w-[100%] h-[auto] drop-shadow-2xl'>
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className='flex md:flex-row flex-col justify-around md:space-x-2 md:p-3 md:space-y-0 space-y-2'>

              {yeekee_5_status[0] ? <Link className='link' to='/dashboard/Yeekee_5'>
                <div className="flex flex-col md:w-[250px] w-full h-[100px] bg-black border-[1px] border-black drop-shadow-xl rounded">
                  <div className="flex flex-row w-[100%] space-x-1 h-[50%] pl-1" >
                    <div className='h-[50%] w-[30px] bg-red-400 rounded mt-1'>
                      <AllOutIcon className='spin text-black' />
                    </div>
                    <div className='flex flex-col w-[100%] h-[100%] bg-red-800 rounded'>
                      <div className='text-right bg-gradient-to-r from-red-500 from-10% via-red-600 via-30% to-red-10 to-90% h-[50%] rounded-t'>
                        <span className='text-white mr-1'>จับยี่กี</span>
                      </div>
                      <div className='text-right bg-gradient-to-r from-red-500 from-10% via-red-600 via-30% to-red-10 to-90% h-[50%] rounded-b'>
                        <span className='text-white-600 font-bold text-xs mr-1'>เปิดแทง 288 รอบ ออกผลทุก 5 นาที</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-[100%] h-[50%]" >
                    <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded-b'>
                      <div className='text-right bg-slate-700 h-[100%] p-3 rounded-b'>
                        <span className='text-white font-bold text-l'>
                          เปิดแล้ว! 24 ชม.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
                :
                <div className="gray flex flex-col md:w-[250px] w-full h-[100px] bg-black border-[1px] border-black drop-shadow-xl rounded">
                  <div className="flex flex-row w-[100%] space-x-1 h-[50%] pl-1" >
                    <div className='h-[50%] w-[30px] bg-white rounded mt-1'>
                    <AllOutIcon className='spin text-black' />
                    </div>
                    <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded'>
                      <div className='text-right bg-gradient-to-r from-yellow-500 from-10% via-yellow-600 via-30% to-yellow-10 to-90% h-[50%] rounded-t'>
                        <span className='text-black mr-1'>จับยี่กี ปกติ</span>
                      </div>
                      <div className='text-right bg-gradient-to-r from-amber-500 from-10% via-amber-600 via-30% to-amber-10 to-90% h-[50%] rounded-b'>
                        <span className='text-black text-xs mr-1'>ปิดรับแทง</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-[100%] h-[50%]" >
                    <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded-b'>
                      <div className='text-right bg-slate-700 h-[100%] p-3 rounded-b'>
                        <span className='text-slate-500 font-bold text-l'>
                          ปิดรับแทง
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {yeekee_10_status[0] ? <Link className='link' to='/dashboard/Yeekee_10'>
                <div className="flex flex-col md:w-[250px] w-full h-[100px] bg-black border-[1px] border-black drop-shadow-xl rounded">
                  <div className="flex flex-row w-[100%] space-x-1 h-[50%] pl-1" >
                    <div className='h-[50%] w-[30px] bg-red-400 rounded mt-1'>
                      <AllOutIcon className='spin text-black' />
                    </div>
                    <div className='flex flex-col w-[100%] h-[100%] bg-red-800 rounded'>
                      <div className='text-right bg-gradient-to-r from-red-500 from-10% via-red-600 via-30% to-red-10 to-90% h-[50%] rounded-t'>
                        <span className='text-white mr-1'>จับยี่กี VIP</span>
                      </div>
                      <div className='text-right bg-gradient-to-r from-red-500 from-10% via-red-600 via-30% to-red-10 to-90% h-[50%] rounded-b'>
                        <span className='text-white-600 font-bold text-xs mr-1'>เปิดแทง 144 รอบ ออกผลทุก 10 นาที</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-[100%] h-[50%]" >
                    <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded-b'>
                      <div className='text-right bg-slate-700 h-[100%] p-3 rounded-b'>
                        <span className='text-white font-bold text-l'>
                          เปิดแล้ว! 24 ชม.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
                :
                <div className="gray flex flex-col md:w-[250px] w-full h-[100px] bg-black border-[1px] border-black drop-shadow-xl rounded">
                  <div className="flex flex-row w-[100%] space-x-1 h-[50%] pl-1" >
                    <div className='h-[50%] w-[30px] bg-white rounded mt-1'>
                    <AllOutIcon className='spin text-black' />
                    </div>
                    <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded'>
                      <div className='text-right bg-gradient-to-r from-yellow-500 from-10% via-yellow-600 via-30% to-yellow-10 to-90% h-[50%] rounded-t'>
                        <span className='text-black mr-1'>จับยี่กี VIP</span>
                      </div>
                      <div className='text-right bg-gradient-to-r from-amber-500 from-10% via-amber-600 via-30% to-amber-10 to-90% h-[50%] rounded-b'>
                        <span className='text-black text-xs mr-1'>ปิดรับแทง</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-[100%] h-[50%]" >
                    <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded-b'>
                      <div className='text-right bg-slate-700 h-[100%] p-3 rounded-b'>
                        <span className='text-slate-500 font-bold text-l'>
                          ปิดรับแทง
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {yeekee_15_status[0] ? <Link className='link' to='/dashboard/Yeekee_10'>
                <div className="flex flex-col md:w-[250px] w-full h-[100px] bg-black border-[1px] border-black drop-shadow-xl rounded">
                  <div className="flex flex-row w-[100%] space-x-1 h-[50%] pl-1" >
                    <div className='h-[50%] w-[30px] bg-red-400 rounded mt-1'>
                      <AllOutIcon className='spin text-black' />
                    </div>
                    <div className='flex flex-col w-[100%] h-[100%] bg-red-800 rounded'>
                      <div className='text-right bg-gradient-to-r from-red-500 from-10% via-red-600 via-30% to-red-10 to-90% h-[50%] rounded-t'>
                        <span className='text-white mr-1'>จับยี่กี VVIP</span>
                      </div>
                      <div className='text-right bg-gradient-to-r from-red-500 from-10% via-red-600 via-30% to-red-10 to-90% h-[50%] rounded-b'>
                        <span className='text-white-600 font-bold text-xs mr-1'>เปิดแทง 96 รอบ ออกผลทุก 15 นาที</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-[100%] h-[50%]" >
                    <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded-b'>
                      <div className='text-right bg-slate-700 h-[100%] p-3 rounded-b'>
                        <span className='text-white font-bold text-l'>
                          เปิดแล้ว! 24 ชม.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
                :
                <div className="gray flex flex-col md:w-[250px] w-full h-[100px] bg-black border-[1px] border-black drop-shadow-xl rounded">
                  <div className="flex flex-row w-[100%] space-x-1 h-[50%] pl-1" >
                    <div className='h-[50%] w-[30px] bg-white rounded mt-1'>
                    <AllOutIcon className='spin text-black' />
                    </div>
                    <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded'>
                      <div className='text-right bg-gradient-to-r from-yellow-500 from-10% via-yellow-600 via-30% to-yellow-10 to-90% h-[50%] rounded-t'>
                        <span className='text-black mr-1'>จับยี่กี VVIP</span>
                      </div>
                      <div className='text-right bg-gradient-to-r from-amber-500 from-10% via-amber-600 via-30% to-amber-10 to-90% h-[50%] rounded-b'>
                        <span className='text-black text-xs mr-1'>ปิดรับแทง</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-[100%] h-[50%]" >
                    <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded-b'>
                      <div className='text-right bg-slate-700 h-[100%] p-3 rounded-b'>
                        <span className='text-slate-500 font-bold text-l'>
                          ปิดรับแทง
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              }



            </motion.div>
          </div>
        </div>
      )}



    </>
  )
}

export default Lotteryselect