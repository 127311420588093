import React, { useState } from 'react';
import './label.css'
function Label(props) {
     const numbers = props.number;
    // const listItems = numbers.map(() =>
    //   <label className='number'></label>
      
    // );
//     const data = [];
//   for (let i = 0; i < props.type; i++) {
//     data.push(`Label ${i + 1}`);
//   }

if(props.type.find(item => item === 'run_top') || props.type.find(item => item === 'run_bot'))
 return(
    <div>
    <label className='number'>{props.number1}</label>
    </div>
 )


if(props.type.find(item => item === 'two_top') || props.type.find(item => item === 'two_bot') || props.type.find(item => item === 'rollfront') || props.type.find(item => item === 'rollback')){
   if(props.type.find(item => item === 'rollfront') || props.type.find(item => item === 'rollback'))
   {
      return(
         <div>
         <label className='number'>{props.number1}</label>
         </div>
      )   
   }
   else{
      return(
         <div>
         <label className='number'>{props.number1}</label>
         <label className='number'>{props.number2}</label>
         </div>
      )   
   }
}

 if(props.type.find(item => item === 'three_top') || props.type.find(item => item === 'three_tod') || props.type.find(item => item === 'three_bot') || props.type.find(item => item === 'three_front') || props.type.find(item => item === 'threeswap'))
 return(
    <div>
    <label className='number'>{props.number1}</label>
    <label className='number'>{props.number2}</label>
    <label className='number'>{props.number3}</label>
    </div>
 )

 if(props.type.find(item => item === "four_top") || props.type.find(item => item === "four_tod"))
 return(
    <div>
    <label className='number'>{props.number1}</label>
    <label className='number'>{props.number2}</label>
    <label className='number'>{props.number3}</label>
    <label className='number'>{props.number4}</label>
    </div>
 )

 if(props.type.find(item => item ==="first"))
 return(
    <div>
    <label className='number'>{props.number1}</label>
    <label className='number'>{props.number2}</label>
    <label className='number'>{props.number3}</label>
    <label className='number'>{props.number4}</label>
    <label className='number'>{props.number5}</label>
    <label className='number'>{props.number6}</label>

    </div>
 )

  
}

export default Label