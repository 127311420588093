import React from 'react';
import ContentLoader from 'react-content-loader';

const LoadingComponent = () => {
  return (
    <ContentLoader
      className='p-2'
      height={80}
      speed={1}
      backgroundColor={'#9C96A0'}
      foregroundColor={'#7A06C6'}
      viewBox="0 0 800 100">
      <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
      <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
    </ContentLoader>
  );
};

export default LoadingComponent;
