import styles from './AnimateText.css';

function AnimatedText() {
  return (
    <div className={`md:flex flex-row bg-stone-800 w-full h-12 p-2 rounded-t space-x-4 `}>
      <div className={`w-full h-auto bg-stone-900 border-2 rounded border-zinc-500/50 parent-div`}>
        <span className={`text-sm font-bold text-yellow-200 ml-10 running-text`}>ยังไม่เปิดด พัฒนาอยู่วว เว็บเเทงหวยอันดับ 1 ที่นี่ MEESOK.com</span>
      </div>
    </div>
  );
}

export default AnimatedText;
