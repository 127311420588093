import React, { useRef, useState, useEffect } from 'react'
import { Button } from '@mui/material';
import LoadingComponent from './LoadingComponent'; // Replace with the correct path
import { motion } from 'framer-motion';
import moment from 'moment';
import 'moment/locale/th';
import AllOutIcon from '@mui/icons-material/AllOut';
import { v4 as uuidv4 } from 'uuid';
import { Link, Routes, Route } from 'react-router-dom'
import './roundselect.css'
import Numadd from './numadd';

function Roundselect(props) {

  const [now, setNow] = useState(new Date())
  const [isLoading, setIsLoading] = useState(true);
  const [loadedRounds, setLoadedRounds] = useState(20);
  const [hasMore, setHasMore] = useState(true);
  const paginationRef = useRef(null);
  const todaydate = moment().format("YYYY-MM-DD")

  useEffect(() => {
    // Attach a scroll event listener
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const scrollTop = window.scrollY;
    const scrollHeight = document.documentElement.scrollHeight;

    // Load more content when reaching the bottom and still have more to load
    if (scrollTop + windowHeight >= scrollHeight - 100 && hasMore && !isLoading) {
      loadMoreContent();
    }
  };

  const loadMoreContent = () => {
    setIsLoading(true);

    // Simulate loading delay
    setTimeout(() => {
      const newLoadedRounds = loadedRounds + 20;

      // Check if there's more content to load
      if (newLoadedRounds >= props.numberofrond) {
        setHasMore(false);
      }

      setLoadedRounds(newLoadedRounds);
      setIsLoading(false);
    }, 1000);
  };


  useEffect(() => {
    const intervalId = setInterval(() => {
      const today = new Date()
      setNow(today);
      // console.log(props.numberofrond)

    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const roundgen = (round) => {
    const buttons = [];
    let time = 0;
    // const now = new Date(); // get the current time
    for (let i = 0; i < round; i++) {
      let hours = Math.floor(time / 3600).toString().padStart(2, '0');
      let minutes = Math.floor((time % 3600) / 60).toString().padStart(2, '0');
      let timeString = `${hours}:${minutes}`;
      const roundStartTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
      if (roundStartTime < now) {
      } else {
        // calculate the time difference between now and the round start time
        let diff = roundStartTime - now;
        let diffSeconds = Math.floor(diff / 1000);
        let diffMinutes = Math.floor(diffSeconds / 60);
        let diffHours = Math.floor(diffMinutes / 60);
        // calculate the remaining time in seconds, minutes, and hours
        diffSeconds = diffSeconds % 60;
        diffMinutes = diffMinutes % 60;
        diffHours = diffHours % 24;
        // format the remaining time as a time string
        let countdownString = `${diffHours.toString().padStart(2, '0')}:${diffMinutes.toString().padStart(2, '0')}:${diffSeconds.toString().padStart(2, '0')}`;

        // otherwise, enable the view
        buttons.push(
          <Link key={uuidv4()} className='link' to={`/dashboard/${props.type}/${i + 1}`}>
            <div className="flex flex-col md:w-[230px] w-[90vw] h-[100px] bg-black border-[1px] border-black drop-shadow-xl rounded">
              <div className="flex flex-row w-[100%] space-x-1 h-[50%] pl-1" >
                <div className='h-[50%] w-[30px] bg-red-400 rounded mt-1'>
                  <AllOutIcon className='spin text-black' />
                </div>
                <div className='flex flex-col w-[100%] h-[100%] bg-red-800 rounded'>
                  <div className='text-right bg-gradient-to-r from-red-500 from-10% via-red-600 via-30% to-red-10 to-90% h-[50%] rounded-t'>
                    <span className='text-white mr-1'>ยี่กี่ปกติรอบที่ {i + 1}</span>
                  </div>
                  <div className='text-right bg-gradient-to-r from-red-500 from-10% via-red-600 via-30% to-red-10 to-90% h-[50%] rounded-b'>
                    <span className='text-white-600 font-bold text-xs mr-1'>เวลาปิดรับแทง: {timeString}</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-[100%] h-[50%]" >
                <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded-b'>
                  <div className='text-right bg-slate-700 h-[100%] p-3 rounded-b'>
                    <span className='text-slate-500 font-bold text-l'>
                      เปิดแทง! เวลาเหลือ: {countdownString}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        );
      }
      time += props.timeperround; // add 5 minutes
    }
    return buttons;
  }


  const resultroundgen = (round) => {
    const buttons = [];
    let time = 0;
    // const now = new Date(); // get the current time
    for (let i = 0; i < round; i++) {
      let hours = Math.floor(time / 3600).toString().padStart(2, '0');
      let minutes = Math.floor((time % 3600) / 60).toString().padStart(2, '0');
      let timeString = `${hours}:${minutes}`;
      const roundStartTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
      if (roundStartTime < now) {
        buttons.push(
          <Link key={uuidv4()} className='link' to={`/dashboard/${props.type}/${i + 1}`}>
            <div
              className="flex flex-col md:w-[230px] w-[90vw] h-[100px] bg-black border-[1px] border-black drop-shadow-xl rounded">
              <div className="flex flex-row w-[100%] space-x-1 h-[50%] pl-1" >
                <div className='h-[50%] w-[30px] bg-grey-400 rounded mt-1'>
                  <AllOutIcon className='spin text-black' />
                </div>
                <div className='flex flex-col w-[100%] h-[100%] bg-slate-800 rounded'>
                  <div className='text-right bg-gradient-to-r from-slate-500 from-10% via-slate-600 via-30% to-slate-10 to-90% h-[50%] rounded-t'>
                    <span className='text-white mr-1'>ยี่กี่ปกติรอบที่ {i + 1}</span>
                  </div>
                  <div className='text-right bg-gradient-to-r from-slate-500 from-10% via-slate-600 via-30% to-slate-10 to-90% h-[50%] rounded-b'>
                    <span className='text-white-600 font-bold text-xs mr-1'>เวลาปิดรับแทง: {timeString}</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-[100%] h-[50%]" >
                <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded-b'>
                  <div className='text-right bg-slate-700 h-[100%] p-3 rounded-b'>
                    <span className='text-slate-500 font-bold text-l'>
                      ปิดแทง!
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        );

      } else {
        // otherwise, enable the view
      }
      time += props.timeperround; // add 5 minutes
    }
    return buttons;
  }




  return (
    (
      <>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }} 
           style={{ display: 'flex', flexDirection: 'column' }}>
          <div className='flex flex-col justify-center'>
            <div className='w-[100%] flex flex-row h-[40px] justify-center items-center p-2 bg-slate-700 space-x-2 border-[1px] border-slate-700 rounded-t'>
        
              <Link to="/dashboard" className='md:w-[5%] text-center bg-red-600 rounded justify-center border shadow-md flex justify-center hover:bg-red-700'>
                <span className='text-white font-bold'>กลับ</span>
              </Link>
              <span className='text-white font-bold text-m'>
              </span>
              <div className='md:w-[10%] text-center bg-slate-200 rounded place-content-center border border-gray-200 flex justify-center'>
                <span className='text-purple-700 font-bold'>
                {props.type === 'Yeekee_5' && 'ยี่กี่ ปกติ'}
                {props.type === 'Yeekee_10' && 'ยี่กี่ VIP'}
                {props.type === 'Yeekee_15' && 'ยี่กี่ VVIP'}
                </span>
              </div>
              <div className='md:w-[15%] text-center bg-slate-200 rounded place-content-center border border-gray-200 flex justify-center'>
                <span className='text-purple-700 font-bold'>{moment(props.resultdate).format('LL')}</span>
              </div>
           
            </div>
            <div className='flex flex-col bg-gradient-to-r from-purple-400 from-30% via-purple-450 via-50% to-purple-700 to-90% border-[1px] border-slate-700 p-1 w-[100%] h-[auto] drop-shadow-2xl'>
              <div className='flex_type p-2'>
                {(roundgen(props.numberofrond))}
                {(resultroundgen(props.numberofrond))}
              </div>
            </div>
          </div>
        </motion.div>
      </>
    )

  )
}

export default Roundselect