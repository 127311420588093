import React, { useState, useEffect } from 'react';
import './login.css'
import LoadingComponent from './LoadingComponent'; // Replace with the correct path
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Dialog from '@mui/material/Dialog';
import { motion } from 'framer-motion';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment'
import Topnav from './Topnav'
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Footer from './Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';






export default function SignInSide() {


  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const jsonData = {
      Username: data.get('username'),
      Password: data.get('password')
    };

    fetch(`${process.env.REACT_APP_API_KEY}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonData),
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 'OK') {
          localStorage.setItem('token', data.token);
          localStorage.setItem('user', jsonData.Username);
          toast.success('ข้อมูลถูกต้อง กำลังเข้าสู่ระบบ...', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
          setTimeout(() => {
            window.location = '/dashboard';
          }, 2000); // Delay for 5 seconds
        } else {
          toast.error('Username หรือ รหัสผ่านไม่ถูกต้อง', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        toast.error('🦄 Wow so easy!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };


  //////////////////////////////////////////////////////////////////////////////////////////////////

  const [open, setOpen] = React.useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [bank, setBank] = useState("");
  const [banknumber, setBanknumber] = useState("");
  const [agent, setAgent] = useState("");
  const [step, setStep] = useState(1);
  const handleNext = () => {
    setStep(prevStep => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep(prevStep => prevStep - 1);
  };
  const handleClose = () => {
    setOpen(false);
    setUsername('')
    setPassword('')
    setFname('')
    setLname('')
    setMobileNumber('')
    setBank('')
    setBanknumber('')
    setAgent('')

  };

  const handleClickOpen = (e) => {
    e.preventDefault()
    setOpen(true);
  };

  const handleSubmitregis = async (e) => {
    e.preventDefault();
    const json = {
      Username: username,
      Password: password,
      fname: fname,
      lname: lname,
      phone: mobileNumber,
      bank: bank,
      accout: banknumber,
      agent: agent

    }
    fetch(`${process.env.REACT_APP_API_KEY}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result === 'aleady has this user') {
            toast.error('มีชื่อผู้ใช้ในระบบแล้ว โปรดใช้ชื่ออื่น', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            })
          }
          else {
            setUsername('')
            setPassword('')
            setFname('')
            setLname('')
            setMobileNumber('')
            setBank('')
            setBanknumber('')
            setAgent('')
            toast.success('สมัครสมาชิกเรียบร้อย', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            window.location.reload(false)
          }
        }
      )

  };
  const [loading, setLoading] = useState(true); // Loading state
  const [Yeekeeloading, YeekeesetLoading] = useState(true); // Loading state
  const [result, setResult] = useState([]);
  const [yeekeeSelect, setyeekeeSelect] = useState('yeekee_5')
  const [yeekeeresult, setYeekeeresult] = useState([]);
  const [vtlHaiphongresult, SetvtlHaiphongresult] = useState([]);
  const [vtlphuquaresult, Setvtlphuquaresult] = useState([]);
  const [vtNgheAnresult, SetvtNgheAnresult] = useState([]);
  const [vtDalatresult, SetvtDalatresult] = useState([]);
  const [vtNhaTrangresult, SetvtNhaTrangresult] = useState([]);
  const [laoGov, SetlaoGov] = useState([])
  const [agentlist, setAgentlist] = useState([]);
  const [lottotype, setLottotype] = useState('th_gov');
  //console.log(vtlphuquaresult)


  const handleYeekeeSelect = (selection) => {
    setyeekeeSelect(selection)
  };

  const ResGet = () => {
    const json = {
      lottotype: 'th_gov'
    }

    fetch(`${process.env.REACT_APP_API_KEY}/getlastresult`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    })
      .then(res => res.json())
      .then(
        (result) => {
          setResult(result)
          setLoading(false)
        }
      )
  }

  const vtphuquaget = () => {
    const json = {
      lottotype: "hanoy_PhuQua"
    }

    fetch(`${process.env.REACT_APP_API_KEY}/getlastresult_hanoi`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    })
      .then(res => res.json())
      .then(
        (result) => {
          //console.log(result)
          Setvtlphuquaresult(result)

        }
      )
  }
  const vtHaiphongget = () => {
    const json = {
      lottotype: "hanoy_Haiphong"
    }

    fetch(`${process.env.REACT_APP_API_KEY}/getlastresult_hanoi`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    })
      .then(res => res.json())
      .then(
        (result) => {
          SetvtlHaiphongresult(result)
        }
      )
  }
  const vtNgheAnget = () => {
    const json = {
      lottotype: "hanoy_NgheAn"
    }

    fetch(`${process.env.REACT_APP_API_KEY}/getlastresult_hanoi`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    })
      .then(res => res.json())
      .then(
        (result) => {
          SetvtNgheAnresult(result)
        }
      )
  }
  const vtNhaTrangget = () => {
    const json = {
      lottotype: "hanoy_NhaTrang"
    }

    fetch(`${process.env.REACT_APP_API_KEY}/getlastresult_hanoi`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    })
      .then(res => res.json())
      .then(
        (result) => {
          // console.log(result)
          SetvtNhaTrangresult(result)
        }
      )
  }
  const vtDalatget = () => {
    const json = {
      lottotype: "hanoy_Dalat"
    }

    fetch(`${process.env.REACT_APP_API_KEY}/getlastresult_hanoi`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    })
      .then(res => res.json())
      .then(
        (result) => {
          SetvtDalatresult(result)
        }
      )
  }
  const laosgovget = () => {
    const json = {
      lottotype: "laos_gov"
    }

    fetch(`${process.env.REACT_APP_API_KEY}/getlastresult`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    })
      .then(res => res.json())
      .then(
        (result) => {
          SetlaoGov(result)
        }
      )
  }

  useEffect(() => {
    const yeekeeget = () => {
      YeekeesetLoading(true);
      const json = {
        date: moment().format('YYYY-MM-DD'),
        lottotype: yeekeeSelect,
      };

      fetch(`${process.env.REACT_APP_API_KEY}/getallresultbydate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(json),
      })
        .then((res) => res.json())
        .then((result) => {
          //console.log(result);
          setYeekeeresult(result);
          YeekeesetLoading(false);
        });
    };

    yeekeeget(); // Call the function initially

    // This effect will re-run whenever yeekeeSelect changes
  }, [yeekeeSelect]);
  const agentget = () => {

    fetch(`${process.env.REACT_APP_API_KEY}/getagent`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          setAgentlist(result)
        }
      )
  }


  useEffect(() => {
    ResGet()
    vtNgheAnget()
    vtNhaTrangget()
    agentget()
    vtphuquaget()
    vtDalatget()
    vtHaiphongget()
    laosgovget()
  }, [])

  const totableLaosGov = {
    first: laoGov.filter(item => item.type === 'first'),
    three_top: laoGov.filter(item => item.type === 'three_top'),
    twobot: laoGov.filter(item => item.type === 'two_bot')
  }

  const totableVtPhuqa = {
    first: vtlphuquaresult.filter(item => item.type === 'first'),
    three_top: vtlphuquaresult.filter(item => item.type === 'three_top'),
    twobot: vtlphuquaresult.filter(item => item.type === 'two_bot')
  }
  
  const totableVtHaiphong = {
    first: vtlHaiphongresult.filter(item => item.type === 'first'),
    three_top: vtlHaiphongresult.filter(item => item.type === 'three_top'),
    twobot: vtlHaiphongresult.filter(item => item.type === 'two_bot')
  }
  const totableVtNgheAn = {
    first: vtNgheAnresult.filter(item => item.type === 'first'),
    three_top: vtNgheAnresult.filter(item => item.type === 'three_top'),
    twobot: vtNgheAnresult.filter(item => item.type === 'two_bot')
  }
  const totableVtNhaTrang = {
    first: vtNhaTrangresult.filter(item => item.type === 'first'),
    three_top: vtNhaTrangresult.filter(item => item.type === 'three_top'),
    twobot: vtNhaTrangresult.filter(item => item.type === 'two_bot')
  }
  // console.log(totableVtNhaTrang)
  const totableVtDaLat = {
    first: vtDalatresult.filter(item => item.type === 'first'),
    three_top: vtDalatresult.filter(item => item.type === 'three_top'),
    twobot: vtDalatresult.filter(item => item.type === 'two_bot')
  }


  const first = result.filter(item => item.type === 'first');
  const threefront = result.filter(item => item.type === 'three_front');
  const threebot = result.filter(item => item.type === 'three_bot');
  const twobot = result.filter(item => item.type === 'two_bot');


  return (

    <div>

      <Topnav />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className='content'
      >
        <div className='xl:flex xl:flex-col xl:mt-[20px] xl:w-[75%] w-[90%] xl:p-2 xl:h-auto xl:rounded mt-10'>
          {/* login */}
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{
              opacity: 1,
              y: 0,
              scale: [1, 1.05, 1], // Scale animation effect
            }}
            transition={{
              duration: 1, // Duration of the animation
            }}
            className='xl:flex flex-col w-[100%] h-auto bg-black p-2 border-[1px] border-amber-400 rounded drop-shadow-2xl'>

            <div className='xl:w-[100%] h-100 p-2 text-yellow-100'>
              <span className='xl:text-[17px] font-bold'>แทงหวยออนไลน์ Meesok.com เว็บหวยอันดับหนึ่ง</span>
            </div>

            <div className='xl:flex xl:h-[60px] xl:flex-row xl:p-2 xl:place-content-between'>

              <div className='xl:flex flex-row xl:space-x-4'>

                <form className='xl:flex flex flex-col w-auto xl:flex-row xl:space-x-2 xl:space-y-0 space-y-2 rounded' onSubmit={handleSubmit}>
                  {/* /////////////////////////////////////////////////////////////////////////// */}

                  <div className='xl:flex flex w-auto flex-row bg-neutral-100 rounded'>
                    <div className='xl:w-[17%] w-[13%] h-auto bg-neutral-300 p-2 rounded-l'>
                      <PersonIcon className="text-neutral-800" />
                    </div>
                    <div className="flex-grow">
                      <input className='w-full xl:w-full p-2 h-full rounded-r text-purple-800 bg-opacity-0' type="text" placeholder='ชื่อผู้ใช้งาน' name='username' />
                    </div>
                  </div>

                  {/* /////////////////////////////////////////////////////////////////////////// */}

                  <div className='xl:flex flex w-auto flex-row bg-neutral-100 rounded '>
                    <div className='xl:w-[17%] w-[13%] h-auto bg-neutral-300 p-2 rounded-l'>
                      <LockIcon className="text-neutral-800" />
                    </div>
                    <div className='flex-grow'>
                      <input className='w-full xl:w-full p-2 h-full rounded-r text-purple-800 bg-opacity-0' type="password" placeholder='รหัสผ่าน' name='password' />
                    </div>
                  </div>


                  {/* /////////////////////////////////////////////////////////////////////////// */}
                  <div className='xl:flex flex-row p-2 space-x-1 '>
                    <LockIcon className="xl:text-[14px] font-bold text-yellow-100 " />
                    <span className='xl:text-[14px] text-yellow-100 hover:text-yellow-800'>ลืมรหัสผ่าน</span>
                  </div>

                  <div className='flex justify-around space-x-2'>
                    <motion.div
                      onClick={handleClickOpen}
                      initial={{ opacity: 0, y: -20 }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        scale: [1, 1.05, 1], // Scale animation effect
                      }}
                      transition={{
                        duration: 1, // Duration of the animation
                      }}
                      whileHover={{ y: -8, scale: 1.05 }}
                      className='xl:flex space-x-1 rounded-lg bg-neutral-700 p-3 hover:bg-neutral-800 text-yellow-800'>
                      <HowToRegIcon className="xl:text-[14px] font-bold text-yellow-50 " />
                      <span className='xl:text-[14px] text-yellow-100'>สมัครสมาชิก</span>
                    </motion.div >
                    <motion.div
                      initial={{ opacity: 0, y: -20 }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        scale: [1, 1.05, 1], // Scale animation effect
                      }}
                      transition={{
                        duration: 1, // Duration of the animation
                      }}
                      whileHover={{ y: -8, scale: 1.05 }}
                      style={{ height: '100% !important' }}
                    >
                      <button type="submit" className="golden-btn">
                        เข้าสู่ระบบ
                      </button>
                    </motion.div>
                  </div>

                </form>
              </div>
            </div>
          </motion.div>
          {/* login */}

          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{
              opacity: 1,
              y: 0,
              scale: [1, 1.05, 1], // Scale animation effect
            }}
            transition={{
              duration: 1, // Duration of the animation
            }}
            className='xl:flex flex-col w-[100%] h-auto mt-3 bg-sky-50 rounded'>
            <div className='xl:flex flex-row bg-stone-800 w-[100%] h-[40px] p-2 rounded-t space-x-4'>
              <span className='text-[10px] xl:text-[14px] text-yellow-50 hover:text-yellow-800'>อัตราจ่าย</span>
              <span className='text-[10px] xl:text-[14px] text-yellow-50 hover:text-yellow-800'>ลืมรหัสผ่าน</span>
              <span className='text-[10px] xl:text-[14px] text-yellow-50 hover:text-yellow-800'>เกี่ยวกับเรา</span>
              <span className='text-[10px] xl:text-[14px] text-yellow-50 hover:text-yellow-800'>รีวิว</span>
            </div>

            <div className='xl:bg-sky-50 flex flex-col p-1 w-[100%] h-auto space-y-3 rounded'>
              <motion.div
                className='xl:flex flex-col w-[100%] bg-sky-50 rounded-t border-black drop-shadow-xl'>
                <div className='flex flex-col justify-center items-center bg-zinc-700 rounded-t p-2 space-y-2'>
                  <div className='flex flex-row space-x-1'>
                    <img className='w-[35px] ml-3' src='https://upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_Thailand.svg' alt="" />
                    <span className=''>หวยรัฐบาลไทย</span>
                  </div>
                  <div class="w-[17%] h-[20px] text-center mt-1 rounded border-black border-1 bg-white flex justify-center items-center">
                    {first.map((row) => (
                      <span className='text-black font-bold xl:text-[9px] text-[7px]'>{moment(row.date).format('ll')}</span>
                    ))}
                  </div>
                  <div className='flex w-full justify-center items-center space-x-1 flex-row'>
                    <div className='w-[10%] xl:w-[3%] bg-slate-200 py-1 rounded place-content-center border border-gray-200 flex justify-center'>
                      {first.map((row) => (
                        <div className='xl:w-[3%] bg-slate-200 py-1 rounded place-content-center border border-gray-200 flex justify-center'>
                          <span className='text-black font-bold text-[15px] xl:text-[25px]'>
                            {row.resultnumber.charAt(0)}
                          </span>
                        </div>
                      ))}
                    </div>
                    <div className='w-[10%] xl:w-[3%] bg-slate-200 py-1 rounded place-content-center border border-gray-200 flex justify-center'>
                      {first.map((row) => (
                        <div className='xl:w-[3%] bg-slate-200 py-1 rounded place-content-center border border-gray-200 flex justify-center'>
                          <span className='text-black font-bold text-[15px] xl:text-[25px]'>
                            {row.resultnumber.charAt(1)}
                          </span>
                        </div>
                      ))}
                    </div>
                    <div className='w-[10%] xl:w-[3%] bg-slate-200 py-1 rounded place-content-center border border-gray-200 flex justify-center'>
                      {first.map((row) => (
                        <div className='xl:w-[3%] bg-slate-200 py-1 rounded place-content-center border border-gray-200 flex justify-center'>
                          <span className='text-black font-bold text-[15px] xl:text-[25px]'>
                            {row.resultnumber.charAt(2)}
                          </span>
                        </div>
                      ))}
                    </div>
                    <div className='w-[10%] xl:w-[3%] bg-slate-200 py-1 rounded place-content-center border border-gray-200 flex justify-center'>
                      {first.map((row) => (
                        <div className='xl:w-[3%] bg-slate-200 py-1 rounded place-content-center border border-gray-200 flex justify-center'>
                          <span className='text-black font-bold text-[15px] xl:text-[25px]'>
                            {row.resultnumber.charAt(3)}
                          </span>
                        </div>
                      ))}
                    </div>
                    <div className='w-[10%] xl:w-[3%] bg-slate-200 py-1 rounded place-content-center border border-gray-200 flex justify-center'>
                      {first.map((row) => (
                        <div className='xl:w-[3%] bg-slate-200 py-1 rounded place-content-center border border-gray-200 flex justify-center'>
                          <span className='text-black font-bold text-[15px] xl:text-[25px]'>
                            {row.resultnumber.charAt(4)}
                          </span>
                        </div>
                      ))}
                    </div>
                    <div className='w-[10%] xl:w-[3%] bg-slate-200 py-1 rounded place-content-center border border-gray-200 flex justify-center'>
                      {first.map((row) => (
                        <div className='xl:w-[3%] bg-slate-200 py-1 rounded place-content-center border border-gray-200 flex justify-center'>
                          <span className='text-black font-bold text-[15px] xl:text-[25px]'>
                            {row.resultnumber.charAt(5)}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  {loading ? (
                    <LoadingComponent />
                  ) : (
                    <div className='p-2 bg-gradient-to-r from-indigo-400 justify-center items-center via-purple-400 to-indigo-400 border-black border-[1px]'>
                      <div className='w-full flex justify-center items-center flex-row xl:flex-row'>
                        <div className='w-full xl:w-[25%] text-center border-white border-b-[1px]'>
                          <span className="text-white text-[15px] xl:text-[18px]">3 ตัวหน้า</span>
                        </div>
                        <div className='w-full xl:w-[25%] text-center border-white border-b-[1px]'>
                          <span className="text-white text-[15px] xl:text-[18px]">3 ตัวหลัง</span>
                        </div>
                        <div className='w-full xl:w-[25%] text-center border-white border-b-[1px]'>
                          <span className="text-white text-[15px] xl:text-[18px]">2 ตัวล่าง</span>
                        </div>
                      </div>
                      <div className='w-full flex justify-center items-center flex-row xl:flex-row p-1'>
                        <div className='w-[50%] xl:w-[25%] flex justify-center items-center text-center'>
                          <div className='w-[70%] xl:w-[50%] bg-slate-200 rounded place-content-center border border-gray-200 flex justify-center'>
                            {threefront.map((row) => (
                              <a className='text-[10px] xl:text-[20px] text-zinc-600 font-bold' style={{ paddingLeft: '1rem', paddingRight: '1rem' }} key={row.id}>
                                {row.resultnumber}
                              </a>
                            ))}
                          </div>
                        </div>
                        <div className='w-full xl:w-[25%] text-center flex justify-center items-center'>
                          <div className='w-[70%] xl:w-[50%] bg-slate-200 rounded place-content-center border border-gray-200 flex justify-center'>
                            {threebot.map((row) => (
                              <a className='text-[10px] xl:text-[20px] text-zinc-600 font-bold' style={{ paddingLeft: '1rem', paddingRight: '1rem' }} key={row.id}>
                                {row.resultnumber}
                              </a>
                            ))}
                          </div>
                        </div>

                        <div className='w-full xl:w-[25%] text-center flex justify-center items-center'>
                          <div className='w-[70%] xl:w-[50%] bg-slate-200 rounded place-content-center border border-gray-200 flex justify-center'>
                            {twobot.map((row) => (
                              <a className='text-[10px] xl:text-[20px] text-zinc-600 font-bold' style={{ paddingLeft: '1rem', paddingRight: '1rem' }} key={row.id}>
                                {row.resultnumber}
                              </a>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                className='xl:flex flex-row rounded xl:space-x-2 xl:space-y-0 space-y-2'>
                <div className='w-[100%] h-auto rounded'>
                  <div className='xl:flex flex-col w-[100%] flex-row bg-sky-50 rounded-t border-black drop-shadow-xl'>
                    <div className='flex flex-row bg-zinc-700 rounded-t p-2 space-x-2'>
                      <img className='w-[35px] ml-3' src='https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Laos.svg' alt="" />
                      <span className=''>หวยลาว</span>
                      <div class="w-[17%] h-[20px] text-center mt-1 rounded border-black border-1 bg-white flex justify-center items-center">
                        {totableLaosGov.first.map((row) => (
                          <span class="text-black font-bold xl:text-[9px] text-[7px]">{moment(row.date).format('ll')}</span>
                        ))}
                      </div>
                    </div>
                    {loading ? (
                      <LoadingComponent />
                    ) : (
                      <div className='p-2 bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 border-black border-[1px]'>
                        <div className='w-full flex flex-row'>
                          <div className='w-full border-b-white border-b-[1px] text-center'>
                            <span className="text-white text-[15px] xl:text-[18px]">3 ตัวบน</span>
                          </div>
                          <div className='w-full border-white border-b-[1px] text-center'>
                            <span className="text-white text-[15px] xl:text-[18px]">2 ตัวล่าง</span>
                          </div>
                        </div>
                        <div className='w-full p-1 flex flex-row'>
                          <div className='w-full text-center'>
                            <div className='flex flex-row space-x-2 place-content-center'>
                              <div className='xl:w-[50%] bg-slate-200 px-2 rounded place-content-center border border-gray-200 flex justify-center'>
                                {totableLaosGov.three_top.map((row) => (
                                  <span key={row.id} className="text-black font-bold text-[15px] xl:text-[16px]">
                                    {row.resultnumber ? row.resultnumber : "XXX"}
                                  </span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className='w-full flex justify-center items-center'>
                            <div className='xl:w-[50%] bg-slate-200 px-2 rounded place-content-center border border-gray-200 flex justify-center'>
                              {totableLaosGov.twobot.map((row) => (
                                <span key={row.id} className="text-black font-bold text-[15px] xl:text-[16px]">
                                  {row.resultnumber ? row.resultnumber : "XX"}
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                  </div>
                </div>
                <div className='w-[100%] h-auto bg-black rounded'>
                  <div className='xl:flex flex-col w-[100%] flex-row bg-sky-50 rounded-t border-black drop-shadow-xl'>
                    <div className='flex flex-row bg-zinc-700 rounded-t p-2 space-x-2'>
                      <img className='w-[35px] ml-3' src='https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Vietnam.svg' alt="" />
                      <span className=''>หวยฮานอย</span>
                      <div class="w-[17%] h-[20px] text-center mt-1 rounded border-black border-1 bg-white flex justify-center items-center">
                        {totableVtPhuqa.first.length > 0 && (
                          <span class="text-black font-bold xl:text-[9px] text-[7px]">
                            {moment(totableVtPhuqa.first[0].date).format('ll')}
                          </span>
                        )}

                      </div>
                      <div class="w-[17%] h-[20px] text-center mt-1 rounded border-black border-1 bg-white flex justify-center items-center">
                        {totableVtPhuqa.first.length > 0 && (
                          <span class="text-purple-500 font-bold xl:text-[9px] text-[7px]">
                            รอบที่ {totableVtPhuqa.first[0].round ? totableVtPhuqa.first[0].round : "X"}
                          </span>
                        )}
                      </div>
                    </div>
                    {loading ? (
                      <LoadingComponent />
                    ) : (
                      <div className='p-2 bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 border-black border-[1px]'>
                        <div className='w-full flex flex-row'>
                          <div className='w-full border-b-white border-b-[1px] text-center'>
                            <span className="text-white text-[15px] xl:text-[18px]">3 ตัวบน</span>
                          </div>
                          <div className='w-full border-white border-b-[1px] text-center'>
                            <span className="text-white text-[15px] xl:text-[18px]">2 ตัวล่าง</span>
                          </div>
                        </div>
                        <div className='w-full p-1 flex flex-row'>
                          <div className='w-full text-center'>
                            <div className='flex flex-row space-x-2 place-content-center'>
                              <div className='xl:w-[50%] bg-slate-200 px-2 rounded place-content-center border border-gray-200 flex justify-center'>
                                {totableVtPhuqa.first.length > 0 && (
                                  <span className="text-black font-bold text-[15px] xl:text-[16px]">
                                    {totableVtPhuqa.first[0].resultnumber ? totableVtPhuqa.first[0].resultnumber.slice(2) : "XXX"}
                                  </span>
                                )}
                              </div>

                            </div>
                          </div>
                          <div className='w-full flex justify-center items-center'>
                            <div className='xl:w-[50%] bg-slate-200 px-2 rounded place-content-center border border-gray-200 flex justify-center'>
                              {totableVtPhuqa.first.length > 0 && (
                                <span className="text-black font-bold text-[15px] xl:text-[16px]">
                                  {totableVtPhuqa.first[0].resultnumber ? JSON.parse(totableVtPhuqa.first[0].d_other).second.slice(3) : "XX"}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='w-[100%] h-auto bg-black rounded'>
                  <div className='xl:flex flex-col w-[100%] flex-row bg-sky-50 rounded-t border-black drop-shadow-xl'>
                    <div className='flex flex-row bg-zinc-700 rounded-t p-2 space-x-2'>
                      <img className='w-[35px] ml-3' src='https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Vietnam.svg' alt="" />
                      <span className=''>หวยฮานอย</span>
                      <div class="w-[17%] h-[20px] text-center mt-1 rounded border-black border-1 bg-white flex justify-center items-center">
                        <span class="text-black font-bold xl:text-[9px] text-[7px]">
                          {totableVtHaiphong.first.length > 0 ? moment(totableVtHaiphong.first[0].date).format('ll') : "ว-ด-ป"}
                        </span>
                      </div>
                      <div class="w-[17%] h-[20px] text-center mt-1 rounded border-black border-1 bg-white flex justify-center items-center">
                        {totableVtHaiphong.first.length > 0 && (
                          <span class="text-purple-500 font-bold xl:text-[9px] text-[7px]">
                            รอบที่ {totableVtHaiphong.first[0].round ? totableVtHaiphong.first[0].round : "X"}
                          </span>
                        )}
                      </div>
                    </div>
                    {loading ? (
                      <LoadingComponent />
                    ) : (
                      <div className='p-2 bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 border-black border-[1px]'>
                        <div className='w-full flex flex-row'>
                          <div className='w-full border-b-white border-b-[1px] text-center'>
                            <span className="text-white text-[15px] xl:text-[18px]">3 ตัวบน</span>
                          </div>
                          <div className='w-full border-white border-b-[1px] text-center'>
                            <span className="text-white text-[15px] xl:text-[18px]">2 ตัวล่าง</span>
                          </div>
                        </div>
                        <div className='w-full p-1 flex flex-row'>
                          <div className='w-full text-center'>
                            <div className='flex flex-row space-x-2 place-content-center'>
                              <div className='xl:w-[50%] bg-slate-200 px-2 rounded place-content-center border border-gray-200 flex justify-center'>

                                <span className="text-black font-bold text-[15px] xl:text-[16px]">
                                  {totableVtHaiphong.first.length > 0 ? totableVtHaiphong.first[0].resultnumber.slice(2) : "XXX"}
                                </span>

                              </div>

                            </div>
                          </div>
                          <div className='w-full flex justify-center items-center'>
                            <div className='xl:w-[50%] bg-slate-200 px-2 rounded place-content-center border border-gray-200 flex justify-center'>

                              <span className="text-black font-bold text-[15px] xl:text-[16px]">
                                {totableVtHaiphong.first.length > 0 ? JSON.parse(totableVtHaiphong.first[0].d_other).second.slice(3) : "XX"}
                              </span>

                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                className='xl:flex flex-row rounded xl:space-x-2 xl:space-y-0 space-y-2'>
                <div className='w-[100%] h-auto bg-black rounded'>
                  <div className='xl:flex flex-col w-[100%] flex-row bg-sky-50 rounded-t border-black drop-shadow-xl'>
                    <div className='flex flex-row bg-zinc-700 rounded-t p-2 space-x-2'>
                      <img className='w-[35px] ml-3' src='https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Vietnam.svg' alt="" />
                      <span className=''>หวยฮานอย</span>
                      <div class="w-[17%] h-[20px] text-center mt-1 rounded border-black border-1 bg-white flex justify-center items-center">

                        <span class="text-black font-bold xl:text-[9px] text-[7px]">
                          {totableVtNgheAn.first.length > 0 ? moment(totableVtNgheAn.first[0].date).format('ll') : "ว-ด-ป"}
                        </span>



                      </div>
                      <div class="w-[17%] h-[20px] text-center mt-1 rounded border-black border-1 bg-white flex justify-center items-center">
                        {totableVtNgheAn.first.length > 0 && (
                          <span class="text-purple-500 font-bold xl:text-[9px] text-[7px]">
                            รอบที่ {totableVtNgheAn.first[0].round ? totableVtNgheAn.first[0].round : "X"}
                          </span>
                        )}
                      </div>
                    </div>
                    {loading ? (
                      <LoadingComponent />
                    ) : (
                      <div className='p-2 bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 border-black border-[1px]'>
                        <div className='w-full flex flex-row'>
                          <div className='w-full border-b-white border-b-[1px] text-center'>
                            <span className="text-white text-[15px] xl:text-[18px]">3 ตัวบน</span>
                          </div>
                          <div className='w-full border-white border-b-[1px] text-center'>
                            <span className="text-white text-[15px] xl:text-[18px]">2 ตัวล่าง</span>
                          </div>
                        </div>
                        <div className='w-full p-1 flex flex-row'>
                          <div className='w-full text-center'>
                            <div className='flex flex-row space-x-2 place-content-center'>
                              <div className='xl:w-[50%] bg-slate-200 px-2 rounded place-content-center border border-gray-200 flex justify-center'>

                                <span className="text-black font-bold text-[15px] xl:text-[16px]">
                                  {totableVtNgheAn.first.length > 0 ? totableVtNgheAn.first[0].resultnumber.slice(2) : "XXX"}
                                </span>

                              </div>

                            </div>
                          </div>
                          <div className='w-full flex justify-center items-center'>
                            <div className='xl:w-[50%] bg-slate-200 px-2 rounded place-content-center border border-gray-200 flex justify-center'>

                              <span className="text-black font-bold text-[15px] xl:text-[16px]">
                                {totableVtNgheAn.first.length > 0 ? JSON.parse(totableVtNgheAn.first[0].d_other).second.slice(3) : "XX"}
                              </span>

                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='w-[100%] h-auto bg-black rounded'>
                  <div className='xl:flex flex-col w-[100%] flex-row bg-sky-50 rounded-t border-black drop-shadow-xl'>
                    <div className='flex flex-row bg-zinc-700 rounded-t p-2 space-x-2'>
                      <img className='w-[35px] ml-3' src='https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Vietnam.svg' alt="" />
                      <span className=''>หวยฮานอย</span>
                      <div class="w-[17%] h-[20px] text-center mt-1 rounded border-black border-1 bg-white flex justify-center items-center">

                        <span class="text-black font-bold xl:text-[9px] text-[7px]">
                          {totableVtNhaTrang.first.length > 0 ? moment(totableVtNhaTrang.first[0].date).format('ll') : "ว-ด-ป"}
                        </span>



                      </div>
                      <div class="w-[17%] h-[20px] text-center mt-1 rounded border-black border-1 bg-white flex justify-center items-center">
                        {totableVtNhaTrang.first.length > 0 && (
                          <span class="text-purple-500 font-bold xl:text-[9px] text-[7px]">
                            รอบที่ {totableVtNhaTrang.first[0].round ? totableVtNhaTrang.first[0].round : "X"}
                          </span>
                        )}
                      </div>
                    </div>
                    {loading ? (
                      <LoadingComponent />
                    ) : (
                      <div className='p-2 bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 border-black border-[1px]'>
                        <div className='w-full flex flex-row'>
                          <div className='w-full border-b-white border-b-[1px] text-center'>
                            <span className="text-white text-[15px] xl:text-[18px]">3 ตัวบน</span>
                          </div>
                          <div className='w-full border-white border-b-[1px] text-center'>
                            <span className="text-white text-[15px] xl:text-[18px]">2 ตัวล่าง</span>
                          </div>
                        </div>
                        <div className='w-full p-1 flex flex-row'>
                          <div className='w-full text-center'>
                            <div className='flex flex-row space-x-2 place-content-center'>
                              <div className='xl:w-[50%] bg-slate-200 px-2 rounded place-content-center border border-gray-200 flex justify-center'>

                                <span className="text-black font-bold text-[15px] xl:text-[16px]">
                                  {totableVtNhaTrang.first.length > 0 ? totableVtNhaTrang.first[0].resultnumber.slice(2) : "XXX"}
                                </span>

                              </div>

                            </div>
                          </div>
                          <div className='w-full flex justify-center items-center'>
                            <div className='xl:w-[50%] bg-slate-200 px-2 rounded place-content-center border border-gray-200 flex justify-center'>

                              <span className="text-black font-bold text-[15px] xl:text-[16px]">
                                {totableVtNhaTrang.first.length > 0 ? JSON.parse(totableVtNhaTrang.first[0].d_other).second.slice(3) : "XX"}
                              </span>

                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='w-[100%] h-auto bg-black rounded'>
                  <div className='xl:flex flex-col w-[100%] flex-row bg-sky-50 rounded-t border-black drop-shadow-xl'>
                    <div className='flex flex-row bg-zinc-700 rounded-t p-2 space-x-2'>
                      <img className='w-[35px] ml-3' src='https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Vietnam.svg' alt="" />
                      <span className=''>หวยฮานอย</span>
                      <div class="w-[17%] h-[20px] text-center mt-1 rounded border-black border-1 bg-white flex justify-center items-center">
                        {totableVtDaLat.first.length > 0 && (
                          <span class="text-black font-bold xl:text-[9px] text-[7px]">
                            {moment(totableVtDaLat.first[0].date).format('ll')}
                          </span>
                        )}
                      </div>
                      <div class="w-[17%] h-[20px] text-center mt-1 rounded border-black border-1 bg-white flex justify-center items-center">
                        {totableVtDaLat.first.length > 0 && (
                          <span class="text-purple-500 font-bold xl:text-[9px] text-[7px]">
                            รอบที่ {totableVtDaLat.first[0].round ? totableVtDaLat.first[0].round : "X"}
                          </span>
                        )}
                      </div>
                    </div>
                    {loading ? (
                      <LoadingComponent />
                    ) : (
                      <div className='p-2 bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 border-black border-[1px]'>
                        <div className='w-full flex flex-row'>
                          <div className='w-full border-b-white border-b-[1px] text-center'>
                            <span className="text-white text-[15px] xl:text-[18px]">3 ตัวบน</span>
                          </div>
                          <div className='w-full border-white border-b-[1px] text-center'>
                            <span className="text-white text-[15px] xl:text-[18px]">2 ตัวล่าง</span>
                          </div>
                        </div>
                        <div className='w-full p-1 flex flex-row'>
                          <div className='w-full text-center'>
                            <div className='flex flex-row space-x-2 place-content-center'>
                              <div className='xl:w-[50%] bg-slate-200 px-2 rounded place-content-center border border-gray-200 flex justify-center'>
                                {totableVtDaLat.first.length > 0 && (
                                  <span className="text-black font-bold text-[15px] xl:text-[16px]">
                                    {totableVtDaLat.first[0].resultnumber ? totableVtDaLat.first[0].resultnumber.slice(2) : "XXX"}
                                  </span>
                                )}
                              </div>

                            </div>
                          </div>
                          <div className='w-full flex justify-center items-center'>
                            <div className='xl:w-[50%] bg-slate-200 px-2 rounded place-content-center border border-gray-200 flex justify-center'>
                              {totableVtDaLat.first.length > 0 && (
                                <span className="text-black font-bold text-[15px] xl:text-[16px]">
                                  {totableVtDaLat.first[0].resultnumber ? JSON.parse(totableVtDaLat.first[0].d_other).second.slice(3) : "XX"}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </motion.div>
              <div className='xl:flex flex-col w-[100%] bg-sky-50 rounded-t border-black drop-shadow-xl '>
                <div className='flex flex-col bg-zinc-700 rounded-t p-2 space-x-2 justify-center items-center'>
                  <EmojiEventsIcon className="ml-3" />
                  <div class="xl:w-[10%] w-[20%] h-[20px] text-center mt-1 rounded border-black border-1 bg-white flex justify-center items-center">
                    <span class="text-black font-bold xl:text-[9px] text-[7px]">{moment().format('ll')}</span>
                  </div>
                </div>
                <div className='flex flex-row bg-zinc-700 p-2 justify-center items-center'>
                  <div onClick={() => handleYeekeeSelect('yeekee_5')} className='bg-red-700 w-full p-2 text-center hover:bg-red-900'>
                    <span class="text-white font-bold text-[10px] xl:text-[20px]">กดดูผลยี่กี</span>
                  </div>
                  <div onClick={() => handleYeekeeSelect('yeekee_10')} className='bg-purple-700 w-full p-2 text-center hover:bg-purple-900'>
                    <span class="text-white font-bold text-[10px] xl:text-[20px]">กดดูผลยี่กี VIP</span>
                  </div>
                  <div onClick={() => handleYeekeeSelect('yeekee_15')} className='bg-green-700 w-full p-2 text-center hover:bg-green-900'>
                    <span class="text-white font-bold text-[10px] xl:text-[20px]">กดดูผลยี่กี VVIP</span>
                  </div>
                </div>

                {Yeekeeloading ? (
                  <LoadingComponent />
                ) : (
                  <div className='border-black border-[1px]'>
                    {yeekeeSelect === 'yeekee_5' &&
                      <div className='w-full p-2 overflow-auto'>
                        <div className='grid grid-cols-1 xl:grid-cols-1 lg:grid-cols-2 gap-1'>
                          {yeekeeresult.map((row) => (
                            <div className='w-full bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 flex flex-col border-[1px] rounded-lg'>
                              <div className='w-[full] pt-2 rounded-t-lg justify-center items-center text-center bg-purple-700'>
                                <span className="text-white text-[10px] xl:text-[15px]">จับยี่กีปกติ | รอบที่ {row.round}</span>
                              </div>
                              <div className='w-full flex flex-row'>
                                <div className='w-full border-b-white border-b-[1px] text-center'>
                                  <span className="text-white text-[10px] xl:text-[15px]">3 ตัวบน</span>
                                </div>
                                <div className='w-full border-white border-b-[1px] text-center'>
                                  <span className="text-white text-[10px] xl:text-[15px]">2 ตัวล่าง</span>
                                </div>
                              </div>
                              <div className='w-full p-1 flex flex-row'>
                                <div className='w-full text-center'>
                                  <div className='flex flex-row space-x-2 place-content-center'>
                                    <div className='xl:w-[50%] bg-slate-200 px-2 rounded place-content-center border border-gray-200 flex justify-center'>
                                      <span className="text-black font-bold text-[10px] xl:text-[16px]">{row.resultnumber.slice(0, 3)}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className='w-full flex justify-center items-center'>
                                  <div className='xl:w-[50%] bg-slate-200 px-2 rounded place-content-center border border-gray-200 flex justify-center'>
                                    <span className="text-black font-bold text-[10px] xl:text-[16px]">{row.resultnumber.slice(3, 5)}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>



                    }
                    {yeekeeSelect === 'yeekee_10' &&
                      <div className='w-full p-2 overflow-auto'>
                        <div className='grid grid-cols-1 xl:grid-cols-1 lg:grid-cols-2 gap-1'>
                          {yeekeeresult.map((row) => (
                            <div className='w-full bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 flex flex-col border-[1px] rounded-lg'>
                              <div className='w-[full] pt-2 rounded-t-lg justify-center items-center text-center bg-purple-700'>
                                <span className="text-white text-[10px] xl:text-[15px]">จับยี่กี 10 นาที | รอบที่ {row.round}</span>
                              </div>
                              <div className='w-full flex flex-row'>
                                <div className='w-full border-b-white border-b-[1px] text-center'>
                                  <span className="text-white text-[10px] xl:text-[15px]">3 ตัวบน</span>
                                </div>
                                <div className='w-full border-white border-b-[1px] text-center'>
                                  <span className="text-white text-[10px] xl:text-[15px]">2 ตัวล่าง</span>
                                </div>
                              </div>
                              <div className='w-full p-1 flex flex-row'>
                                <div className='w-full text-center'>
                                  <div className='flex flex-row space-x-2 place-content-center'>
                                    <div className='xl:w-[50%] bg-slate-200 px-2 rounded place-content-center border border-gray-200 flex justify-center'>
                                      <span className="text-black font-bold text-[10px] xl:text-[16px]">{row.resultnumber.slice(0, 3)}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className='w-full flex justify-center items-center'>
                                  <div className='xl:w-[50%] bg-slate-200 px-2 rounded place-content-center border border-gray-200 flex justify-center'>
                                    <span className="text-black font-bold text-[10px] xl:text-[16px]">{row.resultnumber.slice(3, 5)}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>}

                    {yeekeeSelect === 'yeekee_15' &&
                      <div className='w-full p-2 overflow-auto'>
                        <div className='grid grid-cols-1 xl:grid-cols-1 lg:grid-cols-2 gap-1'>
                          {yeekeeresult.map((row) => (
                            <div className='w-full bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 flex flex-col border-[1px] rounded-lg'>
                              <div className='w-[full] pt-2 rounded-t-lg justify-center items-center text-center bg-purple-700'>
                                <span className="text-white text-[10px] xl:text-[15px]">จับยี่กี 15 นาที | รอบที่ {row.round}</span>
                              </div>
                              <div className='w-full flex flex-row'>
                                <div className='w-full border-b-white border-b-[1px] text-center'>
                                  <span className="text-white text-[10px] xl:text-[15px]">3 ตัวบน</span>
                                </div>
                                <div className='w-full border-white border-b-[1px] text-center'>
                                  <span className="text-white text-[10px] xl:text-[15px]">2 ตัวล่าง</span>
                                </div>
                              </div>
                              <div className='w-full p-1 flex flex-row'>
                                <div className='w-full text-center'>
                                  <div className='flex flex-row space-x-2 place-content-center'>
                                    <div className='xl:w-[50%] bg-slate-200 px-2 rounded place-content-center border border-gray-200 flex justify-center'>
                                      <span className="text-black font-bold text-[10px] xl:text-[16px]">{row.resultnumber.slice(0, 3)}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className='w-full flex justify-center items-center'>
                                  <div className='xl:w-[50%] bg-slate-200 px-2 rounded place-content-center border border-gray-200 flex justify-center'>
                                    <span className="text-black font-bold text-[10px] xl:text-[16px]">{row.resultnumber.slice(3, 5)}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>}
                  </div>
                )}

              </div>


            </div>
          </motion.div>

        </div>
      </motion.div>
      {/* <Footer/> */}

      {open &&
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle className='bg-gradient-to-r from-purple-800 text-[20px] text-white' id="alert-dialog-title">
              {"สมัครสมาชิกกับเรา"}
            </DialogTitle>
            <div className="App">
              <form onSubmit={handleSubmit}>
                {step === 1 && (
                  <>
                    <div className='w-full p-1 flex flex-col justify-center items-center'>
                      <img className='w-[15%] mt-1' src="/images/registered.png" alt="Your Image" />
                      <div className='w-full p-3 space-x-1 flex flex-row justify-center items-center'>
                        <div className='w-[10px] h-[10px] rounded-full bg-purple-600 border-[1px] border-slate-500'></div>
                        <div className='w-[10px] h-[10px] rounded-full bg-white border-[1px] border-slate-500'></div>
                        <div className='w-[10px] h-[10px] rounded-full bg-white border-[1px] border-slate-500'></div>
                        <div className='w-[10px] h-[10px] rounded-full bg-white border-[1px] border-slate-500'></div>
                      </div>
                      <div className='register p-2 grid grid-cols-2 gap-4'>
                        <input
                          type="text"
                          value={username}
                          placeholder="ชื่อผู้ใช้งาน"
                          onChange={(e) => setUsername(e.target.value)}
                          className='col-span-2 px-4 py-2 border-b-[2px] border-slate-500 focus:outline-none focus:border-purple-700 hover:bg-purple-50 '
                        />
                        <input
                          type="password"
                          value={password}
                          placeholder="รหัสผ่าน"
                          onChange={(e) => setPassword(e.target.value)}
                          className='col-span-2 px-4 py-2 border-b-[2px] border-slate-500 focus:outline-none focus:border-purple-700 hover:bg-purple-50 '
                        />

                      </div>
                    </div>
                    <DialogActions>
                      <button onClick={handleNext} className="bg-purple-600 mr-1 hover:bg-purple-700 text-white font-bold py-2 px-4 mr-1 rounded">
                        <ArrowForwardIosIcon />
                      </button>
                    </DialogActions>
                  </>
                )}
                {step === 2 && (
                  <>
                    <div className='w-full p-1 flex flex-col justify-center items-center'>
                      <img className='w-[15%] mt-1' src="/images/registered.png" alt="Your Image" />
                      <div className='w-full p-3 space-x-1 flex flex-row justify-center items-center'>
                        <div className='w-[10px] h-[10px] rounded-full bg-white border-[1px] border-slate-500'></div>
                        <div className='w-[10px] h-[10px] rounded-full bg-purple-500 border-[1px] border-slate-500'></div>
                        <div className='w-[10px] h-[10px] rounded-full bg-white border-[1px] border-slate-500'></div>
                        <div className='w-[10px] h-[10px] rounded-full bg-white border-[1px] border-slate-500'></div>
                      </div>
                      <div className='register p-2 grid grid-cols-2 gap-4'>
                        <input
                          type="text"
                          value={fname}
                          placeholder="ชื่อจริง"
                          onChange={(e) => setFname(e.target.value)}
                          className='col-span-2 px-4 py-2 border-b-[2px] border-slate-500 focus:outline-none focus:border-purple-700 hover:bg-purple-50 '
                        />
                        <input
                          type="text"
                          value={lname}
                          placeholder="นามสกุล"
                          onChange={(e) => setLname(e.target.value)}
                          className='col-span-2 px-4 py-2 border-b-[2px] border-slate-500 focus:outline-none focus:border-purple-700 hover:bg-purple-50 '
                        />

                      </div>
                    </div>
                    <DialogActions>
                      <button onClick={handlePrevious} className="bg-purple-600 mr-1 hover:bg-purple-700 text-white font-bold py-2 px-4 mr-1 rounded">
                        <ArrowBackIosIcon />
                      </button>
                      <button onClick={handleNext} className="bg-purple-600 mr-1 hover:bg-purple-700 text-white font-bold py-2 px-4 mr-1 rounded">
                        <ArrowForwardIosIcon />
                      </button>
                    </DialogActions>
                  </>
                )}
                {step === 3 && (
                  <>
                    <div className='w-full p-1 flex flex-col justify-center items-center'>
                      <img className='w-[15%] mt-1' src="/images/phone-call.png" alt="Your Image" />
                      <div className='w-full p-3 space-x-1 flex flex-row justify-center items-center'>
                        <div className='w-[10px] h-[10px] rounded-full bg-white border-[1px] border-slate-500'></div>
                        <div className='w-[10px] h-[10px] rounded-full bg-white border-[1px] border-slate-500'></div>
                        <div className='w-[10px] h-[10px] rounded-full bg-purple-500 border-[1px] border-slate-500'></div>
                        <div className='w-[10px] h-[10px] rounded-full bg-white border-[1px] border-slate-500'></div>
                      </div>
                      <div className='register p-2 grid grid-cols-2 gap-4'>
                        <input
                          type="number"
                          value={mobileNumber}
                          placeholder="เบอร๋โทรศัพท์ของท่าน"
                          onChange={(e) => setMobileNumber(e.target.value)}
                          className='col-span-2 px-4 py-2 border-b-[2px] border-slate-500 focus:outline-none focus:border-purple-700 hover:bg-purple-50 '
                        />

                      </div>
                    </div>
                    <DialogActions>
                      <button onClick={handlePrevious} className="bg-purple-600 mr-1 hover:bg-purple-700 text-white font-bold py-2 px-4 mr-1 rounded">
                        <ArrowBackIosIcon />
                      </button>
                      <button onClick={handleNext} className="bg-purple-600 mr-1 hover:bg-purple-700 text-white font-bold py-2 px-4 mr-1 rounded">
                        <ArrowForwardIosIcon />
                      </button>
                    </DialogActions>
                  </>
                )}
                {step === 4 && (
                  <>
                    <div className='w-full p-1 flex flex-col justify-center items-center'>
                      <img className='w-[15%] mt-1' src="/images/money.png" alt="Your Image" />
                      <div className='w-full p-3 space-x-1 flex flex-row justify-center items-center'>
                        <div className='w-[10px] h-[10px] rounded-full bg-white border-[1px] border-slate-500'></div>
                        <div className='w-[10px] h-[10px] rounded-full bg-white border-[1px] border-slate-500'></div>
                        <div className='w-[10px] h-[10px] rounded-full bg-white border-[1px] border-slate-500'></div>
                        <div className='w-[10px] h-[10px] rounded-full bg-purple-500 border-[1px] border-slate-500'></div>
                      </div>
                      <div className='register p-2 grid grid-cols-2 gap-4'>
                        <span>เลือกธนาคาร</span>
                        <select
                          id="employee"
                          name="employee"
                          onChange={e => setBank(e.target.value)}
                          className='bg-gray-100 rounded-lg px-4 py-2'
                        >
                          <option></option>
                          <option value="KBANK">ธนาคาร กษิกรไทย</option>
                          <option value="SCB">ธนาคาร ไทยพาณิชย์</option>
                          <option value="KTB">ธนาคาร กรุงไทย</option>
                          <option value="BBL">ธนาคาร กรุงเทพ</option>
                          <option value="GSB">ธนาคาร ออมสิน</option>
                        </select>

                        <input
                          type="number"
                          value={banknumber}
                          placeholder="เลขบัญชีธนาคาร" onChange={(e) => setBanknumber(e.target.value)}
                          className='col-span-2 px-4 py-2 border-b-[2px] border-slate-500 focus:outline-none focus:border-purple-700 hover:bg-purple-50 '
                        />

                        <span>Agent</span>
                        <select
                          id="employee"
                          name="employee"
                          onChange={e => setAgent(e.target.value)}
                          className='bg-gray-100 rounded-lg px-4 py-2'
                        >
                          <option></option>
                          {agentlist.map((row) => (
                            <option value={row.Username}>{row.Username}</option>
                          ))}
                        </select>

                      </div>
                    </div>
                    <DialogActions>
                      <button onClick={handlePrevious} className="bg-purple-600 mr-1 hover:bg-purple-700 text-white font-bold py-2 px-4 mr-1 rounded">
                        <ArrowBackIosIcon />
                      </button>
                      <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{
                          opacity: 1,
                          y: 0,
                          scale: [1, 1.05, 1], // Scale animation effect
                        }}
                        transition={{
                          duration: 1, // Duration of the animation
                        }}
                        whileHover={{ y: -4, scale: 1.05 }}
                        style={{ height: '100% !important' }}
                      >
                        <button type="submit" className="bg-green-600 mr-1 hover:bg-green-700 text-white font-bold py-2 px-4 mr-1 rounded">
                          <SaveAltIcon /> สมัครสมาชิก
                        </button>
                      </motion.div>
                    </DialogActions>
                  </>
                )}
              </form>
            </div>
          </Dialog>
        </motion.div>}
      <Footer/>
    </div>
  );
}
