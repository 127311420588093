import React, { useState, useEffect } from 'react'
import { Button } from '@mui/material';
import moment from 'moment';
import { motion } from 'framer-motion';
import 'moment/locale/th';
import './hanoiroundselect.css'
import { Link } from 'react-router-dom'

function Hanoiroundselect(props) {

  function getDate(time) {
    const now = moment();
    const [hours, minutes] = time.toString().split('.').map(parseFloat);
    const targetTime = moment().set({ hours, minutes, seconds: 0 });

    if (now > targetTime) {
      const tomorrow = moment(now).add(1, 'day');
      return tomorrow.format('YYYY-MM-DD');
    } else {
      return now.format('YYYY-MM-DD');
    }
  }

  const [countdown1, setCountdown1] = useState('');
  const [countdown2, setCountdown2] = useState('');
  const [countdown3, setCountdown3] = useState('');
  const [countdown4, setCountdown4] = useState('');
  const [countdown5, setCountdown5] = useState('');


  useEffect(() => {
    const targetDate1 = moment(`${getDate(16.30)} 16:30:00`, `YYYY-MM-DD HH:mm:ss`);
    const targetDate2 = moment(`${getDate(17.30)} 17:30:00`, `YYYY-MM-DD HH:mm:ss`);
    const targetDate3 = moment(`${getDate(18.30)} 18:30:00`, `YYYY-MM-DD HH:mm:ss`);
    const targetDate4 = moment(`${getDate(19.30)} 19:30:00`, `YYYY-MM-DD HH:mm:ss`);
    const targetDate5 = moment(`${getDate(20.30)} 20:30:00`, `YYYY-MM-DD HH:mm:ss`);

    const interval = setInterval(() => {
      const now = moment();

      const diff = moment.duration(targetDate1.diff(now));
      const hours = diff.hours();
      const minutes = diff.minutes();
      const seconds = diff.seconds();
      setCountdown1(`${hours}h:${minutes}m:${seconds}s`);

      const diff2 = moment.duration(targetDate2.diff(now));
      const hours2 = diff2.hours();
      const minutes2 = diff2.minutes();
      const seconds2 = diff2.seconds();
      setCountdown2(`${hours2}h:${minutes2}m:${seconds2}s`);

      const diff3 = moment.duration(targetDate3.diff(now));
      const hours3 = diff3.hours();
      const minutes3 = diff3.minutes();
      const seconds3 = diff3.seconds();
      setCountdown3(`${hours3}h:${minutes3}m:${seconds3}s`);

      const diff4 = moment.duration(targetDate4.diff(now));
      const hours4 = diff4.hours();
      const minutes4 = diff4.minutes();
      const seconds4 = diff4.seconds();
      setCountdown4(`${hours4}h:${minutes4}m:${seconds4}s`);

      const diff5 = moment.duration(targetDate5.diff(now));
      const hours5 = diff5.hours();
      const minutes5 = diff5.minutes();
      const seconds5 = diff5.seconds();
      setCountdown5(`${hours5}h:${minutes5}m:${seconds5}s`);

    }, 1000);

    return () => clearInterval(interval);
  }, []);






  return (
    (
      <>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }} style={{ display: 'flex', flexDirection: 'column' }}>
          <div className='flex flex-col justify-center'>
            <div className='w-[100%] flex flex-row h-[40px] p-2 justify-center items-center bg-slate-700 space-x-2 border-[1px] border-slate-700 rounded-t'>
              <Link to="/dashboard" className='md:w-[5%] text-center bg-red-600 rounded justify-center border shadow-md flex justify-center hover:bg-red-700'>
                <span className='text-white font-bold'>กลับ</span>
              </Link>
              <span className='text-white font-bold text-m'>
              </span>
              <div className='md:w-[12%] text-center bg-slate-200 rounded place-content-center border border-gray-200 flex justify-center'>
                <span className='text-purple-700 font-bold'>
                  แทงหวย ฮานอย
                </span>
              </div>
            </div>
            <div className='flex flex-col bg-gradient-to-r from-purple-400 from-30% via-purple-450 via-50% to-purple-700 to-90% border-[1px] border-slate-700 p-1 w-[100%] h-[auto] drop-shadow-2xl'>
              <div className='flex_type md:p-2 md:justify-left md:items-left'>
                <Link to="/dashboard/hanoi/1">
                  <div className="flex flex-col md:w-[230px] w-[90vw] h-[100px] bg-black border-[1px] border-black drop-shadow-xl rounded">
                    <div className="flex flex-row w-[100%] space-x-1 h-[50%] pl-1" >
                      <div className='h-[50%] w-[30px] bg-white rounded mt-1'>
                        <img src='https://www.meesok.com/static/media/hanoilogo.89df4e1109ca737a51f4.png'></img>
                      </div>
                      <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded'>
                        <div className='text-right bg-gradient-to-r from-yellow-500 from-10% via-yellow-600 via-30% to-yellow-10 to-90% h-[50%] rounded-t'>
                          <span className='text-black mr-1'>หวยฮานอย รอบที่ 1</span>
                        </div>
                        <div className='text-right bg-gradient-to-r from-amber-500 from-10% via-amber-600 via-30% to-amber-10 to-90% h-[50%] rounded-b'>
                          <span className='text-red-600 text-xs mr-1'>ปิดรับ   16.30</span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row w-[100%] h-[50%]" >
                      <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded-b'>
                        <div className='text-right bg-slate-700 h-[100%] p-3 rounded-b'>
                          <span className='text-slate-500 font-bold text-l'>
                            {countdown1}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dashboard/hanoi/2">
                  <div className="flex flex-col md:w-[230px] w-[90vw] h-[100px] bg-black border-[1px] border-black drop-shadow-xl rounded">
                    <div className="flex flex-row w-[100%] space-x-1 h-[50%] pl-1" >
                      <div className='h-[50%] w-[30px] bg-white rounded mt-1'>
                        <img src='https://www.meesok.com/static/media/hanoilogo.89df4e1109ca737a51f4.png'></img>
                      </div>
                      <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded'>
                        <div className='text-right bg-gradient-to-r from-yellow-500 from-10% via-yellow-600 via-30% to-yellow-10 to-90% h-[50%] rounded-t'>
                          <span className='text-black mr-1'>หวยฮานอย รอบที่ 2</span>
                        </div>
                        <div className='text-right bg-gradient-to-r from-amber-500 from-10% via-amber-600 via-30% to-amber-10 to-90% h-[50%] rounded-b'>
                          <span className='text-red-600 text-xs mr-1'>ปิดรับ   17.30</span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row w-[100%] h-[50%]" >
                      <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded-b'>
                        <div className='text-right bg-slate-700 h-[100%] p-3 rounded-b'>
                          <span className='text-slate-500 font-bold text-l'>
                            {countdown2}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dashboard/hanoi/3">
                  <div className="flex flex-col md:w-[230px] w-[90vw] h-[100px] bg-black border-[1px] border-black drop-shadow-xl rounded">
                    <div className="flex flex-row w-[100%] space-x-1 h-[50%] pl-1" >
                      <div className='h-[50%] w-[30px] bg-white rounded mt-1'>
                        <img src='https://www.meesok.com/static/media/hanoilogo.89df4e1109ca737a51f4.png'></img>
                      </div>
                      <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded'>
                        <div className='text-right bg-gradient-to-r from-yellow-500 from-10% via-yellow-600 via-30% to-yellow-10 to-90% h-[50%] rounded-t'>
                          <span className='text-black mr-1'>หวยฮานอย รอบที่ 3</span>
                        </div>
                        <div className='text-right bg-gradient-to-r from-amber-500 from-10% via-amber-600 via-30% to-amber-10 to-90% h-[50%] rounded-b'>
                          <span className='text-red-600 text-xs mr-1'>ปิดรับ   18.30</span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row w-[100%] h-[50%]" >
                      <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded-b'>
                        <div className='text-right bg-slate-700 h-[100%] p-3 rounded-b'>
                          <span className='text-slate-500 font-bold text-l'>
                            {countdown3}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dashboard/hanoi/4">
                  <div className="flex flex-col md:w-[230px] w-[90vw] h-[100px] bg-black border-[1px] border-black drop-shadow-xl rounded">
                    <div className="flex flex-row w-[100%] space-x-1 h-[50%] pl-1" >
                      <div className='h-[50%] w-[30px] bg-white rounded mt-1'>
                        <img src='https://www.meesok.com/static/media/hanoilogo.89df4e1109ca737a51f4.png'></img>
                      </div>
                      <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded'>
                        <div className='text-right bg-gradient-to-r from-yellow-500 from-10% via-yellow-600 via-30% to-yellow-10 to-90% h-[50%] rounded-t'>
                          <span className='text-black mr-1'>หวยฮานอย รอบที่ 4</span>
                        </div>
                        <div className='text-right bg-gradient-to-r from-amber-500 from-10% via-amber-600 via-30% to-amber-10 to-90% h-[50%] rounded-b'>
                          <span className='text-red-600 text-xs mr-1'>ปิดรับ   19.30</span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row w-[100%] h-[50%]" >
                      <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded-b'>
                        <div className='text-right bg-slate-700 h-[100%] p-3 rounded-b'>
                          <span className='text-slate-500 font-bold text-l'>
                            {countdown4}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/dashboard/hanoi/5">
                  <div className="flex flex-col md:w-[230px] w-[90vw] h-[100px] bg-black border-[1px] border-black drop-shadow-xl rounded">
                    <div className="flex flex-row w-[100%] space-x-1 h-[50%] pl-1" >
                      <div className='h-[50%] w-[30px] bg-white rounded mt-1'>
                        <img src='https://www.meesok.com/static/media/hanoilogo.89df4e1109ca737a51f4.png'></img>
                      </div>
                      <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded'>
                        <div className='text-right bg-gradient-to-r from-yellow-500 from-10% via-yellow-600 via-30% to-yellow-10 to-90% h-[50%] rounded-t'>
                          <span className='text-black mr-1'>หวยฮานอย รอบที่ 5</span>
                        </div>
                        <div className='text-right bg-gradient-to-r from-amber-500 from-10% via-amber-600 via-30% to-amber-10 to-90% h-[50%] rounded-b'>
                          <span className='text-red-600 text-xs mr-1'>ปิดรับ   20.30</span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row w-[100%] h-[50%]" >
                      <div className='flex flex-col w-[100%] h-[100%] bg-yellow-200 rounded-b'>
                        <div className='text-right bg-slate-700 h-[100%] p-3 rounded-b'>
                          <span className='text-slate-500 font-bold text-l'>
                            {countdown5}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </motion.div>
      </>
    )

  )
}

export default Hanoiroundselect