import mySvg from '../image/meesok.png'
import { motion, AnimatePresence } from 'framer-motion';

function TopNav({handleSubmit, handleClickOpen}) {
  return (
    <motion.div
    initial={{ opacity: 0, y:50 }}
    animate={{ opacity: 1, y: 0 }}
    className='animated-div'
  >
    <div className='flex flex-row w-[100%] px-32 md:px-40 h-[50px] bg-purple-900 border-black border-[1px] fixed top-0 left-0 z-50'>
      <img
        className='w-[auto] md:h-[150%] h-[150%] self-center'
        src={mySvg}
        alt=""
      />
    </div>
  </motion.div>
  );
}

export default TopNav;
