import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';

const MyTable = ({ columns, data , serach }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const itemsPerPage = 1; // Display one row at a time

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  const pageCount = Math.ceil(rows.length / itemsPerPage);

  const displayRows = rows.slice(0, (currentPage + 1) * itemsPerPage);

  useEffect(() => {
    if (currentPage < pageCount - 1) {
      const timer = setTimeout(() => {
        setCurrentPage(currentPage + 1);
      }, 40); // Delay in milliseconds between rendering each row

      return () => clearTimeout(timer);
    }
  }, [currentPage, pageCount]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setGlobalFilter(event.target.value);
    setCurrentPage(0);
  };

  return (
    <div className='p-2'>
      <Table {...getTableProps()} className="w-full">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="bg-gray-100">
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="py-1 px-2 text-sm"
                  align={column.align}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {displayRows.length > 0 ? (
            displayRows.map((row, index) => {
              prepareRow(row);
              const rowClassName = index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200';
              return (
                <tr
                  {...row.getRowProps()}
                  className={`transition-colors ${rowClassName} hover:bg-gray-300`}
                >
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} className="p-2" align={cell.column.align}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={columns.length} className="p-2 text-center">
                ไม่มีข้อมูลในตาราง
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default MyTable;
