import React, { useState, useEffect } from 'react'
import Label from './label'
import Lottotype from './lottotype'
import { motion } from 'framer-motion';
import Numpad from './numpad'
import './numadd.css'
// import ToggleButton from './orders'
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import SwapCallsIcon from '@mui/icons-material/SwapCalls';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { permutations } from 'itertools';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Lottotypeformodal from './lottotypemodal'
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom'

function Numadd(props) {

  const [digittype, setDigittype] = useState('three')
  const [open, setOpen] = useState(false);


  function Typeselect() {
    if (selecttype === 'numpad') {
      if (props.type === 'th_gov') {
        return (
          <div className='flex w-[100%] flex-wrap  md:gap-x-2 md:gap-y-2 gap-x-1 gap-y-1'>

            <div className='flex flex-row md:w-[32%] w-[48%]'>
              <button className={inputType.includes('run_top') ? 'flex justify-center bg-green-900 rounded-l border-dashed border-[3px] border-red-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-green-600 rounded-l border-green-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] items-center text-[10px] md:text-[16px] '}
                value="run_top"
                onClick={handleType}>วิ่งบน</button>
              <div className='flex justify-center bg-green-200 rounded-r border-green-800 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[0.5rem] md:text-[15px]'> {payrate.map(item => item = item.run_top)}฿</span>
              </div>
            </div>
            <div className='flex flex-row md:w-[32%] w-[48%]'>
              <button className={inputType.includes('run_bot') ? 'flex justify-center bg-green-800 rounded-l border-dashed border-[3px] border-red-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-green-600 rounded-l border-green-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]  '}
                value="run_bot"
                onClick={handleType}>วิ่งล่าง</button>
              <div className='flex justify-center bg-green-200 rounded-r border-green-800 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[0.5rem] md:text-[15px]'> {payrate.map(item => item = item.run_bot)}฿</span>
              </div>
            </div>

            <div className='flex flex-row md:w-[32%] w-[48%]'>
              <button className={inputType.includes('two_top') ? 'flex justify-center bg-blue-800 rounded-l border-dashed border-[3px] border-red-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-blue-600 rounded-l border-blue-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] '}
                value="two_top"
                onClick={handleType}>2 ตัวบน</button>
              <div className='flex justify-center bg-blue-200 rounded-r border-blue-600 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[0.5rem] md:text-[15px]'> {payrate.map(item => item = item.two_top)}฿</span>
              </div>
            </div>
            <div className='flex flex-row md:w-[32%] w-[48%]'>
              <button className={inputType.includes('two_bot') ? 'flex justify-center bg-blue-800 rounded-l border-dashed border-[3px] border-red-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-blue-600 rounded-l border-blue-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] '}
                value="two_bot"
                onClick={handleType}>2 ตัวล่าง</button>
              <div className='flex justify-center bg-blue-200 rounded-r border-blue-600 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[0.5rem] md:text-[15px]'> {payrate.map(item => item = item.two_bot)}฿</span>
              </div>
            </div>
            <div className='flex flex-row md:w-[32%] w-[100%]'>
              <button className={inputType.includes('twoswap') ? 'flex justify-center bg-blue-800 rounded-l border-dashed border-[3px] border-red-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-blue-600 rounded-l border-blue-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] '}
                value="twoswap"
                onClick={handleType}>2 ตัวกลับ</button>
              <div className='flex justify-center bg-blue-200 rounded-r border-blue-600 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[0.5rem] md:text-[15px]'> <SwapCallsIcon /></span>
              </div>
            </div>
            <div className='flex flex-row md:w-[32%] w-[48%]'>
              <button className={inputType.includes('three_top') ? 'flex justify-center bg-red-800 rounded-l border-dashed border-[3px] border-purple-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-red-600 rounded-l border-red-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] '}
                value="three_top"
                onClick={handleType}>3 ตัวบน</button>
              <div className='flex justify-center bg-red-200 rounded-r border-red-600 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[0.5rem] md:text-[15px]'> {payrate.map(item => item = item.three_top)}฿</span>
              </div>
            </div>
            <div className='flex flex-row md:w-[32%] w-[48%]'>
              <button className={inputType.includes('three_tod') ? 'flex justify-center bg-red-800 rounded-l border-dashed border-[3px] border-purple-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-red-600 rounded-l border-red-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] '}
                value="three_tod"
                onClick={handleType}>3 ตัวโต๊ด</button>
              <div className='flex justify-center bg-red-200 rounded-r border-red-600 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[0.5rem] md:text-[15px]'> {payrate.map(item => item = item.three_tod)}฿</span>
              </div>
            </div>
            <div className='flex flex-row md:w-[32%] w-[48%]'>
              <button className={inputType.includes('three_front') ? 'flex justify-center bg-red-800 rounded-l border-dashed border-[3px] border-purple-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-red-600 rounded-l border-red-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] '}
                value="three_front"
                onClick={handleType}>3 ตัวหน้า</button>
              <div className='flex justify-center bg-red-200 rounded-r border-red-600 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[0.5rem] md:text-[15px]'> {payrate.map(item => item = item.three_front)}฿</span>
              </div>
            </div>
            <div className='flex flex-row md:w-[32%] w-[48%]'>
              <button className={inputType.includes('three_bot') ? 'flex justify-center bg-red-800 rounded-l border-dashed border-[3px] border-purple-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-red-600 rounded-l border-red-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] '}
                value="three_bot"
                onClick={handleType}>3 ตัวล่าง</button>
              <div className='flex justify-center bg-red-200 rounded-r border-purple-600 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[0.5rem] md:text-[15px]'> {payrate.map(item => item = item.three_bot)}฿</span>
              </div>
            </div>
            <div className='flex flex-row md:w-[32%] w-[100%]'>
              <button className={inputType.includes('threeswap') ? 'flex justify-center bg-red-800 rounded-l border-dashed border-[3px] border-purple-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-red-600 rounded-l border-red-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] '}
                value="threeswap"
                onClick={handleType}>3 ตัวกลับ</button>
              <div className='flex justify-center bg-red-200 rounded-r border-red-600 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[0.5rem] md:text-[15px]'> <SwapCallsIcon /></span>
              </div>
            </div>
            <div className='flex flex-row md:w-[32%] w-[48%]'>
              <button className={inputType.includes('four_top') ? 'flex justify-center bg-yellow-800 rounded-l border-dashed border-[3px] border-red-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-yellow-600 rounded-l border-yellow-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] '}
                value="four_top"
                onClick={handleType}>4 ตัวบน</button>
              <div className='flex justify-center bg-yellow-200 rounded-r border-yellow-600 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[0.5rem] md:text-[15px]'> {payrate.map(item => item = item.four_top)}฿</span>
              </div>
            </div>
            <div className='flex flex-row md:w-[32%] w-[48%]'>
              <button className={inputType.includes('four_tod') ? 'flex justify-center bg-yellow-800 rounded-l border-dashed border-[3px] border-red-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-yellow-600 rounded-l border-yellow-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] '}
                value="four_tod"
                onClick={handleType}>4 ตัวโต๊ด</button>
              <div className='flex justify-center bg-yellow-200 rounded-r border-yellow-600 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[0.5rem] md:text-[15px]'> {payrate.map(item => item = item.four_tod)}฿</span>
              </div>
            </div>
            <div className='flex flex-row md:w-[32%] w-[100%]'>
              <button className={inputType.includes('first') ? 'flex justify-center bg-purple-800 rounded-l border-dashed border-[3px] border-red-700 text-center w-[60%] p-2' : 'flex justify-center bg-purple-600 rounded-l border-purple-800 border-[1px] text-center w-[60%] p-2 '}
                value="first"
                onClick={handleType}>รางวัลที่ 1</button>
              <div className='flex justify-center bg-purple-200 rounded-r border-purple-600 border-[1px] text-center w-[40%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[0.5rem] md:text-[15px]'> {payrate.map(item => item = item.first)}฿</span>
              </div>
            </div>
          </div>
        )
      }

      if (props.type === 'hanoy_PhuQua' || props.type === 'laos_gov') {
        return (
          <div className='flex w-[100%] flex-wrap flex-row space-y-1  space-x-1'>
            <div className='flex flex-row w-[170px]'>
              <button className={inputType.includes('run_top') ? 'flex justify-center bg-green-800 rounded-l border-dashed border-[3px] border-red-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-green-600 rounded-l border-green-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] '}
                value="run_top"
                onClick={handleType}>วิ่งบน</button>
              <div className='flex justify-center bg-green-200 rounded-r border-green-800 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[15px]'> {payrate.map(item => item = item.run_top)}฿</span>
              </div>
            </div>
            <div className='flex flex-row w-[170px]'>
              <button className={inputType.includes('run_bot') ? 'flex justify-center bg-green-800 rounded-l border-dashed border-[3px] border-red-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-green-600 rounded-l border-green-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] '}
                value="run_bot"
                onClick={handleType}>วิ่งล่าง</button>
              <div className='flex justify-center bg-green-200 rounded-r border-green-800 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[15px]'> {payrate.map(item => item = item.run_bot)}฿</span>
              </div>
            </div>
            <div className='flex flex-row w-[170px]'>
              <button className={inputType.includes('two_top') ? 'flex justify-center bg-blue-800 rounded-l border-dashed border-[3px] border-red-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-blue-600 rounded-l border-blue-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] '}
                value="two_top"
                onClick={handleType}>2 ตัวบน</button>
              <div className='flex justify-center bg-blue-200 rounded-r border-blue-600 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[15px]'> {payrate.map(item => item = item.two_top)}฿</span>
              </div>
            </div>
            <div className='flex flex-row w-[170px]'>
              <button className={inputType.includes('two_bot') ? 'flex justify-center bg-blue-800 rounded-l border-dashed border-[3px] border-red-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-blue-600 rounded-l border-blue-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] '}
                value="two_bot"
                onClick={handleType}>2 ตัวล่าง</button>
              <div className='flex justify-center bg-blue-200 rounded-r border-blue-600 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[15px]'> {payrate.map(item => item = item.two_bot)}฿</span>
              </div>
            </div>
            <div className='flex flex-row w-[170px]'>
              <button className={inputType.includes('three_top') ? 'flex justify-center bg-red-800 rounded-l border-dashed border-[3px] border-purple-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-red-600 rounded-l border-red-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] '}
                value="three_top"
                onClick={handleType}>3 ตัวบน</button>
              <div className='flex justify-center bg-red-200 rounded-r border-red-600 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[15px]'> {payrate.map(item => item = item.three_top)}฿</span>
              </div>
            </div>
            <div className='flex flex-row w-[170px]'>
              <button className={inputType.includes('three_tod') ? 'flex justify-center bg-red-800 rounded-l border-dashed border-[3px] border-purple-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-red-600 rounded-l border-red-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] '}
                value="three_tod"
                onClick={handleType}>3 ตัวโต๊ด</button>
              <div className='flex justify-center bg-red-200 rounded-r border-red-600 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[15px]'> {payrate.map(item => item = item.three_tod)}฿</span>
              </div>
            </div>
          </div>
        )
      }

      if (props.type === 'Yeekee_5' || props.type === 'Yeekee_10' || props.type === 'Yeekee_15') {
        return (
          <div className='flex w-[100%] flex-wrap flex-row space-y-1  space-x-1'>
            <div className='flex flex-row w-[170px]'>
              <button className={inputType.includes('run_top') ? 'flex justify-center bg-green-800 rounded-l border-dashed border-[3px] border-red-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-green-600 rounded-l border-green-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] '}
                value="run_top"
                onClick={handleType}>วิ่งบน</button>
              <div className='flex justify-center bg-green-200 rounded-r border-green-800 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[15px]'> {payrate.map(item => item = item.run_top)}฿</span>
              </div>
            </div>
            <div className='flex flex-row w-[170px]'>
              <button className={inputType.includes('run_bot') ? 'flex justify-center bg-green-800 rounded-l border-dashed border-[3px] border-red-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-green-600 rounded-l border-green-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] '}
                value="run_bot"
                onClick={handleType}>วิ่งล่าง</button>
              <div className='flex justify-center bg-green-200 rounded-r border-green-800 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[15px]'> {payrate.map(item => item = item.run_bot)}฿</span>
              </div>
            </div>
            <div className='flex flex-row w-[170px]'>
              <button className={inputType.includes('two_top') ? 'flex justify-center bg-blue-800 rounded-l border-dashed border-[3px] border-red-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-blue-600 rounded-l border-blue-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] '}
                value="two_top"
                onClick={handleType}>2 ตัวบน</button>
              <div className='flex justify-center bg-blue-200 rounded-r border-blue-600 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[15px]'> {payrate.map(item => item = item.two_top)}฿</span>
              </div>
            </div>
            <div className='flex flex-row w-[170px]'>
              <button className={inputType.includes('two_bot') ? 'flex justify-center bg-blue-800 rounded-l border-dashed border-[3px] border-red-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-blue-600 rounded-l border-blue-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] '}
                value="two_bot"
                onClick={handleType}>2 ตัวล่าง</button>
              <div className='flex justify-center bg-blue-200 rounded-r border-blue-600 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[15px]'> {payrate.map(item => item = item.two_bot)}฿</span>
              </div>
            </div>
            <div className='flex flex-row w-[170px]'>
              <button className={inputType.includes('three_top') ? 'flex justify-center bg-red-800 rounded-l border-dashed border-[3px] border-purple-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-red-600 rounded-l border-red-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] '}
                value="three_top"
                onClick={handleType}>3 ตัวบน</button>
              <div className='flex justify-center bg-red-200 rounded-r border-red-600 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[15px]'> {payrate.map(item => item = item.three_top)}฿</span>
              </div>
            </div>
            <div className='flex flex-row w-[170px]'>
              <button className={inputType.includes('three_tod') ? 'flex justify-center bg-red-800 rounded-l border-dashed border-[3px] border-purple-700 text-center w-[70%] p-2 items-center text-[10px] md:text-[16px]' : 'flex justify-center bg-red-600 rounded-l border-red-800 border-[1px] text-center w-[70%] p-2 items-center text-[10px] md:text-[16px] '}
                value="three_tod"
                onClick={handleType}>3 ตัวโต๊ด</button>
              <div className='flex justify-center bg-red-200 rounded-r border-red-600 border-[1px] text-center w-[30%] p-3 px-3'>
                <span className='text-slate-600 font-thin text-[15px]'> {payrate.map(item => item = item.three_tod)}฿</span>
              </div>
            </div>
          </div>
        )
      }
    }

    if (selecttype === 'numlist') {
      return (
        <div className='flex flex-col gap-x-2 gap-y-2 w-[100%]'>
          {/* digit type */}
          <div className='flex flex-row justify-center gap-x-2 items-center w-[100%]'>
            <div className='flex flex-row w-1/3'>
              <button className='flex justify-center bg-red-600 rounded border-red-800 border-[1px] text-center w-[100%] p-2 '
                value="three"
                onClick={(e) => {
                  setDigittype(e.target.value);
                  setInputType([]);
                }}>สามตัว</button>
            </div>
            <div className='flex flex-row w-1/3'>
              <button className='flex justify-center bg-blue-600 rounded border-blue-800 border-[1px] text-center w-[100%] p-2 '
                value="two"
                onClick={(e) => {
                  setDigittype(e.target.value);
                  setInputType([]);
                }}>สองตัว</button>
            </div>
            <div className='flex flex-row w-1/3'>
              <button className='flex justify-center bg-green-600 rounded border-green-800 border-[1px] text-center w-[100%] p-2 '
                value="run"
                onClick={(e) => {
                  setDigittype(e.target.value);
                  setInputType([]);
                }}>เลขวิ่ง</button>
            </div>
          </div>

          {/* three digit */}
          {digittype === 'three' &&
            <div className='flex flex-row justify-center items-center gap-x-2 w-[100%]'>
              <div className='flex flex-row w-1/4'>
                <button className='flex justify-center bg-red-600 rounded border-red-800 border-[1px] text-center w-[100%] p-2 '
                  value="three_top"
                  onClick={handleType}>สามตัวบน</button>
              </div>
              <div className='flex flex-row w-1/4'>
                <button className='flex justify-center bg-red-600 rounded border-red-800 border-[1px] text-center w-[100%] p-2 '
                  value="three_tod"
                  onClick={handleType}>สามตัวโต๊ด</button>
              </div>
              <div className='flex flex-row w-1/4'>
                <button className='flex justify-center bg-red-600 rounded border-red-800 border-[1px] text-center w-[100%] p-2 '
                  value="three_front"
                  onClick={handleType}>สามตัวหน้า</button>
              </div>
              <div className='flex flex-row w-1/4'>
                <button className='flex justify-center bg-red-600 rounded border-red-800 border-[1px] text-center w-[100%] p-2 '
                  value="three_bot"
                  onClick={handleType}>สามตัวล่าง</button>
              </div>
            </div>
          }

          {/* two digit */}
          {digittype === 'two' &&
            <div className='flex flex-row justify-center items-center gap-x-2 w-[100%]'>
              <div className='flex flex-row w-1/2'>
                <button className='flex justify-center bg-blue-600 rounded border-blue-800 border-[1px] text-center w-[100%] p-2 '
                  value="two_top"
                  onClick={handleType}>สองตัวบน</button>
              </div>
              <div className='flex flex-row w-1/2'>
                <button className='flex justify-center bg-blue-600 rounded border-blue-800 border-[1px] text-center w-[100%] p-2 '
                  value="two_bot"
                  onClick={handleType}>สองตัวล่าง</button>
              </div>
            </div>
          }

          {/* 1 digit */}
          {digittype === 'run' &&
            <div className='flex flex-row justify-center items-center gap-x-2 w-[100%]'>
              <div className='flex flex-row md:w-[700px] w-[100%]'>
                <button className='flex justify-center bg-green-600 rounded border-green-800 border-[1px] text-center w-[100%] p-2 '
                  value="run_top"
                  onClick={handleType}>วิ่งบน</button>
              </div>
              <div className='flex flex-row w-[100%]'>
                <button className='flex justify-center bg-green-600 rounded border-green-800 border-[1px] text-center w-[100%] p-2 '
                  value="run_bot"
                  onClick={handleType}>วิ่งล่าง</button>
              </div>
            </div>
          }
        </div>
      )
    }

  }
  ////////////////////////////////////////////////////////////////
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  /////////////////////////////////////////////////////////////////////////
  function Addontype() {
    if (selecttype === 'numpad') {
      return (
        <div>
          <button className='three' value="rollfront" onClick={handleType}>รูดหลัง</button>
          <button className='three' value="rollback" onClick={handleType}>รูดหน้า</button>
          <button className='three' value="twolow" onClick={(e) => handleAddonbutton(e)}>สองตัวต่ำ</button>
          <button className='three' value="twohigh" onClick={(e) => handleAddonbutton(e)}>สองตัวสูง</button>
          <button className='three' value="twoodd" onClick={(e) => handleAddonbutton(e)}>สองตัวคี่</button>
          <button className='three' value="twoeven" onClick={(e) => handleAddonbutton(e)}>สองตัวคู่</button>
        </div>
      )
    }
  }


  const [inputType, setInputType] = useState([]);
  const [allbetamt, setAllbetamt] = useState(0);
  const [history, setHistory] = useState(1);

  const [number1, setNumber1] = useState('');
  const [number2, setNumber2] = useState('');
  const [number3, setNumber3] = useState('');
  const [number4, setNumber4] = useState('');
  const [number5, setNumber5] = useState('');
  const [number6, setNumber6] = useState('');

  const combinedNumber = number1 + number2;
  const combinedNumber3 = number1 + number2 + number3;
  const combinedNumber4 = number1 + number2 + number3 + number4;
  const combinedNumber6 = number1 + number2 + number3 + number4 + number5 + number6;

  const [array, setArray] = useState([]);
  const [numpadvisible, setNumpadvisible] = useState(true);
  const [garr, setgrr] = useState([]);

  //tod function
  function tod(nums) {
    const num1 = nums.substring(0, 1);
    const num2 = nums.substring(1, 2);
    const num3 = nums.substring(2, 3);
    const num4 = nums.substring(3, 4);
    if (nums.length === 3) {
      const permutationsArr = Array.from(permutations([num1, num2, num3]));
      const permutationsString = permutationsArr.map((array) => array.join(''));
      const uniqueArray = [...new Set(permutationsString)];
      return uniqueArray;
    } else if (nums.length === 4) {
      const permutationsArr = Array.from(permutations([num1, num2, num3, num4]));
      const permutationsString = permutationsArr.map((array) => array.join(''));
      const uniqueArray = [...new Set(permutationsString)];
      return uniqueArray;
    } else {
      return 'error';
    }
  }

  function generateTwofront(input) {
    const result = [];
    for (let i = 0; i <= 9; i++) {
      const twoDigitNumber = i.toString().padStart(1, '0') + input.toString();
      result.push(twoDigitNumber);
    }
    return result;
  }

  function generateTwoback(input) {
    const result = [];
    for (let i = 0; i <= 9; i++) {
      const twoDigitNumber = input.toString() + i.toString().padStart(1, '0');
      result.push(twoDigitNumber);
    }
    return result;
  }

  function generateTwolow() {
    const result = [];
    for (let i = 0; i <= 49; i++) {
      const twoDigitNumber = i.toString().padStart(2, '0');
      result.push(twoDigitNumber);
    }
    return result;
  }
  function generateTwohigh() {
    const result = [];
    for (let i = 50; i <= 99; i++) {
      const twoDigitNumber = i.toString().padStart(2, '0');
      result.push(twoDigitNumber);
    }
    return result;
  }

  function generateTwoodd() {
    const result = [];
    for (let i = 0; i <= 99; i++) {
      if (i % 2 === 1) {
        const twoDigitNumber = i.toString().padStart(2, '0');
        result.push(twoDigitNumber);
      }
    }
    return result;
  }

  function generateTwoeven() {
    const result = [];
    for (let i = 0; i <= 99; i++) {
      if (i % 2 === 0) {
        const twoDigitNumber = i.toString().padStart(2, '0');
        result.push(twoDigitNumber);
      }

    }
    return result;
  }

  const handleAddonbutton = (event) => {
    const twotop = inputType.find(item => item === 'two_top');
    const twobot = inputType.find(item => item === 'two_bot');
    const twotoppayrate = payrate.map((item) => (item.two_top))
    const twobotpayrate = payrate.map((item) => (item.two_bot))

    if (event.target.value === 'twolow') {

      if (twotop && !twobot) {
        let arr = []
        const twolow = generateTwolow()
        const twolownum = []
        twolow.forEach(num => {
          twolownum.push({
            "id": uuidv4(),
            "history": history,
            "number": num,
            "bet_amout": 1,
            "bet_payrate": twotoppayrate,
            "bet_type": twotop
          })
        })
        arr = [...array, ...twolownum]
        setArray(arr)
        setHistory(history + 1)
      }

      if (!twotop && twobot) {
        let arr = []
        const twolow = generateTwolow()
        const twolownum = []
        twolow.forEach(num => {
          twolownum.push({
            "id": uuidv4(),
            "history": history,
            "number": num,
            "bet_amout": 1,
            "bet_payrate": twobotpayrate,
            "bet_type": twobot
          })
        })
        arr = [...array, ...twolownum]
        setArray(arr)
        setHistory(history + 1)
      }

      if (twotop && twobot) {
        let arr = []
        const twolow = generateTwolow()
        const twolownum = []
        twolow.forEach(num => {
          twolownum.push({
            "id": uuidv4(),
            "history": history,
            "number": num,
            "bet_amout": 1,
            "bet_payrate": twobotpayrate,
            "bet_type": twobot
          },
            {
              "id": uuidv4(),
              "history": history,
              "number": num,
              "bet_amout": 1,
              "bet_payrate": twotoppayrate,
              "bet_type": twotop
            })
        })
        arr = [...array, ...twolownum]
        setArray(arr)
        setHistory(history + 1)
      }

    }
    if (event.target.value === 'twohigh') {

      if (twotop && !twobot) {
        let arr = []
        const twolow = generateTwohigh()
        const twolownum = []
        twolow.forEach(num => {
          twolownum.push({
            "id": uuidv4(),
            "history": history,
            "number": num,
            "bet_amout": 1,
            "bet_payrate": twotoppayrate,
            "bet_type": twotop
          })
        })
        arr = [...array, ...twolownum]
        setArray(arr)
        setHistory(history + 1)
      }

      if (!twotop && twobot) {
        let arr = []
        const twolow = generateTwohigh()
        const twolownum = []
        twolow.forEach(num => {
          twolownum.push({
            "id": uuidv4(),
            "history": history,
            "number": num,
            "bet_amout": 1,
            "bet_payrate": twobotpayrate,
            "bet_type": twobot
          })
        })
        arr = [...array, ...twolownum]
        setArray(arr)
        setHistory(history + 1)
      }

      if (twotop && twobot) {
        let arr = []
        const twolow = generateTwohigh()
        const twolownum = []
        twolow.forEach(num => {
          twolownum.push({
            "id": uuidv4(),
            "history": history,
            "number": num,
            "bet_amout": 1,
            "bet_payrate": twobotpayrate,
            "bet_type": twobot
          },
            {
              "id": uuidv4(),
              "history": history,
              "number": num,
              "bet_amout": 1,
              "bet_payrate": twotoppayrate,
              "bet_type": twotop
            })
        })
        arr = [...array, ...twolownum]
        setArray(arr)
        setHistory(history + 1)
      }

    }
    if (event.target.value === 'twoodd') {

      if (twotop && !twobot) {
        let arr = []
        const twolow = generateTwoodd()
        const twolownum = []
        twolow.forEach(num => {
          twolownum.push({
            "id": uuidv4(),
            "history": history,
            "number": num,
            "bet_amout": 1,
            "bet_payrate": twotoppayrate,
            "bet_type": twotop
          })
        })
        arr = [...array, ...twolownum]
        setArray(arr)
        setHistory(history + 1)
      }

      if (!twotop && twobot) {
        let arr = []
        const twolow = generateTwoodd()
        const twolownum = []
        twolow.forEach(num => {
          twolownum.push({
            "id": uuidv4(),
            "history": history,
            "number": num,
            "bet_amout": 1,
            "bet_payrate": twobotpayrate,
            "bet_type": twobot
          })
        })
        arr = [...array, ...twolownum]
        setArray(arr)
        setHistory(history + 1)
      }

      if (twotop && twobot) {
        let arr = []
        const twolow = generateTwoodd()
        const twolownum = []
        twolow.forEach(num => {
          twolownum.push({
            "id": uuidv4(),
            "history": history,
            "number": num,
            "bet_amout": 1,
            "bet_payrate": twobotpayrate,
            "bet_type": twobot
          },
            {
              "id": uuidv4(),
              "history": history,
              "number": num,
              "bet_amout": 1,
              "bet_payrate": twotoppayrate,
              "bet_type": twotop
            })
        })
        arr = [...array, ...twolownum]
        setArray(arr)
        setHistory(history + 1)
      }

    }
    if (event.target.value === 'twoeven') {

      if (twotop && !twobot) {
        let arr = []
        const twolow = generateTwoeven()
        const twolownum = []
        twolow.forEach(num => {
          twolownum.push({
            "id": uuidv4(),
            "history": history,
            "number": num,
            "bet_amout": 1,
            "bet_payrate": twotoppayrate,
            "bet_type": twotop
          })
        })
        arr = [...array, ...twolownum]
        setArray(arr)
        setHistory(history + 1)
      }

      if (!twotop && twobot) {
        let arr = []
        const twolow = generateTwoeven()
        const twolownum = []
        twolow.forEach(num => {
          twolownum.push({
            "id": uuidv4(),
            "history": history,
            "number": num,
            "bet_amout": 1,
            "bet_payrate": twobotpayrate,
            "bet_type": twobot
          })
        })
        arr = [...array, ...twolownum]
        setArray(arr)
        setHistory(history + 1)
      }

      if (twotop && twobot) {
        let arr = []
        const twolow = generateTwoeven()
        const twolownum = []
        twolow.forEach(num => {
          twolownum.push({
            "id": uuidv4(),
            "history": history,
            "number": num,
            "bet_amout": 1,
            "bet_payrate": twobotpayrate,
            "bet_type": twobot
          },
            {
              "id": uuidv4(),
              "history": history,
              "number": num,
              "bet_amout": 1,
              "bet_payrate": twotoppayrate,
              "bet_type": twotop
            })
        })
        arr = [...array, ...twolownum]
        setArray(arr)
        setHistory(history + 1)
      }
    }

    setModal(event.target.value)

    // alert(`You clicked button ${buttonLabel}`);
    setShowModal(true);

    // hide the modal after 1 second
    setTimeout(() => {
      setShowModal(false);
    }, 300);

  };


  // reset number input
  useEffect(() => {
    const interval = setInterval(() => {

      //1 digit
      if (inputType.find(item => item === 'run_top') || inputType.find(item => item === 'run_bot')) {
        if (number1 !== '') {
          const runtop = inputType.find(item => item === 'run_top');
          const runbot = inputType.find(item => item === 'run_bot');

          if (runtop && !runbot) {
            const arr = [...array, {
              "id": uuidv4(),
              "history": history,
              "number": number1,
              "bet_amout": 1,
              "bet_payrate": payrate[0].run_top,
              "bet_type": runtop
            }]
            setArray(arr)
            setHistory(history + 1)
          }
          if (runbot && !runtop) {
            const arr = [...array, {
              "id": uuidv4(),
              "history": history,
              "number": number1,
              "bet_amout": 1,
              "bet_payrate": payrate[0].run_bot,
              "bet_type": runbot
            }]
            setArray(arr)
            setHistory(history + 1)
          }
          if (runbot && runtop) {
            const arr = [...array, {
              "id": uuidv4(),
              "history": history,
              "number": number1,
              "bet_amout": 1,
              "bet_payrate": payrate[0].run_top,
              "bet_type": runtop
            }, {
              "id": uuidv4(),
              "history": history,
              "number": number1,
              "bet_amout": 1,
              "bet_payrate": payrate[0].run_bot,
              "bet_type": runbot
            }]
            setArray(arr)
            setHistory(history + 1)
          }


          setNumber1('');
          setNumber2('');
          // console.log(combinedNumber, arr)
          clearInterval(interval);
        }
      }

      //two digit
      if (inputType.find(item => item === 'two_top') || inputType.find(item => item === 'two_bot')) {
        if (number1 !== '') {
          const twotop = inputType.find(item => item === 'two_top');
          const twobot = inputType.find(item => item === 'two_bot');
          const twoswap = inputType.find(item => item === 'twoswap');
          const rollfront = inputType.find(item => item === 'rollfront');
          const rollback = inputType.find(item => item === 'rollback');

          const swapnum = combinedNumber[1] + combinedNumber[0]

          if (twotop && !twobot) {
            let arr = []

            ////////////////////////////// two swap condition

            if (twoswap && !rollfront && !rollback) {
              arr = [...array, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber,
                "bet_amout": 1,
                "bet_payrate": payrate[0].two_top,
                "bet_type": twotop
              }, {
                "id": uuidv4(),
                "history": history,
                "number": swapnum,
                "bet_amout": 1,
                "bet_payrate": payrate[0].two_top,
                "bet_type": twotop
              }]
            }

            ////////////////////////////// only rollfront condition

            else if (rollfront && !rollback && !twoswap) {
              const frontnum = generateTwofront(number1)
              const allfront = []
              frontnum.forEach(num => {
                allfront.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": num,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].two_top,
                    "bet_type": twotop
                  }
                )
              })
              arr = [...array, ...allfront]
            }

            ////////////////////////////// only rollback condition

            else if (!rollfront && rollback && !twoswap) {
              const frontnum = generateTwoback(number1)
              const allfront = []
              frontnum.forEach(num => {
                allfront.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": num,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].two_top,
                    "bet_type": twotop
                  }
                )
              })
              arr = [...array, ...allfront]
            }
            ////////////////////////////// only rollback condition

            else if (!rollfront && rollback && !twoswap) {
              const frontnum = generateTwoback(number1)
              const allfront = []
              frontnum.forEach(num => {
                allfront.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": num,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].two_top,
                    "bet_type": twotop
                  }
                )
              })
              arr = [...array, ...allfront]
            }

            ////////////////////////////// rollfront rollback condition

            else if (rollfront && rollback && !twoswap) {
              const frontnum = generateTwofront(number1)
              const backnum = generateTwoback(number1)
              const allfront = []

              frontnum.forEach(num => {
                allfront.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": num,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].two_top,
                    "bet_type": twotop
                  }
                )
              })

              backnum.forEach(num => {
                allfront.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": num,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].two_top,
                    "bet_type": twotop
                  }
                )
              })

              arr = [...array, ...allfront]
            }

            ////////////////////////////// normal condition

            else {
              arr = [...array, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber,
                "bet_amout": 1,
                "bet_payrate": payrate[0].two_top,
                "bet_type": twotop
              }]
            }


            setArray(arr)
            setHistory(history + 1)
          }

          if (twobot && !twotop) {
            let arr = []

            ////////////////////////////// two swap condition

            if (twoswap && !rollfront && !rollback) {
              arr = [...array, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber,
                "bet_amout": 1,
                "bet_payrate": payrate[0].two_bot,
                "bet_type": twobot
              }, {
                "id": uuidv4(),
                "history": history,
                "number": swapnum,
                "bet_amout": 1,
                "bet_payrate": payrate[0].two_bot,
                "bet_type": twobot
              }]
            }

            ////////////////////////////// only rollfront condition

            else if (rollfront && !rollback && !twoswap) {
              const frontnum = generateTwofront(number1)
              const allfront = []
              frontnum.forEach(num => {
                allfront.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": num,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].two_bot,
                    "bet_type": twobot
                  }
                )
              })
              arr = [...array, ...allfront]
            }

            ////////////////////////////// only rollback condition

            else if (!rollfront && rollback && !twoswap) {
              const frontnum = generateTwoback(number1)
              const allfront = []
              frontnum.forEach(num => {
                allfront.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": num,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].two_bot,
                    "bet_type": twobot
                  }
                )
              })
              arr = [...array, ...allfront]
            }
            ////////////////////////////// only rollback condition

            else if (!rollfront && rollback && !twoswap) {
              const frontnum = generateTwoback(number1)
              const allfront = []
              frontnum.forEach(num => {
                allfront.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": num,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].two_bot,
                    "bet_type": twobot
                  }
                )
              })
              arr = [...array, ...allfront]
            }

            ////////////////////////////// rollfront rollback condition

            else if (rollfront && rollback && !twoswap) {
              const frontnum = generateTwofront(number1)
              const backnum = generateTwoback(number1)
              const allfront = []

              frontnum.forEach(num => {
                allfront.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": num,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].two_bot,
                    "bet_type": twobot
                  }
                )
              })

              backnum.forEach(num => {
                allfront.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": num,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].two_bot,
                    "bet_type": twobot
                  }
                )
              })

              arr = [...array, ...allfront]
            }

            else {
              arr = [...array, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber,
                "bet_amout": 1,
                "bet_payrate": payrate[0].two_bot,
                "bet_type": twobot
              }]
            }
            setArray(arr)
            setHistory(history + 1)
          }

          if (twobot && twotop) {
            let arr = []

            ////////////////////////////// two swap condition

            if (twoswap && !rollfront && !rollback) {
              arr = [...array, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber,
                "bet_amout": 1,
                "bet_payrate": payrate[0].two_bot,
                "bet_type": twobot
              }, {
                "id": uuidv4(),
                "history": history,
                "number": swapnum,
                "bet_amout": 1,
                "bet_payrate": payrate[0].two_bot,
                "bet_type": twobot
              }, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber,
                "bet_amout": 1,
                "bet_payrate": payrate[0].two_top,
                "bet_type": twotop
              }, {
                "id": uuidv4(),
                "history": history,
                "number": swapnum,
                "bet_amout": 1,
                "bet_payrate": payrate[0].two_top,
                "bet_type": twotop
              }]
            }

            ////////////////////////////// only rollfront condition

            else if (rollfront && !rollback && !twoswap) {
              const frontnum = generateTwofront(number1)
              const allfront = []
              frontnum.forEach(num => {
                allfront.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": num,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].two_top,
                    "bet_type": twotop
                  },
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": num,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].two_bot,
                    "bet_type": twobot
                  }
                )
              })
              arr = [...array, ...allfront]
            }

            ////////////////////////////// only rollback condition

            else if (!rollfront && rollback && !twoswap) {
              const frontnum = generateTwoback(number1)
              const allfront = []
              frontnum.forEach(num => {
                allfront.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": num,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].two_top,
                    "bet_type": twotop
                  },
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": num,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].two_bot,
                    "bet_type": twobot
                  }
                )
              })
              arr = [...array, ...allfront]
            }
            ////////////////////////////// only rollback condition

            else if (!rollfront && rollback && !twoswap) {
              const frontnum = generateTwoback(number1)
              const allfront = []
              frontnum.forEach(num => {
                allfront.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": num,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].two_top,
                    "bet_type": twotop
                  },
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": num,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].two_bot,
                    "bet_type": twobot
                  }
                )
              })
              arr = [...array, ...allfront]
            }

            ////////////////////////////// rollfront rollback condition

            else if (rollfront && rollback && !twoswap) {
              const frontnum = generateTwofront(number1)
              const backnum = generateTwoback(number1)
              const allfront = []

              frontnum.forEach(num => {
                allfront.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": num,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].two_top,
                    "bet_type": twotop
                  },
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": num,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].two_bot,
                    "bet_type": twobot
                  }
                )
              })

              backnum.forEach(num => {
                allfront.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": num,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].two_top,
                    "bet_type": twotop
                  },
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": num,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].two_bot,
                    "bet_type": twobot
                  }
                )
              })

              arr = [...array, ...allfront]
            }

            else {
              arr = [...array, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber,
                "bet_amout": 1,
                "bet_payrate": payrate[0].two_top,
                "bet_type": twotop
              }, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber,
                "bet_amout": 1,
                "bet_payrate": payrate[0].two_bot,
                "bet_type": twobot
              }]
            }

            setArray(arr)
            setHistory(history + 1)
          }

          setNumber1('');
          setNumber2('');
          clearInterval(interval);
        }
      }

      // three digit
      if (inputType.find(item => item === 'three_top') || inputType.find(item => item === 'three_bot') || inputType.find(item => item === 'three_tod') || inputType.find(item => item === 'three_front')) {
        if (number1 !== '') {
          const threetop = inputType.find(item => item === 'three_top');
          const threebot = inputType.find(item => item === 'three_bot');
          const threetod = inputType.find(item => item === 'three_tod');
          const threefront = inputType.find(item => item === 'three_front');
          const threeswap = inputType.find(item => item === 'threeswap');

          //only three top select
          if (threetop && !threebot && !threetod && !threefront) {
            let arr = []
            if (threeswap) {
              const swapnum = tod(combinedNumber3)
              const threetodsql = []
              swapnum.forEach(element => {
                threetodsql.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": element,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_top,
                    "bet_type": threetop
                  }
                )
              });
              arr = [...array, ...threetodsql]
            } else {
              arr = [...array, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_top,
                "bet_type": threetop
              }]
            }
            setArray(arr)
            setHistory(history + 1)
          }
          //only three bot select
          if (!threetop && threebot && !threetod && !threefront) {
            let arr = []
            if (threeswap) {
              const swapnum = tod(combinedNumber3)
              const threetodsql = []
              swapnum.forEach(element => {
                threetodsql.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": element,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_bot,
                    "bet_type": threebot
                  }
                )
              });
              arr = [...array, ...threetodsql]
            } else {
              arr = [...array, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_bot,
                "bet_type": threebot
              }]
            }
            setArray(arr)
            setHistory(history + 1)
          }
          //only three tod select
          if (!threetop && !threebot && threetod && !threefront) {
            let arr = []
            arr = [...array, {
              "id": uuidv4(),
              "history": history,
              "number": combinedNumber3,
              "bet_amout": 1,
              "bet_payrate": payrate[0].three_tod,
              "bet_type": threetod
            }]
            setArray(arr)
            setHistory(history + 1)
          }
          //only three front select
          if (!threetop && !threebot && !threetod && threefront) {
            let arr = []
            if (threeswap) {
              const swapnum = tod(combinedNumber3)
              const threetodsql = []
              swapnum.forEach(element => {
                threetodsql.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": element,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_front,
                    "bet_type": threefront
                  }
                )
              });
              arr = [...array, ...threetodsql]
            } else {
              arr = [...array, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_front,
                "bet_type": threefront
              }]
            }
            setArray(arr)
            setHistory(history + 1)
          }

          ////////////////////////////////////////////////////////////////////////////////////////////////////

          //select two type

          //three top and three bot select
          if (threetop && threebot && !threetod && !threefront) {
            let arr = []
            if (threeswap) {
              const swapnum = tod(combinedNumber3)
              const threetodsql = []
              swapnum.forEach(element => {
                threetodsql.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": element,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_top,
                    "bet_type": threetop
                  },
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": element,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_bot,
                    "bet_type": threebot
                  }
                )
              });
              arr = [...array, ...threetodsql]
            } else {
              arr = [...array, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_top,
                "bet_type": threetop
              },
              {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_bot,
                "bet_type": threebot
              }]
            }
            setArray(arr)
            setHistory(history + 1)
          }
          //three top and three tod select
          if (threetop && !threebot && threetod && !threefront) {
            let arr = []
            if (threeswap) {
              const swapnum = tod(combinedNumber3)
              const threetodsql = []
              swapnum.forEach(element => {
                threetodsql.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": element,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_top,
                    "bet_type": threetop
                  }
                )
              });
              arr = [...array, ...threetodsql,
              {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_tod,
                "bet_type": threetod
              }]
            } else {
              arr = [...array, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_top,
                "bet_type": threetop
              },
              {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_tod,
                "bet_type": threetod
              }]
            }
            setArray(arr)
            setHistory(history + 1)
          }
          //three top and three front select
          if (threetop && !threebot && !threetod && threefront) {
            let arr = []
            if (threeswap) {
              const swapnum = tod(combinedNumber3)
              const threetodsql = []
              swapnum.forEach(element => {
                threetodsql.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": element,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_top,
                    "bet_type": threetop
                  },
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": element,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_front,
                    "bet_type": threefront
                  }
                )
              });
              arr = [...array, ...threetodsql]
            } else {
              arr = [...array, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_top,
                "bet_type": threetop
              },
              {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_front,
                "bet_type": threefront
              }]
            }
            setArray(arr)
            setHistory(history + 1)
          }
          //three bot and three tod select
          if (!threetop && threebot && threetod && !threefront) {
            let arr = []
            if (threeswap) {
              const swapnum = tod(combinedNumber3)
              const threetodsql = []
              swapnum.forEach(element => {
                threetodsql.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": element,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_bot,
                    "bet_type": threebot
                  }
                )
              });
              arr = [...array, ...threetodsql,
              {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_tod,
                "bet_type": threetod
              }]
            } else {
              arr = [...array, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_bot,
                "bet_type": threebot
              },
              {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_tod,
                "bet_type": threetod
              }]
            }
            setArray(arr)
            setHistory(history + 1)
          }
          //three bot and three front select
          if (!threetop && threebot && !threetod && threefront) {
            let arr = []
            if (threeswap) {
              const swapnum = tod(combinedNumber3)
              const threetodsql = []
              swapnum.forEach(element => {
                threetodsql.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": element,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_bot,
                    "bet_type": threebot
                  },
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": element,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_front,
                    "bet_type": threefront
                  }
                )
              });
              arr = [...array, ...threetodsql]
            } else {
              arr = [...array, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_bot,
                "bet_type": threebot
              },
              {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_front,
                "bet_type": threefront
              }]
            }
            setArray(arr)
            setHistory(history + 1)
          }
          //three front and three tod select
          if (!threetop && !threebot && threetod && threefront) {
            let arr = []
            if (threeswap) {
              const swapnum = tod(combinedNumber3)
              const threetodsql = []
              swapnum.forEach(element => {
                threetodsql.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": element,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_front,
                    "bet_type": threefront
                  }
                )
              });
              arr = [...array, ...threetodsql,
              {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_tod,
                "bet_type": threetod
              }]
            } else {
              arr = [...array, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_front,
                "bet_type": threefront
              },
              {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_tod,
                "bet_type": threetod
              }]
            }
            setArray(arr)
            setHistory(history + 1)
          }

          ////////////////////////////////////////////////////////////////////////////////////////////////

          //select three type

          //three top & three bot & three tod select
          if (threetop && threebot && threetod && !threefront) {
            let arr = []
            if (threeswap) {
              const swapnum = tod(combinedNumber3)
              const threetodsql = []
              swapnum.forEach(element => {
                threetodsql.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": element,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_top,
                    "bet_type": threetop
                  },
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": element,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_bot,
                    "bet_type": threebot
                  }
                )
              });
              arr = [...array, ...threetodsql, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_tod,
                "bet_type": threetod
              }]
            } else {
              arr = [...array, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_top,
                "bet_type": threetop
              },
              {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_bot,
                "bet_type": threebot
              },
              {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_tod,
                "bet_type": threetod
              }]
            }
            setArray(arr)
            setHistory(history + 1)
          }
          //three top & three bot & three front select
          if (threetop && threebot && !threetod && threefront) {
            let arr = []
            if (threeswap) {
              const swapnum = tod(combinedNumber3)
              const threetodsql = []
              swapnum.forEach(element => {
                threetodsql.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": element,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_top,
                    "bet_type": threetop
                  },
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": element,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_bot,
                    "bet_type": threebot
                  },
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": combinedNumber3,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_front,
                    "bet_type": threefront
                  }
                )
              });
              arr = [...array, ...threetodsql]
            } else {
              arr = [...array, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_top,
                "bet_type": threetop
              },
              {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_bot,
                "bet_type": threebot
              },
              {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_front,
                "bet_type": threefront
              }]
            }
            setArray(arr)
            setHistory(history + 1)
          }
          //three top & three tod & three front select
          if (threetop && !threebot && threetod && threefront) {
            let arr = []
            if (threeswap) {
              const swapnum = tod(combinedNumber3)
              const threetodsql = []
              swapnum.forEach(element => {
                threetodsql.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": element,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_top,
                    "bet_type": threetop
                  },
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": element,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_front,
                    "bet_type": threefront
                  }
                )
              });
              arr = [...array, ...threetodsql,
              {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_tod,
                "bet_type": threetod
              }]
            } else {
              arr = [...array, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_top,
                "bet_type": threetop
              },
              {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_front,
                "bet_type": threefront
              },
              {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_tod,
                "bet_type": threetod
              }]
            }
            setArray(arr)
            setHistory(history + 1)
          }
          //three bot & three tod & three front select
          if (!threetop && threebot && threetod && threefront) {
            let arr = []
            if (threeswap) {
              const swapnum = tod(combinedNumber3)
              const threetodsql = []
              swapnum.forEach(element => {
                threetodsql.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": element,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_bot,
                    "bet_type": threebot
                  },
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": element,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_front,
                    "bet_type": threefront
                  }
                )
              });
              arr = [...array, ...threetodsql,
              {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_tod,
                "bet_type": threetod
              }]
            } else {
              arr = [...array, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_bot,
                "bet_type": threebot
              },
              {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_front,
                "bet_type": threefront
              },
              {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_tod,
                "bet_type": threetod
              }]
            }
            setArray(arr)
            setHistory(history + 1)
          }

          ////////////////////////////////////////////////////////////////////////////////////////////////

          //select all type

          //three top & three bot & three tod & three front select
          if (threetop && threebot && threetod && threefront) {
            let arr = []
            if (threeswap) {
              const swapnum = tod(combinedNumber3)
              const threetodsql = []
              swapnum.forEach(element => {
                threetodsql.push(
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": element,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_top,
                    "bet_type": threetop
                  },
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": element,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_bot,
                    "bet_type": threebot
                  },
                  {
                    "id": uuidv4(),
                    "history": history,
                    "number": element,
                    "bet_amout": 1,
                    "bet_payrate": payrate[0].three_front,
                    "bet_type": threefront
                  }
                )
              });
              arr = [...array, ...threetodsql, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_tod,
                "bet_type": threetod
              }]
            } else {
              arr = [...array, {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_top,
                "bet_type": threetop
              },
              {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_bot,
                "bet_type": threebot
              },
              {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_front,
                "bet_type": threefront
              },
              {
                "id": uuidv4(),
                "history": history,
                "number": combinedNumber3,
                "bet_amout": 1,
                "bet_payrate": payrate[0].three_tod,
                "bet_type": threetod
              }]
            }
            setArray(arr)
            setHistory(history + 1)
          }

          setNumber1('');
          setNumber2('');
          setNumber3('');
          clearInterval(interval);
        }
      }

      //four digit
      if (inputType.find(item => item === 'four_top') || inputType.find(item => item === 'four_tod')) {
        if (number1 !== '') {
          const fourtop = inputType.find(item => item === 'four_top');
          const fourtod = inputType.find(item => item === 'four_tod');

          if (fourtop && !fourtod) {
            let arr = []
            arr = [...array, {
              "id": uuidv4(),
              "history": history,
              "number": combinedNumber4,
              "bet_amout": 1,
              "bet_payrate": payrate[0].four_top,
              "bet_type": fourtop
            }]
            setArray(arr)
            setHistory(history + 1)
          }
          if (!fourtop && fourtod) {
            let arr = []
            arr = [...array, {
              "id": uuidv4(),
              "history": history,
              "number": combinedNumber4,
              "bet_amout": 1,
              "bet_payrate": payrate[0].four_tod,
              "bet_type": fourtod
            }]
            setArray(arr)
            setHistory(history + 1)
          }
          if (fourtop && fourtod) {
            let arr = []
            arr = [...array, {
              "id": uuidv4(),
              "history": history,
              "number": combinedNumber4,
              "bet_amout": 1,
              "bet_payrate": payrate[0].four_top,
              "bet_type": fourtop
            }, {
              "id": uuidv4(),
              "history": history,
              "number": combinedNumber4,
              "bet_amout": 1,
              "bet_payrate": payrate[0].four_tod,
              "bet_type": fourtod
            }]
            setArray(arr)
            setHistory(history + 1)
          }

          setNumber1('');
          setNumber2('');
          setNumber3('');
          setNumber4('');
          // console.log(combinedNumber4, arr)
          clearInterval(interval);
        }
      }

      //6 digit
      if (inputType.find(item => item === 'first')) {
        if (number1 !== '') {
          const first = inputType.find(item => item === 'first');
          setNumber1('');
          setNumber2('');
          setNumber3('');
          setNumber4('');
          setNumber5('');
          setNumber6('');
          const arr = [...array, {
            "id": uuidv4(),
            "history": history,
            "number": combinedNumber6,
            "bet_amout": 1,
            "bet_payrate": payrate[0].first,
            "bet_type": first
          }]
          setArray(arr)
          setHistory(history + 1)
          // console.log(combinedNumber4, arr)
          clearInterval(interval);
        }
      }

    }, 200);
  });

  // handle numpad
  const handleNumpad = (e) => {
    const value = e.target.value;
    // console.log(inputType)
    /////////////////////////////////////////////////////////////
    if (inputType.find(item => item === 'run_top') || inputType.find(item => item === 'run_bot')) {
      setNumber1(value);
    }

    //handle for 2 digit

    if (inputType.find(item => item === 'two_top') || inputType.find(item => item === 'two_bot')) {
      if (inputType.find(item => item === 'rollfront') || inputType.find(item => item === 'rollback')) {
        setNumber1(value);
      } else {
        if (number2 === '') {
          setNumber2(value);
        } else if (number1 === '') {
          setNumber2(value);
          setNumber1(...number2);
        }

      }
    }
    //handle for 3 digit

    if (inputType.find(item => item === 'three_top') || inputType.find(item => item === 'three_bot') || inputType.find(item => item === 'three_front') || inputType.find(item => item === 'three_tod')) {
      if (number3 === '') {
        setNumber3(value);
      } else if (number2 === '') {
        setNumber2(...number3);
        setNumber3(value);
      } else if (number1 === '') {
        setNumber1(...number2)
        setNumber2(...number3);
        setNumber3(value);
      }
    }

    //handle for 4 digit

    if (inputType.find(item => item === 'four_top') || inputType.find(item => item === 'four_tod')) {
      if (number4 === '') {
        setNumber4(value);
      } else if (number3 === '') {
        setNumber3(...number4);
        setNumber4(value);
      } else if (number2 === '') {
        setNumber3(...number4);
        setNumber2(...number3);
        setNumber4(value);
      } else if (number1 === '') {
        setNumber1(...number2)
        setNumber2(...number3);
        setNumber3(...number4);
        setNumber4(value);
      }
    }

    if (inputType.find(item => item === 'first')) {
      if (number6 === '') {
        setNumber6(value);
      } else if (number5 === '') {
        setNumber5(...number6);
        setNumber6(value);
      } else if (number4 === '') {
        setNumber4(...number5);
        setNumber5(...number6);
        setNumber6(value);
      } else if (number3 === '') {
        setNumber3(...number4)
        setNumber4(...number5);
        setNumber5(...number6);
        setNumber6(value);
      } else if (number2 === '') {
        setNumber2(...number3)
        setNumber3(...number4)
        setNumber4(...number5);
        setNumber5(...number6);
        setNumber6(value);
      } else if (number1 === '') {
        setNumber1(...number2)
        setNumber2(...number3)
        setNumber3(...number4)
        setNumber4(...number5);
        setNumber5(...number6);
        setNumber6(value);
      }
    }

  };

  // handle numpad
  const handleundo = (e) => {
    //handle for 2 digit
    if (inputType.find(item => item === 'two_top') || inputType.find(item => item === 'two_bot')) {
      if (number1 === '') {
        setNumber2('');
      }
    }
    //handle for 3 digit

    if (inputType.find(item => item === 'three_top') || inputType.find(item => item === 'three_bot') || inputType.find(item => item === 'three_front') || inputType.find(item => item === 'three_tod')) {
      if (number2 === '') {
        setNumber3('');
        setNumber2('');
      } else if (number1 === '') {
        setNumber3(number2);
        setNumber2('');
      }
    }

    //handle for 4 digit

    if (inputType.find(item => item === 'four_top') || inputType.find(item => item === 'four_tod')) {
      if (number3 === '') {
        setNumber4('');
        setNumber2('');
        setNumber3('');
        setNumber4('');
      } else if (number2 === '') {
        setNumber4(number3);
        setNumber2('');
        setNumber3('');
      } else if (number1 === '') {
        setNumber3(number2);
        setNumber4(number3);
        setNumber2('');
      }
    }

    if (inputType.find(item => item === 'first')) {
      if (number5 === '') {
        setNumber1('')
        setNumber2('')
        setNumber3('')
        setNumber4('');
        setNumber5('');
        setNumber6('');
      } else if (number4 === '') {
        setNumber6(number5);
        setNumber1('')
        setNumber2('')
        setNumber3('')
        setNumber4('');
        setNumber5('');
      } else if (number3 === '') {
        setNumber6(number5);
        setNumber5(number4);
        setNumber1('')
        setNumber2('')
        setNumber3('')
        setNumber4('');
      } else if (number2 === '') {
        setNumber6(number5);
        setNumber5(number4);
        setNumber4(number3);
        setNumber1('')
        setNumber2('')
        setNumber3('')
      } else if (number1 === '') {
        setNumber6(number5);
        setNumber5(number4);
        setNumber4(number3);
        setNumber3(number2)
        setNumber1('')
        setNumber2('')
      }
    }
  };

  // handle Type
  const handleType = (event) => {
    if (
      event.target.value === 'two_top'
      || event.target.value === 'two_bot'
      || event.target.value === 'twoswap'
      || event.target.value === 'rollfront'
      || event.target.value === 'rollback'
    ) {
      if (inputType.includes(event.target.value)) {
        const newArray = inputType.filter(item => item !== event.target.value);
        setInputType(newArray);
      } else {
        if (event.target.value === 'rollback' || event.target.value === 'rollfront') {
          const newArray1 = inputType.filter(item => item === 'two_top' || item === 'two_bot' || item === 'rollfront' || item === 'rollback');
          const newArray = [...newArray1, event.target.value];
          setInputType(newArray);
        } else if (event.target.value === 'twoswap') {
          const newArray1 = inputType.filter(item => item === 'two_top' || item === 'two_bot' || item === 'twoswap');
          const newArray = [...newArray1, event.target.value];
          setInputType(newArray);
        } else {
          const newArray1 = inputType.filter(item => item === 'two_top' || item === 'two_bot');
          const newArray = [...newArray1, event.target.value];
          setInputType(newArray);

        }
      }
    }

    if (event.target.value === 'run_top' || event.target.value === 'run_bot') {
      if (inputType.includes(event.target.value)) {
        const newArray = inputType.filter(item => item !== event.target.value);
        setInputType(newArray);
      } else {
        const newArray1 = inputType.filter(item => item === 'run_top' || item === 'run_bot');
        const newArray = [...newArray1, event.target.value];
        setInputType(newArray);
      }
    }

    if (event.target.value === 'three_top' || event.target.value === 'three_bot' || event.target.value === 'three_tod' || event.target.value === 'three_front' || event.target.value === 'threeswap') {
      if (inputType.includes(event.target.value)) {
        const newArray = inputType.filter(item => item !== event.target.value);
        setInputType(newArray);
      } else {
        const newArray1 = inputType.filter(item => item === 'three_top' || item === 'three_bot' || item === 'three_tod' || item === 'three_front' || item === 'threeswap');
        const newArray = [...newArray1, event.target.value];
        setInputType(newArray);
      }
    }

    if (event.target.value === 'four_top' || event.target.value === 'four_tod') {
      if (inputType.includes(event.target.value)) {
        const newArray = inputType.filter(item => item !== event.target.value);
        setInputType(newArray);
      } else {
        const newArray1 = inputType.filter(item => item === 'four_top' || item === 'four_tod');
        const newArray = [...newArray1, event.target.value];
        setInputType(newArray);
      }
    }

    if (event.target.value === 'first') {
      if (inputType.includes(event.target.value)) {
        const newArray = inputType.filter(item => item !== event.target.value);
        setInputType(newArray);
      } else {
        const newArray1 = inputType.filter(item => item === 'first');
        const newArray = [...newArray1, event.target.value];
        setInputType(newArray);
      }
    }
    // setInputType(event.target.value);
    setNumber1('');
    setNumber2('');
    setNumber3('');
    setNumber4('');
    setNumber5('');
    setNumber6('');
    setNumpadvisible(true)
    // console.log(inputType,getpayrate[0])
  };

  const handleDelete = id => {
    const deletVal = [...array]
    let tempExpenses = deletVal.filter(item => item.id !== id);
    setArray(tempExpenses);
  };

  // handle change bet_amout
  const handleChange = (e, id) => {
    const inputdata = [...array];
    const itemToUpdate = inputdata.find((item) => item.id === id);
    const newData = { "bet_amout": parseInt(e.target.value) };
    const type = itemToUpdate.bet_type;

    // Convert the input value to a number or set it to 0 if blank
    const parsedValue = e.target.value.trim() !== "" ? parseInt(e.target.value) : 0;

    if (parsedValue > parseInt(maxbet[type])) {
      newData.bet_amout = parseInt(maxbet[type]);
    } else if (parsedValue < parseInt(minbet[type])) {
      newData.bet_amout = parseInt(minbet[type]);
    }
    else {
      newData.bet_amout = parsedValue;
    }

    itemToUpdate.bet_amout = newData.bet_amout;
    Object.assign(itemToUpdate, newData);
    setArray(inputdata);
  };

  // handle cal
  const handlecal = (e) => {
    const value = e.target.value;
    setAllbetamt(value)

  }
  const handlecalbutton = () => {
    const arr = [...array]
    for (let i = 0; i < arr.length; i++) {

      if (allbetamt > parseInt(maxbet[arr[i].bet_type])) {
        arr[i].bet_amout = parseInt(maxbet[arr[i].bet_type]);
        setArray(arr)
        // console.log('if',arr[i].bet_amout,' > ',maxbet[arr[i].bet_type])
      }
      else {
        arr[i].bet_amout = parseInt(allbetamt);
        setArray(arr)
        // console.log('else',arr[i].bet_amout,' > ',maxbet[arr[i].bet_type])
      }

    }
    setAllbetamt(0)
  }

  const handlecalbuttonfixprice = (bet) => {
    const arr = [...array];
    const parsedBet = parseInt(bet);
    const defaultBetValue = 0;

    for (let i = 0; i < arr.length; i++) {
      if (isNaN(parsedBet) || parsedBet === 0) { // Check for NaN or 0
        arr[i].bet_amout = defaultBetValue;
      } else if (parsedBet > parseInt(maxbet[arr[i].bet_type])) {
        arr[i].bet_amout = parseInt(maxbet[arr[i].bet_type]);
      } else if (parsedBet < parseInt(minbet[arr[i].bet_type])) {
        arr[i].bet_amout = parseInt(minbet[arr[i].bet_type]);
      } else {
        arr[i].bet_amout = parsedBet;
      }
    }

    setArray(arr);
  }


  const user = localStorage.getItem('user');

  function calculateItemSums() {
    const itemSums = array.reduce((acc, currentItem) => {
        acc[currentItem.bet_type] = (acc[currentItem.bet_type] || 0) + currentItem.bet_amout;
        return acc;
    }, {});
    return itemSums;
}

function checkPricesAgainstMax() {
  const itemSums = calculateItemSums();
  for (const [item, sum] of Object.entries(itemSums)) {
      if (sum > maxbetpermatch[item]) {

        let message;
        if (item === 'first') {
          message = 'รางวัลที่หนึ่ง';
        } else if (item === 'four_top') {
          message = 'สี่ตัวตรง';
        } else if (item === 'four_tod') {
          message = 'สี่ตัวโต๊ด';
        } else if (item === 'three_top') {
          message = 'สามตัวบน';
        } else if (item === 'three_front') {
          message = 'สามตัวหน้า';
        } else if (item === 'three_bot') {
          message = 'สามตัวหลัง';
        } else if (item === 'three_tod') {
          message = 'สามตัวโต๊ด';
        } else if (item === 'two_top') {
          message = 'สองตัวบน';
        } else if (item === 'two_bot') {
          message = 'สองตัวล่าง';
        } else if (item === 'run_top') {
          message = 'วิ่งบน';
        } else if (item === 'run_bot') {
          message = 'วิ่งล่าง';
        }


          toast.error(message+" เดิมพันรวมเกิน "+maxbetpermatch[item]+" บาท", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })

          return false;  // If any item exceeds the max price or has no max price defined
      }
  }
  return true;  // All items are within their respective maximum prices
}

function checkzeropayrate()
{
  if (array.some(item => item.bet_payrate === 0)) 
    {
      toast.error("กรุณายกเลิกเดิมพันที่ที่ราคาจ่าย 0 บาท", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
    return false
    }

  return true
}


  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(false)
    const jsonData = {
      username: user,
      type: props.type,
      round: props.round,
      status: "wait_result",
      resultdate: props.resultdate,
      array: array
    };

    const totalPrice = array.reduce((sum, item) => sum + item.bet_amout, 0);
    if (array.length !== 0) {
      if(props.balance >= totalPrice)
      {
        if(checkPricesAgainstMax() && checkzeropayrate())
        {
          Swal.fire({
            title: 'คุณต้องการส่งโพย?',
            text: 'คลิ้กยืนยันเพื่อส่งโพยของคุณ',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'แก้ไขโพย'
          }).then((result) => {
            if (result.isConfirmed) {
              fetch(`${process.env.REACT_APP_API_KEY}/orders`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(jsonData),
              })
                .then(response => response.json())
                .then(result => {
                  // Toastify success notification
                  toast.success('ส่งโพยของคุณเรียบร้อย ระบบกำลังพาคุณไป!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  })
                  setTimeout(() => {
                    // Reload the page
                    window.location = '/dashboard/orderlist';
                  }, 1000); // Delay for 5 seconds
    
                })
                .catch((error) => {
                  console.error('Error:', error);
                });
            } else {
              Swal.fire('Cancelled', 'Your submission has been cancelled', 'error')
              setOpen(true)
            }
          })    
        }
        else{
  
        }
      } else
      {
        toast.error('คุณมีเครดิตไม่เพียงพอ', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      }
    }
    else {
      toast.error('กรุณาเลือกเลขที่ต้องการซื้อ ก่อนกดส่งโพย', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
    }
  };


  const [payrate, setPayrate] = useState([]);
  const [limitnumber, setLimitnumber] = useState([]);
  const [maxbet, setMaxbet] = useState([]);
  const [maxbetpermatch, setMaxbetpermatch] = useState([]);
  const [minbet, setMinbet] = useState([]);



  const Payrateget = () => {
    const jsonData = {
      memberuser: localStorage.getItem('user'),
    };

    fetch(`${process.env.REACT_APP_API_KEY}/Getmemberpayrate`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonData),
    })
      .then(res => res.json())
      .then(
        (result) => {
          setPayrate(result.filter(item => item.lotto_type === props.type))
          // console.log(payrate.map((row) => (row.two_top)))
        }
      )
  }

  const Limitnumberget = () => {
    const jsonData = {
      lotto_type: props.type,
      res_date: props.resultdate,
      round: props.round,
      memberuser: localStorage.getItem('user'),
    }

    fetch(`${process.env.REACT_APP_API_KEY}/getlimitnumberformember`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonData)
    })
      .then(res => res.json())
      .then(
        (result) => {
          setLimitnumber(result)
        }
      )
  }

  const maxbetget = () => {
    const jsonData = {
      lottotype: props.type,
      memberuser: localStorage.getItem('user'),
    };

    fetch(`${process.env.REACT_APP_API_KEY}/getmaxbetformember`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonData),
    })
      .then(res => res.json())
      .then(
        (result) => {
          setMaxbet(result[0])
        }
      )
  }

  const maxbetpermatchget = () => {
    const jsonData = {
      lottotype: props.type,
      memberuser: localStorage.getItem('user'),
    };

    fetch(`${process.env.REACT_APP_API_KEY}/getmaxbetpermatchformember`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonData),
    })
      .then(res => res.json())
      .then(
        (result) => {
          setMaxbetpermatch(result[0])
        }
      )
  }

  const minbetget = () => {
    const jsonData = {
      lottotype: props.type,
      memberuser: localStorage.getItem('user'),
    };

    fetch(`${process.env.REACT_APP_API_KEY}/getminbetformember`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonData),
    })
      .then(res => res.json())
      .then(
        (result) => {
          setMinbet(result[0])
        }
      )
  }


  useEffect(() => {
    Payrateget()
    Limitnumberget()
    maxbetget()
    minbetget()
    maxbetpermatchget()

  }, [])

  useEffect(() => {
    const array1 = array
    const array2 = limitnumber

    array1.forEach((item1) => {
      const item2 = array2.find((i) => i.number === item1.number && i.number_type === item1.bet_type);
      // console.log(limitnumber)
      if (item2) {
        item1.bet_payrate = item2.limit_payrate;
      }
    });

    setArray(array1)
    const GroupArray = array.reduce((x, y) => {
      (x[y.bet_type] = x[y.bet_type] || []).push(y);
      return x;
    }, {});
    setgrr(GroupArray)
    // console.log("grouparray",GroupArray)
    // console.log("array",array)
  }, [array])


  // const getpayrate = payrate.map((item) => (item[inputType]))
  const [selecttype, setSelecttype] = useState('numpad')
  const [modal, setModal] = useState('')

  //////////////////////////////// numlist handle button in numlist

  const handleClick = (event) => {
    const threetop = inputType.find(item => item === 'three_top');
    const threefront = inputType.find(item => item === 'three_front');
    const threebot = inputType.find(item => item === 'three_bot');
    const threetod = inputType.find(item => item === 'three_tod');
    const threetoppayrate = payrate.map((item) => (item.three_top))
    const threefrontpayrate = payrate.map((item) => (item.three_front))
    const threebotpayrate = payrate.map((item) => (item.three_bot))
    const threetodpayrate = payrate.map((item) => (item.three_tod))

    const twotop = inputType.find(item => item === 'two_top');
    const twobot = inputType.find(item => item === 'two_bot');
    const twotoppayrate = payrate.map((item) => (item.two_top))
    const twobotpayrate = payrate.map((item) => (item.two_bot))

    const runtop = inputType.find(item => item === 'run_top');
    const runbot = inputType.find(item => item === 'run_bot');
    const runtoppayrate = payrate.map((item) => (item.run_top))
    const runbotpayrate = payrate.map((item) => (item.run_top))

    ////////////////////////// handle three digit ////////////////////////////
    ////////////////////////// only three top
    if (threetop && !threefront && !threebot && !threetod) {
      const arr = [...array, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threetoppayrate,
        "bet_type": threetop
      }]
      setArray(arr)
      setModal(event.target.value)

    }
    ////////////////////////// only three front
    if (!threetop && threefront && !threebot && !threetod) {
      const arr = [...array, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threefrontpayrate,
        "bet_type": threefront
      }]
      setArray(arr)
      setModal(event.target.value)

    }
    ////////////////////////// only three bot
    if (!threetop && !threefront && threebot && !threetod) {
      const arr = [...array, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threebotpayrate,
        "bet_type": threebot
      }]
      setArray(arr)
      setModal(event.target.value)

    }
    ////////////////////////// only three tod
    if (!threetop && !threefront && !threebot && threetod) {
      const arr = [...array, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threetodpayrate,
        "bet_type": threetod
      }]
      setArray(arr)
      setModal(event.target.value)

    }

    //////////////////////////  three top & threefront
    if (threetop && threefront && !threebot && !threetod) {
      const arr = [...array, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threetoppayrate,
        "bet_type": threetop
      }, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threefrontpayrate,
        "bet_type": threefront
      }
      ]
      setArray(arr)
      setModal(event.target.value)

    }
    //////////////////////////  three top & threetod
    if (threetop && !threefront && !threebot && threetod) {
      const arr = [...array, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threetoppayrate,
        "bet_type": threetop
      }, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threetodpayrate,
        "bet_type": threetod
      }
      ]
      setArray(arr)
      setModal(event.target.value)

    }
    //////////////////////////  three top & threebot
    if (threetop && !threefront && threebot && !threetod) {
      const arr = [...array, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threetoppayrate,
        "bet_type": threetop
      }, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threebotpayrate,
        "bet_type": threebot
      }
      ]
      setArray(arr)
      setModal(event.target.value)

    }

    //////////////////////////  three front & three tod
    if (!threetop && threefront && !threebot && threetod) {
      const arr = [...array, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threefrontpayrate,
        "bet_type": threefront
      }, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threetodpayrate,
        "bet_type": threetod
      }
      ]
      setArray(arr)
      setModal(event.target.value)
    }
    //////////////////////////  three tod & threebot
    if (!threetop && !threefront && threebot && threetod) {
      const arr = [...array, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threebotpayrate,
        "bet_type": threebot
      }, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threetodpayrate,
        "bet_type": threetod
      }
      ]
      setArray(arr)
      setModal(event.target.value)
    }
    //////////////////////////  three front & threebot
    if (!threetop && threefront && threebot && !threetod) {
      const arr = [...array, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threefrontpayrate,
        "bet_type": threefront
      }, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threebotpayrate,
        "bet_type": threebot
      }
      ]
      setArray(arr)
      setModal(event.target.value)
    }
    //////////////////////////  three top & threetod & threefront
    if (threetop && threefront && !threebot && threetod) {
      const arr = [...array, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threetoppayrate,
        "bet_type": threetop
      }, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threefrontpayrate,
        "bet_type": threefront
      }, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threetodpayrate,
        "bet_type": threetod
      }
      ]
      setArray(arr)
      setModal(event.target.value)
    }
    //////////////////////////  three top & threetod & threeback
    if (threetop && !threefront && threebot && threetod) {
      const arr = [...array, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threetoppayrate,
        "bet_type": threetop
      }, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threebotpayrate,
        "bet_type": threebot
      }, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threetodpayrate,
        "bet_type": threetod
      }
      ]
      setArray(arr)
      setModal(event.target.value)
    }
    //////////////////////////  three top & threefront & threeback
    if (threetop && threefront && threebot && !threetod) {
      const arr = [...array, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threetoppayrate,
        "bet_type": threetop
      }, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threebotpayrate,
        "bet_type": threebot
      }, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threefrontpayrate,
        "bet_type": threefront
      }
      ]
      setArray(arr)
      setModal(event.target.value)
    }
    //////////////////////////  three tod & threefront & threeback
    if (!threetop && threefront && threebot && threetod) {
      const arr = [...array, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threetodpayrate,
        "bet_type": threetod
      }, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threebotpayrate,
        "bet_type": threebot
      }, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threefrontpayrate,
        "bet_type": threefront
      }
      ]
      setArray(arr)
      setModal(event.target.value)
    }
    //////////////////////////  all select
    if (threetop && threefront && threebot && threetod) {
      const arr = [...array, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threetodpayrate,
        "bet_type": threetod
      }, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threebotpayrate,
        "bet_type": threebot
      }, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threefrontpayrate,
        "bet_type": threefront
      }, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": threetoppayrate,
        "bet_type": threetop
      }
      ]
      setArray(arr)
      setModal(event.target.value)
    }

    /////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////// handle two digit ////////////////////////////
    ////////////////////////// only two top
    if (twotop && !twobot) {
      const arr = [...array, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": twotoppayrate,
        "bet_type": twotop
      }]
      setArray(arr)
      setModal(event.target.value)
    }
    ////////////////////////// only two bot
    if (!twotop && twobot) {
      const arr = [...array, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": twobotpayrate,
        "bet_type": twobot
      }]
      setArray(arr)
      setModal(event.target.value)
    }
    ////////////////////////// all select
    if (twotop && twobot) {
      const arr = [...array, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": twotoppayrate,
        "bet_type": twotop
      }, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": twobotpayrate,
        "bet_type": twobot
      }]
      setArray(arr)
      setModal(event.target.value)
    }

    /////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////// handle 1 digit ////////////////////////////
    ////////////////////////// only run top
    if (runtop && !runbot) {
      const arr = [...array, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": runtoppayrate,
        "bet_type": runtop
      }]
      setArray(arr)
      setModal(event.target.value)
    }
    ////////////////////////// only two bot
    if (!runtop && runbot) {
      const arr = [...array, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": runbotpayrate,
        "bet_type": runbot
      }]
      setArray(arr)
      setModal(event.target.value)
    }
    ////////////////////////// all select
    if (runtop && runbot) {
      const arr = [...array, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": runtoppayrate,
        "bet_type": runtop
      }, {
        "id": uuidv4(),
        "number": event.target.value,
        "bet_amout": 1,
        "bet_payrate": runbotpayrate,
        "bet_type": runbot
      }]
      setArray(arr)
      setModal(event.target.value)
    }




    // alert(`You clicked button ${buttonLabel}`);
    setShowModal(true);
    // hide the modal after 1 second
    setTimeout(() => {
      setShowModal(false);
    }, 300);

  };

  const [showModal, setShowModal] = useState(false);

  function generateButtonArray(firststdigit, maxdigit, pad, status) {
    const buttonArray = [];
    for (let i = 0; i <= maxdigit; i++) {
      const twoDigitNumber = i.toString().padStart(pad, '0')

      if (twoDigitNumber.startsWith(firststdigit)) {
        buttonArray.push(
          <button className='rounded border-[1px] text-[15px] md:w-[10%] w-1/5 p-2 border-slate-200 bg-purple-800 hover:bg-purple-900' key={i} disabled={status} value={twoDigitNumber} onClick={handleClick}>
            {twoDigitNumber}
          </button>
        );
      }
    }
    return buttonArray;
  }
  function setArrayZe() {
    setArray([]);
  }

  function generate2digitButtonArray(firststdigit, maxdigit, pad, status) {
    const buttonArray = [];
    for (let i = 0; i <= maxdigit; i++) {
      const twoDigitNumber = i.toString().padStart(pad, '0')
      buttonArray.push(
        <button className='rounded border-[1px] text-[15px] md:w-[10%] w-1/5 p-2 border-slate-200 bg-purple-800 hover:bg-purple-900' key={i} disabled={status} value={twoDigitNumber} onClick={handleClick}>
          {twoDigitNumber}
        </button>
      );
    }
    return buttonArray;
  }


  const [numset, setNumset] = useState('0')
  const firstnumset = (e) => {
    setNumset(e.target.value)
  }

  const initialValue = 0;
  const sumWithInitial = array.reduce(
    (accumulator, currentValue) => accumulator + parseInt(currentValue.bet_amout),
    initialValue
  );
  const isMobile = useMediaQuery('(max-width: 768px)');

  

  function deleteLatestHistory() {
    let latestHistory = 0;
    let updatearray = [...array]

    // Find the latest history value
    updatearray.forEach((item) => {
      if (item.history > latestHistory) {
        latestHistory = item.history;
      }
    });

    if (latestHistory !== 0) {
      // Remove all entries with the latest history value
      updatearray = updatearray.filter((item) => item.history !== latestHistory);
      setArray(updatearray); // Update the state with the modified array
    }

  }


  return (

    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }} style={{ display: 'flex', flexDirection: 'column' }}>
      <div className='flex flex-col justify-center'>
        <div className='w-[100%] flex flex-row h-[40px] p-2 justify-center items-center bg-slate-700 space-x-2 border-[1px] border-slate-700 rounded-t'>
          <Link to="/dashboard" className='md:w-[5%] text-center bg-red-600 rounded justify-center border shadow-md flex justify-center hover:bg-red-700'>
            <span className='text-white font-bold'>กลับ</span>
          </Link>
          <span className='text-white font-bold text-m'>
          </span>
          <div className='md:w-[12%] text-center bg-slate-200 rounded place-content-center border border-gray-200 flex justify-center'>
            <span className='text-purple-700 font-bold'>
              <span className='text-black font-bold text-m'>แทง </span>
              {props.type === 'th_gov' && 'หวยรัฐบาลไทย'}
              {props.type === 'hanoy_PhuQua' && 'หวยฮานอย'}
              {props.type === 'laos_gov' && 'หวยลาว'}
              {props.type === 'Yeekee_5' && 'ยี่กี่ ปกติ'}
              {props.type === 'Yeekee_10' && 'ยี่กี่ VIP'}
              {props.type === 'Yeekee_15' && 'ยี่กี่ VVIP'}
            </span>
          </div>
        </div>
        <div className='flex flex-row bg-gradient-to-r from-purple-400 from-30% via-purple-450 via-50% to-purple-700 to-90% border-[1px] border-slate-700 w-[100%] h-[auto] drop-shadow-2xl'>
          <div className='flex_type'>
            <div className='flex flex-col p-1 space-y-2 w-[30%] bg-slate-900 h-auto justify-between'>
              <div className='flex flex-col space-y-2 w-[100%]'>
                <div className='w-[100%] border-b-[1px] border-slate-300 p-1 space-y-2'>
                  <span className='font-bold text-red-600 md:text-lg text-[0.6rem]'><DescriptionIcon />รายการแทง {array.length} รายการ</span>
                  <button onClick={handleClickOpen} className='bg-purple-800 w-[100%] h-[20px] rounded hover:bg-purple-900 text-[15px]'>ใส่ราคา</button>

                  <Dialog open={open} onClose={handleClose} maxWidth="xl"
                    fullWidth={true} fullScreen={isMobile ? true : false}
                  >
                    <DialogTitle className='flex text-center bg-purple-700 h-auto'>
                      <span className='text-white text-[20px]'>ใส่ราคา</span>
                      <button onClick={handleClose} className="ml-auto" >
                        <CloseIcon></CloseIcon>
                      </button>
                    </DialogTitle>
                    <DialogContent sx={{ padding: 0 }}>
                      <div className='h-[100%] flex flex-col'>

                        <Lottotypeformodal grouparray={garr} handleDelete={handleDelete} handleChange={handleChange} />

                      </div>
                    </DialogContent>
                    <DialogActions className='bg-slate-100 flex flex-col'>
                      <div className='w-[100%] flex flex-col'>
                        <div className='flex rounded border-[1px] border-black md:p-3 flex-row w-[100%]'>
                          <div className='flex flex-col w-[100%] justify-center items-center'>
                            <span className='text-green-600 font-bold'>ราคา "เท่ากัน" ทั้งหมด</span>
                            <div className='flex flex-row w'>
                              <input
                                type="number" value={allbetamt}
                                onClick={(e) => e.target.select()}
                                onChange={(e) => {
                                  handlecal(e)
                                  handlecalbuttonfixprice(e.target.value)
                                }} min="1"
                                className='w-[100%] border-[1px] border-black rounded-l p-1'></input>
                              <button
                                onClick={handlecalbutton}
                                className="border-[1px] border-green-500 hover:bg-green-700 text-green-600 font-bold py-2 px-4 rounded-r">
                                +</button>
                            </div>


                          </div>
                          <div className='flex flex-col w-[100%] justify-center items-center'>
                            <div className='flex flex-row space-x-2 w-[100%] md:p-3'>
                              <button
                                className='p-1 bg-slate-400 border-[1px] border-slate-700 rounded text-center w-[25%]'
                                onClick={() => handlecalbuttonfixprice(5)}>
                                <span className='text-black font-bold'>5 ฿</span>
                              </button>
                              <button
                                onClick={() => handlecalbuttonfixprice(10)}
                                className='p-1 bg-slate-400 border-[1px] border-slate-700 rounded text-center w-[25%]'>
                                <span className='text-black font-bold'>10 ฿</span>
                              </button>
                              <button
                                onClick={() => handlecalbuttonfixprice(20)}
                                className='p-1 bg-slate-400 border-[1px] border-slate-700 rounded text-center w-[25%]'>
                                <span className='text-black font-bold'>20 ฿</span>
                              </button>
                              <button
                                onClick={() => handlecalbuttonfixprice(50)}
                                className='p-1 bg-slate-400 border-[1px] border-slate-700 rounded text-center w-[25%]'>
                                <span className='text-black font-bold'>50 ฿</span>
                              </button>
                            </div>
                            <div className='flex flex-row items-center justify-center space-x-2 w-[100%] md:p-3'>
                              <button
                                onClick={() => handlecalbuttonfixprice(100)}
                                className='p-1 bg-slate-400 border-[1px] border-slate-700 rounded text-center w-[100%]'>
                                <span className='text-black font-bold'>100 ฿</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='flex border-black flex-row w-[100%]'>
                          <div className='flex flex-col w-[100%] p-1 h-[50px] bg-blue-600 justify-center items-center'>
                            <span className='font-bold text-white text-[13px]'>ยอดเครดิตคงเหลือ</span>
                            <span className='font-bold text-white text-[16px]'>{props.balance} ฿</span>
                          </div>
                          <div className='flex flex-col w-[100%] p-1 h-[50px] bg-red-500 justify-center items-center'>
                            <span className='font-bold text-white text-[13px]'>รวมยอดแทง</span>
                            <span className='font-bold text-white text-[16px]'>{sumWithInitial} ฿</span>
                          </div>
                        </div>

                        <div className='flex border-black  flex-row w-[100%]'>
                          <div className='flex flex-col w-[100%] p-1 h-[50px] justify-center items-center'>
                            <div className='flex flex-row items-center justify-center space-x-2 w-[100%] p-3'>
                              <button onClick={setArrayZe} className='p-1 bg-slate-200 rounded text-center w-[100%] hover:bg-slate-400'>
                                <span className='text-black font-bold'>ยกเลิกทั้งหมด</span>
                              </button>
                            </div>
                          </div>

                          <div className='flex flex-col w-[100%] p-1 h-[50px] justify-center items-center'>
                            <button onClick={handleSubmit} className='p-1 bg-green-600 rounded text-center w-[100%] hover:bg-green-900'>
                              <span className='text-white font-bold'>ส่งโพย</span>
                            </button>
                          </div>
                        </div>

                      </div>
                    </DialogActions>
                  </Dialog>

                  {/* //////////////////////////////////////// */}
                </div>
                <Lottotype grouparray={garr} handleDelete={handleDelete} handleChange={handleChange} />
                <button className='bg-red-800 p-1 w-[100%] rounded hover:bg-red-900' onClick={setArrayZe}><DeleteForeverIcon className='text-white' /><span className='md:inline hidden'>ลบรายการทั้งหมด</span></button>
              </div>
            </div>
            <div className='flex flex-col w-[65%] p-1 h-auto bg-slate-200 h-auto'>
              <div className='flex flex-row w-[100%] h-[auto]'>

                <div onClick={(e) => setSelecttype('numpad')} value="numpad"
                  className={selecttype === "numpad" ?
                    'w-[50%] h-[50px] justify-center p-2 items-center text-center rounded-t-lg bg-red-900 border-[1px] border-red-800'
                    :
                    'w-[50%] h-[50px] justify-center p-2 items-center text-center rounded-t-lg bg-red-400 border-[1px] border-red-800 hover:bg-red-900'
                  }
                >
                  <span className='font-bold text-white md:text-lg text-xs'>กดเลขเอง</span>
                </div>

                <div onClick={(e) => setSelecttype('numlist')} value="numlist"
                  className={selecttype === "numlist" ?
                    'w-[50%] h-[50px] justify-center p-2 items-center text-center rounded-t-lg bg-red-900 border-[1px] border-red-800'
                    :
                    'w-[50%] h-[50px] justify-center p-2 items-center text-center rounded-t-lg bg-red-400 border-[1px] border-red-800 hover:bg-red-900'
                  }
                >
                  <span className='font-bold text-white md:text-lg text-xs'>เลือกจากแผง</span>
                </div>
              </div>

              <div className='flex flex-row w-[100%] h-[auto] p-1'>
                {selecttype && <Typeselect />}
                {/* ตัวเลือกเพิ่ม */}
              </div>

              <div className='w-[100%] flex flex-wrap flex-col h-auto justify-center mt-1 text-center bg-slate-200 items-center border-slate-400 border-[1px] p-1'>
                {inputType.includes('two_top') || inputType.includes('two_bot') ? <Addontype /> : <></>}

                <span className="text-m font-thin text-black">
                  รายการที่เลือก
                </span>
                <div className='flex flex-row space-x-2 space-around'>
                  {inputType.find(item => item === 'run_top') && <span className='font-bold text-[15px] text-green-600 rounded'>วิ่งบน</span>}
                  {inputType.find(item => item === 'run_bot') && <span className='font-bold text-[15px] text-green-600 rounded'>วิ่งล่าง</span>}
                  {inputType.find(item => item === 'two_top') && <span className='font-bold text-[15px] text-blue-600 rounded'>สองตัวบน</span>}
                  {inputType.find(item => item === 'two_bot') && <span className='font-bold text-[15px] text-blue-600 rounded'>สองตัวล่าง</span>}
                  {inputType.find(item => item === 'twoswap') && <span className='font-bold text-[15px] text-blue-600 rounded'>สองตัวกลับ</span>}
                  {inputType.find(item => item === 'rollfront') && <span className='font-bold text-[15px] text-yellow-600 rounded'>รูดหลัง</span>}
                  {inputType.find(item => item === 'rollback') && <span className='font-bold text-[15px] text-yellow-600 rounded'>รูดหน้า</span>}
                  {inputType.find(item => item === 'three_top') && <span className='font-bold text-[15px] text-red-600 rounded'>สามตัวบน</span>}
                  {inputType.find(item => item === 'threeswap') && <span className='font-bold text-[15px] text-red-600 rounded'>สามตัวกลับ</span>}
                  {inputType.find(item => item === 'three_bot') && <span className='font-bold text-[15px] text-red-600 rounded'>สามตัวหลัง</span>}
                  {inputType.find(item => item === 'three_tod') && <span className='font-bold text-[15px] text-red-600 rounded'>สามตัวโต๊ด</span>}
                  {inputType.find(item => item === 'three_front') && <span className='font-bold text-[15px] text-red-600 rounded'>สามตัวหน้า</span>}
                  {inputType.find(item => item === 'four_top') && <span className='font-bold text-[15px] text-yellow-600 rounded'>สี่ตัวบน</span>}
                  {inputType.find(item => item === 'four_tod') && <span className='font-bold text-[15px] text-yellow-600 rounded'>สี่ตัวโต๊ด</span>}
                  {inputType.find(item => item === 'first') && <span className='font-bold text-[15px] text-purple-600 rounded'>รางวัลที่ 1</span>}
                </div>



                {selecttype === "numpad" &&
                  <Label className='label' type={inputType} number1={number1} number2={number2} number3={number3} number4={number4} number5={number5} number6={number6} />
                }

                {selecttype === "numlist" && digittype === 'three' &&
                  <div id="firstnum" className="flex flex-row w-[100%] justify-around">
                    <button className='rounded border-[1px] text-[15px] w-[10%] border-slate-200 bg-purple-800 hover:bg-purple-900' value='0' onClick={(e) => firstnumset(e)}>0</button>
                    <button className='rounded border-[1px] text-[15px] w-[10%] border-slate-200 bg-purple-800 hover:bg-purple-900' value='1' onClick={(e) => firstnumset(e)}>1</button>
                    <button className='rounded border-[1px] text-[15px] w-[10%] border-slate-200 bg-purple-800 hover:bg-purple-900' value='2' onClick={(e) => firstnumset(e)}>2</button>
                    <button className='rounded border-[1px] text-[15px] w-[10%] border-slate-200 bg-purple-800 hover:bg-purple-900' value='3' onClick={(e) => firstnumset(e)}>3</button>
                    <button className='rounded border-[1px] text-[15px] w-[10%] border-slate-200 bg-purple-800 hover:bg-purple-900' value='4' onClick={(e) => firstnumset(e)}>4</button>
                    <button className='rounded border-[1px] text-[15px] w-[10%] border-slate-200 bg-purple-800 hover:bg-purple-900' value='5' onClick={(e) => firstnumset(e)}>5</button>
                    <button className='rounded border-[1px] text-[15px] w-[10%] border-slate-200 bg-purple-800 hover:bg-purple-900' value='7' onClick={(e) => firstnumset(e)}>7</button>
                    <button className='rounded border-[1px] text-[15px] w-[10%] border-slate-200 bg-purple-800 hover:bg-purple-900' value='8' onClick={(e) => firstnumset(e)}>8</button>
                    <button className='rounded border-[1px] text-[15px] w-[10%] border-slate-200 bg-purple-800 hover:bg-purple-900' value='9' onClick={(e) => firstnumset(e)}>9</button>
                  </div>
                }
              </div>

              <div className='w-[100%] h-auto justify-center items-center'>
                <div className='div2'>
                  {selecttype === "numlist" &&
                    <div id="selectnum" className="flex flex-row flex-wrap w-[100%] h-auto justify-center items-center">

                      {digittype === 'run' &&
                        <>
                          {
                            inputType.includes('run_top')
                              || inputType.includes('run_bot')
                              ? generate2digitButtonArray(0, 9, '', 0) : generate2digitButtonArray(0, 9, '', 1)

                          }
                        </>
                      }


                      {digittype === 'two' &&
                        <>
                          {
                            inputType.includes('two_top')
                              || inputType.includes('two_bot')
                              ? generate2digitButtonArray(numset, 99, 2, 0) : generate2digitButtonArray(numset, 99, 2, 1)

                          }
                        </>
                      }

                      {digittype === 'three' &&
                        <>
                          {
                            inputType.includes('three_top')
                              || inputType.includes('three_bot')
                              || inputType.includes('three_front')
                              || inputType.includes('three_tod')
                              ? generateButtonArray(numset, 999, 3, 0) : generateButtonArray(numset, 999, 3, 1)

                          }
                        </>
                      }
                    </div>
                  }
                  {selecttype === "numpad" && <Numpad numpadvisible={numpadvisible} handleNumpad={handleNumpad} handleCancelLastest={deleteLatestHistory} handleundo={handleundo} />}

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </motion.div>


  )
}

export default Numadd