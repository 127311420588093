import React, { useState } from 'react';
import { motion } from 'framer-motion';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function Lottotype(props) {
  // const [inputLotto, setInputLotto] = useState([
  //   {
  //     "number": "25",
  //     "bet_amout": 0,
  //     "bet_payrate": 90,
  //     "bet_type": "TWO_TOP"
  //   }
  //   ,
  //   {
  //     "number": "52",
  //     "bet_amout": 0,
  //     "bet_payrate": 90,
  //     "bet_type": "TWO_TOP"
  //   }
  // ]);

  // const result = inputLotto.reduce((x, y) => {
  //   (x[y.bet_type] = x[y.bet_type] || []).push(y);
  //   return x;
  // }, {});



  const handleDelete = props.handleDelete;
  const handleChange = props.handleChange;

  // console.log(props.grouparray)
  return (
    (
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className='flex flex-col space-y-2 border-dashed border-b-[1px] border-slate-400'>
        {/* makeall this in the same row */}
        {/* <button onClick={()=>handleAdd()}>Add</button> */}

        {props.grouparray.run_top &&
          <div>
            <div className='md:w-[20%] bg-slate-200 rounded place-content-center border border-gray-200 flex justify-center'>
              <span className='text-green-700 font-bold'>วิ่งบน</span>
            </div>
            {props.grouparray.run_top.map((data) => {
              return (
                <div key={data.id}>
                  <div className='numlist border-dashed border-b-[1px] p-1 border-gray-200 flex flex-row gap-3 justify-around'>
                    <span>{data.number}</span>

                    {/* set bet amont */}
                    <input className='text-purple-800 text-center md:inline hidden' type="number" value={data.bet_amout} onChange={(e) => handleChange(e, data.id)} min={1} max={5000} />
                    <div className='flex flex-col md:inline hidden'>
                      {/* get bet payrate from database */}
                      <span className='font-thin text-[10px]'>บาทละ</span>
                      <span className='font-thin text-[13px]'>{data.bet_payrate}</span>
                    </div>
                    <div className='flex flex-col md:inline hidden'>
                      {/* get bet payrate from database */}
                      <span className='font-thin text-[10px]'>ราคาจ่าย</span>
                      <span className='font-thin text-[13px] text-green-600'>{data.bet_amout * data.bet_payrate}</span>
                    </div>

                    <div className='md:inline hidden'>
                      {/* delete all this by the id */}
                    <DeleteForeverIcon onClick={() => handleDelete(data.id)} className='text-red-600' />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        }

        {props.grouparray.run_bot &&
          <div>
            <div className='md:w-[20%] bg-slate-200 rounded place-content-center flex justify-center'>
              <span className='text-green-700 font-bold'>วิ่งล่าง</span>
            </div>
            {props.grouparray.run_bot.map((data) => {
              return (
                <div key={data.id}>
                  <div className='numlist border-dashed border-b-[1px] p-1 border-gray-200 flex flex-row gap-3 justify-around'>
                    <span>{data.number}</span>

                    {/* set bet amont */}
                    <input className='text-purple-800 text-center md:inline hidden' type="number" value={data.bet_amout} onChange={(e) => handleChange(e, data.id)} min={1} max={5000} />
                    <div className='flex flex-col md:inline hidden'>
                      {/* get bet payrate from database */}
                      <span className='font-thin text-[10px]'>บาทละ</span>
                      <span className='font-thin text-[13px]'>{data.bet_payrate}</span>
                    </div>
                    <div className='flex flex-col md:inline hidden'>
                      {/* get bet payrate from database */}
                      <span className='font-thin text-[10px]'>ราคาจ่าย</span>
                      <span className='font-thin text-[13px] text-green-600'>{data.bet_amout * data.bet_payrate}</span>
                    </div>

                    <div className='md:inline hidden'>
                      {/* delete all this by the id */}
                      <DeleteForeverIcon onClick={() => handleDelete(data.id)} className='text-red-600' />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        }



        {props.grouparray.two_top &&
          <div>
            <div className='md:w-[90px] bg-slate-200 rounded place-content-center flex justify-center'>
              <span className='text-blue-700 font-bold'>2 ตัวบน</span>
            </div>
            {props.grouparray.two_top.map((data) => {
              return (
                <div key={data.id}>
                  <div className='numlist border-dashed border-b-[1px] p-1 border-gray-200 flex flex-row gap-1 justify-around'>
                    <span>{data.number}</span>

                    {/* set bet amont */}
                    <input className='text-purple-800 text-center md:inline hidden' type="number" value={data.bet_amout} onChange={(e) => handleChange(e, data.id)} min={1} max={5000} />
                    <div className='flex flex-col md:inline hidden'>
                      {/* get bet payrate from database */}
                      <span className='font-thin text-[10px]'>บาทละ</span>
                      <span className='font-thin text-[13px]'>{data.bet_payrate}</span>
                    </div>
                    <div className='flex flex-col md:inline hidden'>
                      {/* get bet payrate from database */}
                      <span className='font-thin text-[10px]'>ราคาจ่าย</span>
                      <span className='font-thin text-[13px] text-green-600'>{data.bet_amout * data.bet_payrate}</span>
                    </div>

                    <div className='md:inline hidden'>
                      {/* delete all this by the id */}
                      <DeleteForeverIcon onClick={() => handleDelete(data.id)} className='text-red-600' />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        }

        {/* twobot */}

        {props.grouparray.two_bot &&
          <div>
            <div className='md:w-[90px] bg-slate-200 rounded place-content-center flex justify-center'>
              <span className='text-blue-700 font-bold'>2 ตัวล่าง</span>
            </div>
            {props.grouparray.two_bot.map((data) => {
              return (
                <div key={data.id}>
                  <div className='numlist border-dashed border-b-[1px] p-1 border-gray-200 flex flex-row gap-1 justify-around'>
                    <span>{data.number}</span>

                    {/* set bet amont */}
                    <input className='text-purple-800 text-center md:inline hidden' type="number" value={data.bet_amout} onChange={(e) => handleChange(e, data.id)} min={1} max={5000} />
                    <div className='flex flex-col md:inline hidden'>
                      {/* get bet payrate from database */}
                      <span className='font-thin text-[10px]'>บาทละ</span>
                      <span className='font-thin text-[13px]'>{data.bet_payrate}</span>
                    </div>
                    <div className='flex flex-col md:inline hidden'>
                      {/* get bet payrate from database */}
                      <span className='font-thin text-[10px]'>ราคาจ่าย</span>
                      <span className='font-thin text-[13px] text-green-600'>{data.bet_amout * data.bet_payrate}</span>
                    </div>

                    <div className='md:inline hidden'>
                      {/* delete all this by the id */}
                      <DeleteForeverIcon onClick={() => handleDelete(data.id)} className='text-red-600' />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        }
        {/* three top */}

        {props.grouparray.three_top &&
          <div>
            <div className='md:w-[90px] bg-slate-200 rounded place-content-center flex justify-center'>
              <span className='text-red-700 font-bold'>3 ตัวบน</span>
            </div>
            {props.grouparray.three_top.map((data) => {
              return (
                <div key={data.id}>
                  <div className='numlist border-dashed border-b-[1px] p-1 border-gray-200 flex flex-row gap-1 justify-around'>
                    <span>{data.number}</span>

                    {/* set bet amont */}
                    <input className='text-purple-800 text-center md:inline hidden' type="number" value={data.bet_amout} onChange={(e) => handleChange(e, data.id)} min={1} max={5000} />
                    <div className='flex flex-col md:inline hidden'>
                      {/* get bet payrate from database */}
                      <span className='font-thin text-[10px]'>บาทละ</span>
                      <span className='font-thin text-[13px]'>{data.bet_payrate}</span>
                    </div>
                    <div className='flex flex-col md:inline hidden'>
                      {/* get bet payrate from database */}
                      <span className='font-thin text-[10px]'>ราคาจ่าย</span>
                      <span className='font-thin text-[13px] text-green-600'>{data.bet_amout * data.bet_payrate}</span>
                    </div>

                    <div className='md:inline hidden'>
                      {/* delete all this by the id */}
                      <DeleteForeverIcon onClick={() => handleDelete(data.id)} className='text-red-600' />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        }

        {/* three front */}

        {props.grouparray.three_front &&
          <div>
            <div className='md:w-[90px] bg-slate-200 rounded place-content-center flex justify-center'>
              <span className='text-red-700 font-bold'>3 ตัวหน้า</span>
            </div>
            {props.grouparray.three_front.map((data) => {
              return (
                <div key={data.id}>
                  <div className='numlist border-dashed border-b-[1px] p-1 border-gray-200 flex flex-row gap-1 justify-around'>
                    <span>{data.number}</span>

                    {/* set bet amont */}
                    <input className='text-purple-800 text-center md:inline hidden' type="number" value={data.bet_amout} onChange={(e) => handleChange(e, data.id)} min={1} max={5000} />
                    <div className='flex flex-col md:inline hidden'>
                      {/* get bet payrate from database */}
                      <span className='font-thin text-[10px]'>บาทละ</span>
                      <span className='font-thin text-[13px]'>{data.bet_payrate}</span>
                    </div>
                    <div className='flex flex-col md:inline hidden'>
                      {/* get bet payrate from database */}
                      <span className='font-thin text-[10px]'>ราคาจ่าย</span>
                      <span className='font-thin text-[13px] text-green-600'>{data.bet_amout * data.bet_payrate}</span>
                    </div>

                    <div className='md:inline hidden'>
                      {/* delete all this by the id */}
                      <DeleteForeverIcon onClick={() => handleDelete(data.id)} className='text-red-600' />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        }


        {/* three bot */}

        {props.grouparray.three_bot &&
          <div>
            <div className='md:w-[90px] bg-slate-200 rounded place-content-center flex justify-center'>
              <span className='text-red-700 font-bold'>3 ตัวหลัง</span>
            </div>
            {props.grouparray.three_bot.map((data) => {
              return (
                <div key={data.id}>
                  <div className='numlist border-dashed border-b-[1px] p-1 border-gray-200 flex flex-row gap-1 justify-around'>
                    <span>{data.number}</span>

                    {/* set bet amont */}
                    <input className='text-purple-800 text-center md:inline hidden' type="number" value={data.bet_amout} onChange={(e) => handleChange(e, data.id)} min={1} max={5000} />
                    <div className='flex flex-col md:inline hidden'>
                      {/* get bet payrate from database */}
                      <span className='font-thin text-[10px]'>บาทละ</span>
                      <span className='font-thin text-[13px]'>{data.bet_payrate}</span>
                    </div>
                    <div className='flex flex-col md:inline hidden'>
                      {/* get bet payrate from database */}
                      <span className='font-thin text-[10px]'>ราคาจ่าย</span>
                      <span className='font-thin text-[13px] text-green-600'>{data.bet_amout * data.bet_payrate}</span>
                    </div>

                    <div className='md:inline hidden'>
                      {/* delete all this by the id */}
                      <DeleteForeverIcon onClick={() => handleDelete(data.id)} className='text-red-600' />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        }

        {/* three bot */}

        {props.grouparray.three_tod &&
          <div>
            <div className='md:w-[90px] bg-slate-200 rounded place-content-center flex justify-center'>
              <span className='text-red-700 font-bold'>3 ตัวโต๊ด</span>
            </div>
            {props.grouparray.three_tod.map((data) => {
              return (
                <div key={data.id}>
                  <div className='numlist border-dashed border-b-[1px] p-1 border-gray-200 flex flex-row gap-1 justify-around'>
                    <span>{data.number}</span>

                    {/* set bet amont */}
                    <input className='text-purple-800 text-center md:inline hidden' type="number" value={data.bet_amout} onChange={(e) => handleChange(e, data.id)} min={1} max={5000} />
                    <div className='flex flex-col md:inline hidden'>
                      {/* get bet payrate from database */}
                      <span className='font-thin text-[10px]'>บาทละ</span>
                      <span className='font-thin text-[13px]'>{data.bet_payrate}</span>
                    </div>
                    <div className='flex flex-col md:inline hidden'>
                      {/* get bet payrate from database */}
                      <span className='font-thin text-[10px]'>ราคาจ่าย</span>
                      <span className='font-thin text-[13px] text-green-600'>{data.bet_amout * data.bet_payrate}</span>
                    </div>

                    <div className='md:inline hidden'>
                      {/* delete all this by the id */}
                      <DeleteForeverIcon onClick={() => handleDelete(data.id)} className='text-red-600' />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        }


        {/* four top */}

        {props.grouparray.four_top &&
          <div>
            <div className='md:w-[90px] bg-slate-200 rounded place-content-center flex justify-center'>
              <span className='text-yellow-700 font-bold'>4 ตัวบน</span>
            </div>
            {props.grouparray.four_top.map((data) => {
              return (
                <div key={data.id}>
                  <div className='numlist border-dashed border-b-[1px] p-1 border-gray-200 flex flex-row gap-1 justify-around'>
                    <span>{data.number}</span>

                    {/* set bet amont */}
                    <input className='text-purple-800 text-center md:inline hidden' type="number" value={data.bet_amout} onChange={(e) => handleChange(e, data.id)} min={1} max={5000} />
                    <div className='flex flex-col md:inline hidden'>
                      {/* get bet payrate from database */}
                      <span className='font-thin text-[10px]'>บาทละ</span>
                      <span className='font-thin text-[13px]'>{data.bet_payrate}</span>
                    </div>
                    <div className='flex flex-col md:inline hidden'>
                      {/* get bet payrate from database */}
                      <span className='font-thin text-[10px]'>ราคาจ่าย</span>
                      <span className='font-thin text-[13px] text-green-600'>{data.bet_amout * data.bet_payrate}</span>
                    </div>

                    <div className='md:inline hidden'>
                      {/* delete all this by the id */}
                      <DeleteForeverIcon onClick={() => handleDelete(data.id)} className='text-red-600' />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        }

        {/* four tod */}

        {props.grouparray.four_tod &&
          <div>
            <div className='md:w-[90px] bg-slate-200 rounded place-content-center flex justify-center'>
              <span className='text-yellow-700 font-bold'>4 ตัวโต๊ด</span>
            </div>
            {props.grouparray.four_tod.map((data) => {
              return (
                <div key={data.id}>
                  <div className='numlist border-dashed border-b-[1px] p-1 border-gray-200 flex flex-row gap-1 justify-around'>
                    <span>{data.number}</span>

                    {/* set bet amont */}
                    <input className='text-purple-800 text-center md:inline hidden' type="number" value={data.bet_amout} onChange={(e) => handleChange(e, data.id)} min={1} max={5000} />
                    <div className='flex flex-col md:inline hidden'>
                      {/* get bet payrate from database */}
                      <span className='font-thin text-[10px]'>บาทละ</span>
                      <span className='font-thin text-[13px]'>{data.bet_payrate}</span>
                    </div>
                    <div className='flex flex-col md:inline hidden'>
                      {/* get bet payrate from database */}
                      <span className='font-thin text-[10px]'>ราคาจ่าย</span>
                      <span className='font-thin text-[13px] text-green-600'>{data.bet_amout * data.bet_payrate}</span>
                    </div>

                    <div className='md:inline hidden'>
                      {/* delete all this by the id */}
                      <DeleteForeverIcon onClick={() => handleDelete(data.id)} className='text-red-600' />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        }

        {/* first */}

        {props.grouparray.first &&
          <div>
            <div className='md:w-[90px] bg-slate-200 rounded place-content-center flex justify-center'>
              <span className='text-purple-700 font-bold'>รางวัลที่1</span>
            </div>
            {props.grouparray.first.map((data) => {
              return (
                <div key={data.id}>
                  <div className='numlist border-dashed border-b-[1px] p-1 border-gray-200 flex flex-row gap-1 justify-around text-[13px]'>
                    <span>{data.number}</span>

                    {/* set bet amont */}
                    <input className='text-purple-800 text-center md:inline  hidden' type="number" value={data.bet_amout} onChange={(e) => handleChange(e, data.id)} min={1} max={5000} />
                    <div className='flex flex-col md:inline  hidden'>
                      {/* get bet payrate from database */}
                      <span className='font-thin text-[10px]'>บาทละ</span>
                      <span className='font-thin text-[10px]'>{data.bet_payrate}</span>
                    </div>
                    <div className='flex flex-col md:inline  hidden'>
                      {/* get bet payrate from database */}
                      <span className='font-thin text-[10px]'>ราคาจ่าย</span>
                      <span className='font-thin text-[10px] text-green-600'>{data.bet_amout * data.bet_payrate}</span>
                    </div>

                    <div className='md:inline  hidden'>
                      {/* delete all this by the id */}
                      <DeleteForeverIcon onClick={() => handleDelete(data.id)} className='text-red-600' />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        }


      </motion.div>
    )

  )
}

export default Lottotype