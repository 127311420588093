import React, { useEffect, useState } from 'react';
import MyTable from './Table';
import { Link } from 'react-router-dom';
import './dashboard.css';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { motion } from 'framer-motion';
import Navbar from './MemberNav'
import CalculateIcon from '@mui/icons-material/Calculate';
import moment from 'moment';
import CreditImage from '../image/creditlorro.png';
import Innerdashboard from './innerdashboard.jsx';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Footer from './Footer';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AnimateText from './AnimateText'
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import FactCheckIcon from '@mui/icons-material/FactCheck';


function DashboardContent() {
  const user = localStorage.getItem('user');

  const json = { user: user }
  const [credit, setCredit] = useState()
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [ModalEventOpen, setModalEventOpen] = useState(true)
  const [Transaction, setTransaction] = useState(null);
  // console.log(Transaction)

  const handleOpenEventModal = () => {
    setModalEventOpen(true)
  };
  const handleCloseEventModal = () => {
    setModalEventOpen(false)
  };
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    // Make the POST request when the component mounts
    postData();
  }, []);

  const TransactionColumn = [
    {
      Header: 'วัน / เวลา',
      accessor: 'date_time',
      align: 'center',
      Cell: ({ value }) => <span className="text-xs font-bold">
      {moment(value).format('DD-MM-YYYY | HH:mm:ss')}
    </span>,
    },
    {
      Header: 'จำนวน',
      accessor: 'Amout',
      align: 'right',
      Cell: ({ value }) => (
        <span className={`text-xs font-bold ${value < 0 ? 'text-red-500' : 'text-green-500'}`}>
          {value} ฿
        </span>
      ),
    },
    {
      Header: 'หมายเหตุ',
      accessor: 'Sender',
      align: 'right',
      Cell: ({ value }) => <span className="text-xs">ผู้ทำรายการ <span className="text-xs font-bold"> :{value}</span></span>,
    },

  ];

  const postData = async () => {
    const jsonData = {
      user: user,
    };
    try {
      const response = await fetch(`${process.env.REACT_APP_API_KEY}/gettransaction`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonData),
      });

      if (response.ok) {
        const responseData = await response.json();
        setTransaction(responseData);
      } else {
        console.error('Failed to fetch data from the API');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_KEY}/profile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    })
      .then(data => data.json())
      .then(
        (result) => {
          setCredit(result[0].credit)
        }
      )
  }, []);

  return (
    <>
      <Navbar
        username={user}
        balance={credit}
      />
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{
          opacity: 1,
          y: 0,
          scale: [1, 1.05, 1], // Scale animation effect
        }}
        transition={{
          duration: 1, // Duration of the animation
        }} className='inner_content'>
        <div className='flex flex-col xl:w-[75%]  w-[100%] h-auto p-2 rounded space-y-3'>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{
              opacity: 1,
              y: 0,
              scale: [1, 1.05, 1], // Scale animation effect
            }}
            transition={{
              duration: 1, // Duration of the animation
            }} className='md:flex md:flex-row flex-col w-[100%] space-x-1 h-auto border-yellow-400 p-1 bg-white bg-opacity-10 rounded'>

            <div className='flex justify-between flex-row md:w-[70%] bg-gradient-to-r from-purple-900 to-purple-500 rounded h-auto '>
              <div className='w-auto h-full flex-col p-2'>
                <div className='flex flex-row p-1 space-x-2'>
                  <AccountBalanceWalletIcon />
                  <span className='text- text-white'>Credit : </span>
                  <span className='text- text-amber-400'>{user && user}</span>
                </div>
                <span className='text-[50px] font-bold text-amber-200 ml-2'>
                  {credit && credit.toFixed(2)} ฿
                </span>
              </div>
              <div className='w-auto h-full justify-left flex-col'>
                <img src={CreditImage} className="w-[150px]" alt="My Image" />
              </div>
            </div>

            <div className='flex md:flex-col flex-row md:w-[30%] md:space-y-2 bg-white bg-opacity-10 h-auto p-2'>
              <button className="golden-btn h-[100%] w-full" >
                <AddCircleIcon className='text-yellow-900' /> เติมเงิน
              </button>
              <button className="silver-btn h-[100%] w-full" >
                <RemoveCircleIcon className='text-zinc-900' /> ถอนเงิน
              </button>
            </div>
          </motion.div>
          <div className='md:flex flex-col w-[100%] space-y-3 h-auto mt-3 bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 rounded'>
            <AnimateText />
            <div className='md:bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 p-1 w-[100%] h-auto space-y-3 rounded'>
              <div className='md:flex-row flex flex-wrap md:flex-nowrap md:w-[100%] p-1 text-center'>
                <Link className='md:w-full w-1/4' to='/dashboard'>
                  <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{
                      opacity: 1,
                      y: 0,
                      scale: [1, 1.05, 1], // Scale animation effect
                    }}
                    transition={{
                      duration: 1, // Duration of the animation
                    }}
                    whileHover={{ y: -8, scale: 1.02 }}
                    style={{ height: '100% !important' }}
                  >
                    <div className='flex flex-col w-full h-auto justify-center items-center md:px-20 md:py-5 border-r-[1px] text-center border-white '>
                      <CalculateIcon className='text-white' />
                      <span className='text-white text-lg font-bold'>แทงหวย</span>
                    </div>
                  </motion.div>
                </Link>
                <Link className='md:w-full w-1/4' to='/dashboard/orderlist'>
                  <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{
                      opacity: 1,
                      y: 0,
                      scale: [1, 1.05, 1], // Scale animation effect
                    }}
                    transition={{
                      duration: 1, // Duration of the animation
                    }}
                    whileHover={{ y: -8, scale: 1.02 }}
                    style={{ height: '100% !important' }}
                  >
                    <div className='flex flex-col w-full h-auto justify-center items-center md:px-20 md:py-5 border-r-[1px] text-center border-white '>
                      <PlagiarismIcon className='text-white text-lg' />
                      <span className='text-white text-lg font-bold '>ดูโพยหวย</span>
                    </div>
                  </motion.div>
                </Link>
                <Link className='md:w-full w-1/4' to='/dashboard/profile'>
                  <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{
                      opacity: 1,
                      y: 0,
                      scale: [1, 1.05, 1], // Scale animation effect
                    }}
                    transition={{
                      duration: 1, // Duration of the animation
                    }}
                    whileHover={{ y: -8, scale: 1.02 }}
                    style={{ height: '100% !important' }}
                  >
                    <div className='flex flex-col w-full h-auto justify-center items-center md:px-20 md:py-5 border-r-[1px] text-center border-white '>
                      <PersonSearchIcon className='text-white text-lg' />
                      <span className='text-white text-lg font-bold '>ข้อมูลผู้ใช้</span>
                    </div>
                  </motion.div>
                </Link>
                <Link className='md:w-full w-1/4' to='/dashboard/result'>
                  <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{
                      opacity: 1,
                      y: 0,
                      scale: [1, 1.05, 1], // Scale animation effect
                    }}
                    transition={{
                      duration: 1, // Duration of the animation
                    }}
                    whileHover={{ y: -8, scale: 1.02 }}
                    style={{ height: '100% !important' }}
                  >
                    <div className='flex flex-col w-full h-auto justify-center items-center md:px-20 md:py-5 text-center'>
                      <EmojiEventsIcon className='text-white text-lg' />
                      <span className='text-white text-lg font-bold '>ผลหวย</span>
                    </div>
                  </motion.div>
                </Link>
              </div>
              <div className='flex flex-row w-[100%] space-x-2 h-auto'>
                <div onClick={handleOpen} className='w-[50%] flex'>

                  <div className='flex flex-row md:w-[10%] w-[25%] bg-slate-700 rounded-l p-2'>
                    <RequestQuoteIcon />
                  </div>
                  <div className='bg-slate-500 md:w-[90%] shadow-md w-[75%] rounded-r p-2 text-center hover:bg-slate-800'>
                    รายการฝากถอน
                  </div>

                </div>
                <div className='w-[50%] flex'>
                  <div className='flex flex-row md:w-[10%] w-[25%] bg-slate-700 rounded-l p-2'>
                    <LocalActivityIcon />
                  </div>
                  <div onClick={handleOpenEventModal} className='bg-slate-500 md:w-[90%] shadow-md w-[75%] rounded-r p-2 text-center md:text-[16px] text-[14px] hover:bg-slate-800'>
                    ข่าวสารและกิจกรรม
                  </div>
                  <Dialog open={ModalEventOpen} onClose={handleCloseEventModal} fullWidth maxWidth="xs">
                    <DialogTitle className='bg-purple-700'>
                      <LocalActivityIcon className='text-amber-100' /><span className='text-white ml-2'>ข่าวสารและกิจกรรม</span>
                    </DialogTitle>
                    <DialogContent className='pt-3'>
                      {currentPage === 1 && (
                        <div className='p-3' style={{ textAlign: 'center' }}>
                          <img src="https://res.cloudinary.com/dx42ffktb/image/upload/fl_lossy,f_webp,q_auto,f_auto/v1685329162/lt77announce/allbrand_mbvm5a.jpg" alt="Advertisement 1" style={{ width: '100%', height: 'auto' }} />
                          <br />
                          <h3>💷💶💴💵โปรโมชั่นใหม่ 1 !!💵💴💶💷</h3>
                          <p>พบกับโปรโมชั่นใหม่พิเศษ และแจกเยอะกว่าเดิม</p>
                        </div>
                      )}
                      {currentPage === 2 && (
                        <div className='p-3' style={{ textAlign: 'center' }}>
                          <img className='rounded-m' src="https://res.cloudinary.com/dx42ffktb/image/upload/fl_lossy,f_webp,q_auto,f_auto/v1685329162/lt77announce/allbrand_mbvm5a.jpg" alt="Advertisement 1" style={{ width: '100%', height: 'auto' }} />
                          <br />
                          <h3>💷💶💴💵โปรโมชั่นใหม่ 2 !!💵💴💶💷</h3>
                          <p>พบกับโปรโมชั่นใหม่พิเศษ และแจกเยอะกว่าเดิม</p>
                        </div>
                      )}
                      {currentPage === 3 && (
                        <div className='p-3' style={{ textAlign: 'center' }}>
                          <img src="https://res.cloudinary.com/dx42ffktb/image/upload/fl_lossy,f_webp,q_auto,f_auto/v1685329162/lt77announce/allbrand_mbvm5a.jpg" alt="Advertisement 1" style={{ width: '100%', height: 'auto' }} />
                          <br />
                          <h3>💷💶💴💵โปรโมชั่นใหม่ 3 !!💵💴💶💷</h3>
                          <p>พบกับโปรโมชั่นใหม่พิเศษ และแจกเยอะกว่าเดิม</p>
                        </div>
                      )}
                    </DialogContent>
                    <DialogActions>
                      {currentPage > 1 && (
                        <button onClick={() => setCurrentPage(currentPage - 1)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                          กลับ
                        </button>
                      )}
                      {currentPage < 3 && (
                        <button onClick={handleNextPage} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                          ต่อไป
                        </button>
                      )}
                      <button onClick={handleCloseEventModal} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        x
                      </button>
                    </DialogActions>
                  </Dialog>
                </div>
                <Dialog open={open} onClose={handleCloseEventModal} fullWidth maxWidth="md">
                  <DialogTitle className='bg-purple-700'>
                    <FactCheckIcon className='text-amber-100' /><span className='text-white ml-2'>สถานะฝากเงิน</span></DialogTitle>
                  <DialogContent>
                    {/* add your content here */}
                    <div className='w-[100%] mt-1 flex flex-col h-[100%] space-y-1'>
                      
                      <div className='md:flex flex-col w-[100%] bg-sky-50 rounded-t border-black drop-shadow-xl'>
                        <div className='flex flex-row bg-zinc-700 rounded-t p-2 space-x-2'>
                          <span className='text-white'>ประวัติการ รับ/ฝาก เครดิต</span>
                        </div>
                        <div className='p-2 border-black border-[1px]'>
                        <MyTable columns={TransactionColumn} data={Transaction} />
                        </div>

                      </div>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <button onClick={handleClose} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                      ปิดหน้าต่าง
                    </button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
            <div>
            </div>
          </div>
          <div className='md:flex flex-col w-[100%] h-auto bg-sky-50 rounded'>
            <div className='md:bg-slate-100 p-1 w-[100%] h-auto space-y-3 rounded'>
            </div>
            <div className='p-1'>
              <Innerdashboard balance={credit} className='' />
            </div>
          </div>
        </div>
      </motion.div>
      <Footer/>
    </>
  )
    ;
}

export default function Dashboard() {
  return <DashboardContent />;
}