import './App.css';
import LoginPage from './Component/login';
import Dashboard from './Component/dashboard';
import Orderlist from './Component/orderlist/Orderlist'
import Numadd from './Component/numadd';
import {BrowserRouter,Routes,Route} from 'react-router-dom';
import Register from './Component/register';

function App() {
  const token = localStorage.getItem('token');
  const user = localStorage.getItem('user');


  fetch(`${process.env.REACT_APP_API_KEY}/authen`, {
      method: 'POST',
      headers: {"Authorization": `Bearer ${token}`}
    })
      .then(data => data.json())
      .then(data => {
        if(data.status === 'OK'){
          localStorage.setItem('user', data.decoded.username)
        }else{
          localStorage.removeItem('user')
          localStorage.removeItem('token')

        }
        // console.log('Success:', token);
      })
  
  // console.log(token)
  if(!token) {
    return (
      <div>
    <Routes>
      <Route path='*' element={<LoginPage />}/>
      <Route path='/' element={<LoginPage />}/>
    </Routes>
    </div>
    )
  }
  return (
    <div>
  <Routes>
    <Route path='*' element={<a>404</a>}/>
    <Route path='/' element={<LoginPage />}/>
    <Route path='/dashboard/*' element={<Dashboard/>}/>
     {/* <Route path='/numadd' element={<Numadd />}/>  */}
     {/* <Route path='/orderlist' element={<Orderlist />}/>  */}

  </Routes>
  </div>
  );
}

export default App;
