import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import styled from '@mui/system/styled';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom'


function Resultnum(props) {
  const user = localStorage.getItem('user');
  const json = { 
    lottotype: props.type,
    date:props.resultdate,
    round:props.round
  }
  const [first, setFirst] = useState('xxxxx')
  const [three, setThree] = useState('xxx')
  const [twotop, setTwotop] = useState('xx')
  const [twobot, setTwobot] = useState('xx')

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_KEY}/getresultbydate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    })
      .then(data => data.json())
      .then(
        (result) => {
          const first = result.filter(i => i.type === 'first');
          setFirst(first[0].resultnumber)
          const three = result.filter(i => i.type === 'three_top');
          setThree(three[0].resultnumber)
          const twotop = result.filter(i => i.type === 'two_top');
          setTwotop(twotop[0].resultnumber)
          const twobot = result.filter(i => i.type === 'two_bot');
          setTwobot(twobot[0].resultnumber)
        }
      )
  }, []);
  // console.log(profile)


  const Item = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    border: '1px solid',
    borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
    padding: theme.spacing(1),
    borderRadius: '4px',
    textAlign: 'center',
  }));

  return (
    <motion.div
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }} 
    className="flex flex-col w-[100%] h-[auto] justify-center">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className='flex flex-col justify-center'>
          <div className='w-[100%] flex flex-row h-[40px] p-2 bg-slate-700 space-x-3 border-[1px] border-slate-700 rounded-t'>
          <Link to="/dashboard" className='md:w-[5%] text-center bg-purple-500 rounded place-content-center border border-black flex justify-center hover:bg-purple-800'>
                <span className='text-white font-bold'>กลับ</span>
              </Link>
            <EmojiEventsIcon />
            <span className='text-white font-bold text-m'>ผลรางวัล
            {props.type === "Yeekee_5" && " ยี่กี่ปกติ"}
            {props.type === "Yeekee_10" && " ยี่กี่ปกติ"}
            {props.type === "Yeekee_15" && " ยี่กี่ปกติ"}
            </span>
          </div>
          <div className='flex flex-row justify-center space-x-2 bg-slate-200 border-slate-900 border-[1px] p-2 w-[100%] h-[auto] drop-shadow-2xl'>
            <div className='w-[100%] bg-slate-100 border-r-[2px] border-slate-900 rounded'>
              <Box className="p-2 w-full space-y-2 flex flex-col rounded">
                <div className='p-2 space-x-2 flex flex-row items-center font-bold text-m'>
                  <StarOutlineIcon className='text-amber-800' />
                  <span className='text-black text-m'>ผลรางวัล             
                  {props.type === "Yeekee_5" && " ยี่กี่ปกติ"}
                  {props.type === "Yeekee_10" && " ยี่กี่ปกติ"}
                  {props.type === "Yeekee_15" && " ยี่กี่ปกติ"}
                    </span>
                  <div className='bg-slate-700 rounded-lg px-2 text-center'>
                    <span className='text-white text-[10px]'>{props.resultdate} รอบที่ {props.round} </span>
                  </div>
                </div>
                <div className='md:flex flex-col border-[1px]  border-amber-300 w-full rounded-lg'>
                  <div className='md:w-full p-2 rounded-t-lg border-b-[1px] border-amber-300 text-center items-center bg-amber-50'>
                    <span className='text-yellow-700 font-bold text-[15px]'>รางวัลที่ 1</span>
                  </div>
                  <div className='md:w-full p-1 text-center items-center bg-white'>
                    <span className='text-black text-[20px]'>{first}</span>
                  </div>
                </div>
                <div className='md:flex flex-row space-x-1 w-full rounded-lg'>
                  <div className='md:flex flex-col border-[1px]  border-amber-300 w-full rounded-lg'>
                    <div className='md:w-full p-2 rounded-t-lg border-b-[1px] border-amber-300 text-center items-center bg-amber-50'>
                      <span className='text-yellow-700 font-bold text-[15px]'>3 ตัวบน</span>
                    </div>
                    <div className='md:w-full p-1 text-center items-center bg-white'>
                      <span className='text-black text-[20px]'>{three}</span>
                    </div>
                  </div>
                  <div className='md:flex flex-col border-[1px]  border-amber-300 w-full rounded-lg'>
                    <div className='md:w-full p-2 rounded-t-lg border-b-[1px] border-amber-300 text-center items-center bg-amber-50'>
                      <span className='text-yellow-700 font-bold text-[15px]'>2 ตัวบน</span>
                    </div>
                    <div className='md:w-full p-1 text-center items-center bg-white'>
                      <span className='text-black text-[20px]'>{twotop}</span>
                    </div>
                  </div>
                  <div className='md:flex flex-col border-[1px]  border-amber-300 w-full rounded-lg'>
                    <div className='md:w-full p-2 rounded-t-lg border-b-[1px] border-amber-300 text-center items-center bg-amber-50'>
                      <span className='text-yellow-700 font-bold text-[15px]'>2 ตัวล่าง</span>
                    </div>
                    <div className='md:w-full p-1 text-center items-center bg-white'>
                      <span className='text-black text-[20px]'>{twobot}</span>
                    </div>
                  </div>
                </div>
              </Box>
            </div>

          </div>

        </div>
      </div>

    </motion.div>

  )
}

export default Resultnum