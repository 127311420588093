import './Footer.css'

function Footer() {
  return (
    <div className='md:block hidden flex justify-center items-center w-[100%]'>
    <div className='flex flex-col w-[100%] h-auto bg-black border-black border-[1px] text-center'>
      <div className='flex flex-col w-[100%] px-[10em] py-[20px]'>
        <span className='text-gray-400 text-[13px] font-extralight'>
          เว็บแทงหวยออนไลน์ MEESOK.COM เวอร์ชั่นล่าสุด 5.0 วันที่ 22 เมษายน 2566 แทงหวยรวดเร็วกว่า 10 บาทก็เล่นได้ หวยเวียดนาม, หวยลาว, หวยฮานอย, หวยหุ้น เรามีพร้อมให้ทดลองเล่น 24 ชั่วโมงทุกวัน อัตราจ่ายสูง จ่ายจริง มั่นคงที่สุด ต้อง Lotto432
          SECURE WEBSITE  GUARANTEE 100%
        </span>
        <span className='text-gray-400 text-[13px] font-bold mt-2'>
        © 2023 Copyright - <span className='text-purple-900'> MEESOK.COM</span> ALL RIGHTS RESERVED.
        </span>
      </div>
    </div>
  </div>
  
  );
}

export default Footer;
