import mySvg from '../image/meesok.png'
import { motion, AnimatePresence } from 'framer-motion';
import PersonIcon from '@mui/icons-material/Person';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LogoutIcon from '@mui/icons-material/Logout';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const handleLogout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  toast.info('กำลังออกจากระบบ แล้วพบกันใหม่!', {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
  setTimeout(() => {
    window.location.reload(false);
  }, 3000); // Delay for 5 seconds
 


}

function TopNav({ handleSubmit, handleClickOpen, username, balance }) {
  return (

    <motion.div
    initial={{ opacity: 0, y:50 }}
    animate={{ opacity: 1, y: 0 }}
    className='animated-div'
  >
    <div className='flex flex-row justify-between w-[100%] h-[50px] bg-purple-900 border-black border-[1px] fixed top-0 left-0 z-50'>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <img className='h-[150%] md:h-[300%] w-auto self-center' src={mySvg} alt="" />
      <div className='flex space-x-3 flex-row md:w-[20%] w-[65%] p-3'>
        <div className='flex flex-row w-[full] space-x-1 rounded bg-slate-800 p-1'>
          <span className='text-white md:text-xs text-[11px]'>
            <span className='font-bold text-white'>{balance && balance.toFixed(2)} ฿</span>
          </span>
        </div>
        <div className='w-auto h-auto rounded border-black border-1 bg-slate-300'>
          <PersonIcon className='text-white' />
        </div>
        <span className='font-bold text-slate-300'>{username && username}</span>
        <div onClick={handleLogout} className='flex flex-row w-auto rounded bg-red-700 h-auto px-2 hover:bg-red-900'>
          <span className='text-white text-xs'>
            <LogoutIcon className='text-white text-xs' />
          </span>
        </div>
      </div>
    </div>
  </motion.div>
  );
}

export default TopNav;
