import React, { useEffect, useState } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { motion } from 'framer-motion';
import Typography from '@mui/material/Typography';
import LoadingComponent from '../LoadingComponent'; // Replace with the correct path
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DoNotTouchIcon from '@mui/icons-material/DoNotTouch';
import './orderlist.css'
import moment from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';


export default function Orders() {

  const [order, setOrder] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [Modalloading, setModalLoading] = useState(true); // Loading state
  const [orderid, setOrderid] = useState([]);
  const user = localStorage.getItem('user');
  //console.log(order)

  const jsonData = {
    username: user
  }
  const OrderGet = () => {
    fetch(`${process.env.REACT_APP_API_KEY}/orderlist`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonData),
    })
      .then(res => res.json())
      .then(
        (result) => {
          setOrder(result)
          setLoading(false)
        }
      )
  }
  useEffect(() => {
    OrderGet()
  },[])

  //////////////////////// show by id in modal /////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  const [open, setOpen] = React.useState(false);
  const [resdate, setResdate] = React.useState('');
  const [betdate, setBetdate] = React.useState('');
  const [totalbet, setTotalbet] = React.useState('');
  const [totalwin, setTotalwin] = React.useState('');
  const [result, setResult] = useState([]);
  const first = result.filter(item => item.type === 'first');
  const [pollid, setPollid] = React.useState('');

  const handleClickOpen = (id, rdate, bdate, tbet, twin, type, round) => {
    setModalLoading(true)
    const jsonid = {
      orderid: id
    }
    setOpen(true);
    setResdate(rdate)
    setBetdate(bdate)
    setTotalbet(tbet)
    setTotalwin(twin)
    setPollid(id)
    fetch(`${process.env.REACT_APP_API_KEY}/orderid`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonid),
    })
      .then(res => res.json())
      .then(
        (result) => {
          setOrderid(result)
          setModalLoading(false)
        }
      )

    const json = {
      date: moment(rdate).format('YYYY-MM-DD'),
      lottotype: type,
      round: round
    }
    fetch(`${process.env.REACT_APP_API_KEY}/getresultbydate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    })
      .then(res => res.json())
      .then(
        (result) => {
          setResult(result)
          console.log(result)
        }
      )

  };

  const handleClose = () => {
    setOpen(false);
    setResdate('')
    setBetdate('')
    setTotalbet('')
    setTotalwin('')
    setResult([])
    setPollid('')
  };

  function refreshPage() {
    window.location.reload(false);
  }

  const handleDelete = (id) => {
    // Show confirmation dialog to the user
    Swal.fire({
      title: 'ต้องการยกเลิกโพย?',
      text: 'หากคุณยืนยันจะไม่สามารถย้อนกลับโพยได้!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'ใช่, ยกเลิกโพย!',
      cancelButtonText: 'ไม่, เก็บโพยไว้'
    }).then((result) => {
      if (result.isConfirmed) {
        const jsonData = {
          id: id,
          username: user
        }
        console.log(jsonData)
        fetch(`${process.env.REACT_APP_API_KEY}/cancel`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(jsonData),
        })
          .then((response) => {
            if (response.ok) {
              // Show success message
              toast.success('ยกเลิกโพยเรียบร้อย');
              // Refresh the page
              refreshPage();
            } else {
              // Handle server error
              throw new Error('Server Error');
            }
          })
          .catch((error) => {
            // Show error message
            toast.error('มีปัญหาในการยกเลิกโพย โปรดติดต่อเจ้าหน้าที่');
            console.error(error);
          });
      }
    });
  };

  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <motion.div
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }} 
    className="flex flex-col w-[100%] h-[auto] bg-gradient-to-r from-purple-400 from-30% via-purple-450 via-50% to-purple-700 to-90%  justify-center">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className='flex flex-col justify-center'>
          <div className='w-[100%] flex flex-row h-[40px] p-2 bg-slate-700 space-x-3 border-[1px] border-slate-700 rounded-t'>
            <span className='text-white font-bold text-m'>โพยหวยของคุณ </span>
          </div>
          {loading ? (
            <LoadingComponent />
          ) : (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className='flex flex-col justify-center border-[1px] overflow-x-auto border-slate-700 p-1 w-[100%] h-[auto] drop-shadow-2xl'>
              <Grid container spacing={2}>
                {order.map((row) => (
                  <Grid item xs={12} sm={6} md={4} key={row.id}>
                    <Card>
                      <CardContent className="flex flex-col space-y-2 bg-slate-100">
                        <Typography variant="h5" component="div">
                          <div className="flex flex-col space-y-2">
                            <div className="w-[100%] justify-center text-center shadow-md bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 rounded-t">
                              <span className="text-xs text-white">โพยเลขที่ #{row.id}</span>
                              <div className="flex space-x-1 p-2 rounded-sm flex-row bg-slate-200">
                                <div className="w-[50%]">
                                  <div className="">
                                    {row.type === 'th_gov' && (
                                      <div className="flex flex-row border-white text-center justify-center border-[1px] w-auto p-1 h-auto rounded bg-slate-500">
                                        <span className="text-white text-[13px]">รัฐบาลไทย</span>
                                      </div>
                                    )}
                                    {row.type === 'hanoy_PhuQua' && (
                                      <div className="flex flex-row border-white text-center justify-center border-[1px] w-auto p-1 h-auto rounded bg-slate-500">
                                        <span className="text-white text-[13px]">ฮานอย</span>
                                      </div>
                                    )}
                                    {row.type === 'laos_gov' && (
                                      <div className="flex flex-row border-white text-center justify-center border-[1px] w-auto p-1 h-auto rounded bg-slate-500">
                                        <span className="text-white text-[13px]">ลาว</span>
                                      </div>
                                    )}
                                    {row.type === 'Yeekee_5' && (
                                      <div className="flex flex-row border-white text-center justify-center border-[1px] w-auto p-1 h-auto rounded bg-slate-500">
                                        <span className="text-white text-[13px]">ยี่กี</span>
                                      </div>
                                    )}
                                    {row.type === 'Yeekee_10' && (
                                      <div className="flex flex-row border-white text-center justify-center border-[1px] w-auto p-1 h-auto rounded bg-slate-500">
                                        <span className="text-white font-bold text-[15px]">ยี่กี VIP</span>
                                      </div>
                                    )}
                                    {row.type === 'Yeekee_15' && (
                                      <div className="flex flex-row border-red-500 text-center justify-center border-[2px] w-[50px] h-auto rounded bg-gradient-to-r from-purple-600 via-purple-500 to-purple-300">
                                        <span className="text-white font-bold text-[15px]">ยี่กี VVIP</span>
                                      </div>
                                    )}
                                  </div>


                                  <div className="res">
                                    <div className="md:w-[100%] text-center bg-slate-500 rounded place-content-center border-[1px] border-gray-300 flex justify-center">
                                      <span className="text-white text-[13px]">
                                        วันที่ซื้อ  {row.buydate && moment(row.buydate).format("ll")}
                                      </span>
                                    </div>
                                  </div>

                                </div>
                                <div className="w-[50%] flex flex-col">
                                  <div className="pad" align="right">
                                    {row.status === 'wait_result' && (
                                      <div className="flex flex-row border-white text-center justify-center border-[1px] w-[50px] h-auto rounded bg-blue-800 shadow-md">
                                        <span className="text-white text-[13px]">รอผล</span>
                                      </div>
                                    )}
                                    {row.status === 'lose' && (
                                      <div className="flex flex-row border-white text-center justify-center border-[1px] w-[80px] p-[1px] h-auto rounded bg-red-800 shadow-md">
                                        <span className="text-white text-[13px]">ไม่ถูกรางวัล</span>
                                      </div>
                                    )}
                                    {row.status === 'cancel' && (
                                      <div className="flex flex-row border-white text-center justify-center border-[1px] w-[80px] p-[1px] h-auto rounded bg-red-800 shadow-md">
                                        <span className="text-yellow-300 text-[13px]">ยกเลิกโพย</span>
                                      </div>
                                    )}
                                    {row.status === 'win' && (
                                      <div className="flex flex-row border-white text-center justify-center border-[1px] w-[80px] p-[1px] h-auto rounded bg-green-500 shadow-md">
                                        <span className="text-white text-[13px]">ถูกรางวัล</span>
                                      </div>
                                    )}
                                  </div>

                                  <div align="right">
                                    <div className="font-bold res space-x-2">
                                      <span className="text-[10px]">ได้/เสีย</span>
                                      <span className={row.status === "wait_result" ? "text-black" : row.status === "win" ? "text-green-700" : "text-red-700"}>{row.totalwin}฿</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>



                            <div className="pad">

                              <div className="md:w-[100%] bg-slate-200 p-2 rounded border-[1px] border-gray-300 flex justify-between">
                                <div className="flex flex-row space-x-1">
                                  <span className="text-[10px]">ยอดแทง</span>
                                  <span className="text-[18px]">{row.totalbet}฿</span>
                                </div>
                                <div className="flex flex-row space-x-2">
                                  <span className="text-[10px]">งวดที่</span>
                                  <span className="text-[13px]">
                                    {row.resultdate && moment(row.resultdate).format("ll")}
                                    {row.round !== 1 && <> | รอบที่ {row.round}</>}</span>
                                </div>

                              </div>

                            </div>


                            <div className="flex flex-col w-full space-y-2">
                              <div className="pad" align="right">
                                {row.status === 'wait_result' && (
                                  <button
                                    onClick={() => handleDelete(row.id)}
                                    className="bg-slate-200 w-full text-slate-300 mr-1 hover:bg-slate-600 text-[15px] text-slate-500 py-2 px-4 mr-1 rounded"
                                  >
                                    <DoNotTouchIcon /> คืนโพย
                                  </button>
                                )}
                                <button
                                  onClick={() =>
                                    handleClickOpen(
                                      row.id,
                                      row.resultdate,
                                      row.buydate,
                                      row.totalbet,
                                      row.totalwin,
                                      row.type,
                                      row.round
                                    )
                                  }
                                  className="bg-slate-200 shadow-md w-full text-slate-300 mr-1 hover:bg-slate-600 text-[15px] text-slate-500 py-2 px-4 mr-1 rounded"
                                >
                                  <ContentPasteSearchIcon /> ดูโพย
                                </button>
                              </div>
                            </div>
                          </div>
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              {open &&
                <div>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={isMobile ? true : false} fullScreen={isMobile ? true : false}
                  >
                    <DialogTitle className="text-white bg-gradient-to-r from-purple-800 text-white" id="alert-dialog-title">
                      {"รายละเอียดโพย"}
                    </DialogTitle>

                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {Modalloading ? (
                          <LoadingComponent />
                        ) : (
                          <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            className="flex flex-col space-y-1 p-1 w-[100%] h-[100%]">

                            <div className="w-[100%] justify-center text-center bg-zinc-600 rounded-t">
                              <span className="text-m text-white font-bold">โพยเลขที่ {pollid}</span>
                              <div className="flex flex-col w-[100%] p-1 h-auto bg-slate-100 rounded-b border-[1px] border-black">
                                <div className="flex flex-row w-[100%] justify-between">
                                  <div className="w-[50] p-1">
                                    <span className="text-xs text-black font-thin">รอบวันที่ {moment(resdate).format("ll")}</span>

                                  </div>
                                  <div className="flex flex-row p-2 space-x-2 w-[50]">
                                    <span className="text-xs text-green-800 font-bold">ทำรายการเมื่อ {moment(betdate).format("ll")}</span>
                                  </div>
                                </div>
                                <div className="flex flex-row w-[100%] border-slate-400 border-t-[1px]">
                                  <div className='flex flex-col w-full h-auto justify-center items-center md:px-20 py-5 border-r-[1px] text-center border-slate-400 hover:bg-slate-200 text-amber-500'>
                                    <span className='text-zinc-600 text-xs font-thin'>ยอดแทง</span>
                                    <span className='text-zinc-600 text-sm text-blue-500 font-thin'>฿ {totalbet}</span>
                                  </div>
                                  <div className='flex flex-col w-full h-auto justify-center items-center md:px-20 py-5 text-center border-slate-400 hover:bg-slate-200 text-amber-500'>
                                    <span className='text-zinc-600 text-xs font-thin'>ผลได้เสีย</span>
                                    <span className='text-zinc-600 text-sm text-black font-thin'>฿ {totalwin}</span>
                                  </div>
                                </div>
                              </div>
                            </div>



                            <div className='w-[100%] flex flex-wrap justify-between flex-row'>
                              {orderid.map((row) => (
                                <div className="flex flex-col w-[49%] md:h-[130px space-y-2 bg-slate-50 border-[1px] border-black drop-shadow-xl rounded">
                                  <div className="w-[100%] flex flex-row justify-between p-1 text-center bg-zinc-50 rounded-t border-b-[1px] border-black">
                                    <span className="text-xs text-black font-thin">ลำดับ #00{row.id}</span>
                                    {row.result === 'wait_result' &&
                                      <div className="flex flex-row border-white text-center shadow-md justify-center border-[1px] w-[50px] h-auto rounded bg-blue-800">
                                        <span className="text-white font-bold text-[15px]">รอผล</span>
                                      </div>}
                                    {row.result === 'lose' && <div className="flex flex-row shadow-md border-white text-center justify-center border-[1px] w-[80px] p-[1px] h-auto rounded bg-red-800">
                                      <span className="text-white font-bold text-[10px]">ไม่ถูกรางวัล</span>
                                    </div>}
                                    {row.result === 'cancel' && <div className="flex flex-row shadow-md border-white text-center justify-center border-[1px] w-[80px] p-[1px] h-auto rounded bg-yellow-800">
                                      <span className="text-white font-bold text-[10px]">ยกเลิกโพย</span>
                                    </div>}
                                    {row.result === 'win' && <div className="flex flex-row shadow-md border-white text-center justify-center border-[1px] w-[80px] p-[1px] h-auto rounded bg-green-500">
                                      <span className="text-white font-bold text-[10px]">ถูกรางวัล</span>
                                    </div>}
                                  </div>
                                  <div className="w-[100%] h-auto flex flex-row p-1 text-center bg-slate-50">
                                    <div className="w-[100%] h-auto justify-center items-center flex flex-row ">
                                      <div className='flex flex-col w-full h-auto justify-center items-center p-2 text-center bg-slate-200 rounded mr-1'>
                                        <span className='text-zinc-600 text-lg text-blue-500 font-thin'>{row.number}</span>
                                        <span className='text-zinc-600 text-xs font-thin'>
                                          {row.bet_type === 'two_top' && "สองตัวบน"}
                                          {row.bet_type === 'two_bot' && "สองตัวล่าง"}
                                          {row.bet_type === 'three_top' && "สามตัวบน"}
                                          {row.bet_type === 'three_bot' && "สามตัวล่าง"}
                                          {row.bet_type === 'three_tod' && "สามตัวโต๊ด"}
                                          {row.bet_type === 'three_front' && "สามตัวหน้า"}
                                          {row.bet_type === 'four_top' && "สี่ตัวบน"}
                                          {row.bet_type === 'four_tod' && "สี่ตัวโต๊ด"}
                                          {row.bet_type === 'run_top' && "วิ่งบน"}
                                          {row.bet_type === 'run_bot' && "วิ่งล่าง"}
                                          {row.bet_type === 'first' && "รางวัลที่ 1"}

                                        </span>
                                      </div>
                                      <div className='flex flex-col w-full h-auto justify-center items-center px-2 text-center border-l-[1px] border-slate-200'>
                                        <div className="w-[100%] flex flex-row justify-between border-b-[1px] border-slate-200">
                                          <div>
                                            <span className='text-zinc-600 text-[10px] text-black font-thin'>เลขที่ออก</span>
                                          </div>

                                          <div className='flex items-center'>
                                            <span className='text-zinc-600 text-[25px] text-purple-600 font-thin'>
                                              {first.map((row) => (
                                                row.resultnumber
                                              ))}
                                            </span>
                                          </div>

                                        </div>
                                        <div className="w-[100%] flex flex-row justify-between border-b-[1px] border-slate-200">
                                          <div>
                                            <span className='text-zinc-600 text-[10px] text-black font-thin'>ราคาจ่าย</span>
                                          </div>
                                          <div>
                                            <span className='text-zinc-600 text-[10px] text-black font-thin'>฿ {row.bet_payrate}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w-[100%] flex flex-row justify-between p-1 text-center bg-zinc-50 rounded-b border-t-[1px] border-slate-200">
                                    <span className="text-xs text-slate-500 font-thin">ราคาแทง: ฿ {row.bet_amout}</span>
                                    <div className="flex flex-row ">
                                      <span className="text-slate-500 font-bold text-[10px]">ผลได้เสีย : ฿ {row.win_amout}</span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </motion.div>
                        )}
                      </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                      <button onClick={handleClose} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        ปิดหน้าต่าง
                      </button>
                    </DialogActions>
                  </Dialog>
                </div>}

            </motion.div>

          )}
        </div>
      </div>

    </motion.div>
  );
}