import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import moment from 'moment'
import './Lottoresult.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingComponent from './LoadingComponent'; // Replace with the correct path


function Lottoresult() {

  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [lottotype, setLottotype] = useState('th_gov');
  const [startDate, setStartDate] = useState(new Date());
  const [round, setRound] = useState(1);


  const ResGetbydate = () => {
    const json = {
      date: moment(startDate).format('YYYY-MM-DD'),
      lottotype: lottotype,
      round: round
    }
    fetch(`${process.env.REACT_APP_API_KEY}/getresultbydate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    })
      .then(res => res.json())
      .then(
        (result) => {
          setResult(result)
          setLoading(false)
        }
      )
  }

  useEffect(() => {
    setLoading(true)
    ResGetbydate()
  }, [startDate, lottotype, round])



  const ResGet = () => {
    const json = {
      lottotype: lottotype
    }

    fetch(`${process.env.REACT_APP_API_KEY}/getlastresult`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    })
      .then(res => res.json())
      .then(
        (result) => {
          setResult(result)
          setLoading(false)
        }
      )
  }
  useEffect(() => {
    setLoading(true)
    ResGet()
    // console.log(result)
  }, [])

  const first = result.filter(item => item.type === 'first');
  const threefront = result.filter(item => item.type === 'three_front');
  const threebot = result.filter(item => item.type === 'three_bot');
  const twobot = result.filter(item => item.type === 'two_bot');

  const createOptions = (listnum) => {
    const options = [];
    for (let i = 1; i <= listnum; i++) {
      options.push(<option key={i} value={i}>{i}</option>);
    }
    return options;
  }

  const Yeekee5 = createOptions(288)
  const Yeekee10 = createOptions(144)
  const Yeekee15 = createOptions(96)
  const jackpot = createOptions(7)





  return (
    <motion.div
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
     className="flex flex-col w-[100%] h-[auto] justify-center">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className='flex flex-col justify-center'>
          <div className='w-[100%] flex flex-row h-[40px] p-2 bg-slate-700 space-x-3 border-[1px] border-slate-700 rounded-t'>
            <span className='text-white font-bold text-m'>ตรวจผลหวย </span>
          </div>
          <div className='flex flex-row justify-center space-x-2 bg-slate-200 border-slate-900 border-[1px] p-2 w-[100%] h-[auto] drop-shadow-2xl'>
            
          <div className='md:flex flex-col w-[100%] flex-row bg-sky-50 rounded-t border-black drop-shadow-xl'>
            <div className='flex flex-row bg-zinc-700 rounded-t p-2 space-x-2'>
              <div>
                <h3>วันที่ผลออก</h3>
                <DatePicker className='text-purple-900 rounded md:w-[100%] w-[100px]' selected={startDate} onChange={(date) => setStartDate(date)} />
              </div>

              <div >
                        <h3>เลือกประเภทหวย</h3>  
                        <select className='text-purple-900 rounded ' value={lottotype} onInput={(e) => {
                          setLottotype(e.target.value)
                          setRound(1)
                        }}>
                          <option value="th_gov">หวยรัฐบาลไทย</option>
                          <option value="laos_gov">หวยลาว</option>
                          <option value="hanoy_PhuQua">หวยฮานอย</option>
                          <option value="Yeekee_5">ยี่กี่ ปกติ</option>
                          <option value="Yeekee_10">ยี่กี่ VIP</option>
                          <option value="Yeekee_15">ยี่กี่ VVIP</option>
                        </select>
              </div>

                <div>
                  <h3>เลือกรอบ</h3>
                  <select className='text-purple-900 rounded'  value={round} onInput={(e) => setRound(e.target.value)}>
                    {lottotype === "Yeekee_5" && Yeekee5}
                    {lottotype === "Yeekee_10" && Yeekee10}
                    {lottotype === "Yeekee_15" && Yeekee15}
                    {lottotype === "hanoy_PhuQua" && jackpot}
                  </select>
                </div>
            </div>

                <div className='p-2 border-black border-[1px]'>
                  <table className="table-auto rounded w-[100%] border-collapse border border-gray-400">
                    <thead>
                      <tr className='bg-slate-200  text-zinc-500 md:text-base text-[0.75rem]'>
                        <th className="md:px-4 px-1 py-2 border border-gray-400 ">รอบการออกรางวัล</th>
                        <th className="md:px-4 px-1 py-2 border border-gray-400">ผลการออกรางวัล</th>
                        <th className="md:px-4 px-1 py-2 border border-gray-400">3ตัวหน้า</th>
                        <th className="md:px-4 px-1 py-2 border border-gray-400">3ตัวล่าง</th>
                        <th className="md:px-4 px-1 py-2 border border-gray-400">2ตัวล่าง</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='md:text-base text-[0.75rem]'>
                        <td className="md:px-10 px-1 py-2 border border-gray-400 px-4 py-1">
                          <div className='md:w-[100%] bg-slate-200 rounded place-content-center border border-gray-200 flex justify-center'>
                            {first.map((row) => (
                              <span className='text-green-700 font-bold'>{moment(row.date).format('ll')}</span>
                            ))}
                          </div>
                        </td>

                        <td className="flex flex-row justify-center md:px-4 px-1 py-2 space-x-2">
                          {first.map((row) => (
                            <div className='md:w-[100%] flex md:flex-row flex-col justify-center bg-slate-200 rounded place-content-center flex justify-center'>
                              {row.resultnumber.split('').map((digit) => (
                                <span className='text-red-800 font-bold' key={digit}>{digit}</span>
                              ))}
                            </div>
                          ))}
                        </td>

                        <td className="md:px-4 px-1 py-2 border border-gray-400">
                          <div className='flex flex-row space-x-2'>
                            <div className='md:w-[100%] flex md:flex-row flex-col bg-slate-200 rounded place-content-center border border-gray-200 flex justify-center'>
                              {threefront.map((row) => (
                                <a className='text-zinc-600 font-bold' style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>{row.resultnumber}</a>
                              ))}
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-2 border border-gray-400">
                          <div className='flex flex-row space-x-2'>
                            <div className='md:w-[100%] md:flex-row flex-col bg-slate-200 rounded place-content-center border border-gray-200 flex justify-center'>
                              {threebot.map((row) => (
                                <a className='text-zinc-600 font-bold' style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>{row.resultnumber}</a>
                              ))}
                            </div>
                          </div></td>
                        <td className="px-4 py-2 border border-gray-400">
                          <div className='flex flex-row space-x-2'>
                            <div className='md:w-[100%] bg-slate-200 rounded place-content-center border border-gray-200 flex justify-center'>
                              {twobot.map((row) => (
                                <a className='text-zinc-600 font-bold' style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>{row.resultnumber}</a>
                              ))}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>

          </div>

        </div>
      </div>

    </motion.div>
  )
}

export default Lottoresult