import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import Numadd from './numadd'
import Orderlist from './orderlist/Orderlist'
import Profile from './profile'
import Lotteryselect from './lotteryselect'
import moment from 'moment'
import { useState } from 'react'
import Roundselect from './roundselect'
import Lottoresult from './Lottoresult'
import Hanoiroundselect from './hanoiroundselect'
import Resultnum from './latest'
import { v4 as uuidv4 } from 'uuid';
function Innerdashboard(props) {

  const [date, setDate] = useState();
  const [loading, setLoading] = useState(true); // Loading state
  const OrderGet = () => {
    fetch(`${process.env.REACT_APP_API_KEY}/nextresultdate`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          setDate(moment(result[0].resultdate).format("YYYY-MM-DD"))
        }
      )
  }
  useEffect(() => {
    OrderGet()
  }, [])

  const [now, setNow] = useState(new Date())
  const todaydate = moment().format("YYYY-MM-DD")


  useEffect(() => {
    const intervalId = setInterval(() => {
      const today = new Date()
      setNow(today);
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);


  const roundgen = (type, round, mpr) => {
    const buttons = [];
    let time = 0;
    // const now = new Date(); // get the current time
    for (let i = 0; i < round; i++) {
      let hours = Math.floor(time / 3600).toString().padStart(2, '0');
      let minutes = Math.floor((time % 3600) / 60).toString().padStart(2, '0');
      const roundStartTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
      if (roundStartTime < now) {
      } else {
        buttons.push(
          <Route key={uuidv4()} path={`/${type}/${i + 1}`} element={<Numadd balance={props.balance} type={type} resultdate={todaydate} round={i + 1} />} />
        );
      }
      time += mpr; // add 5 minutes
    }
    return buttons;
  }

  const resultroundgen = (type, round, mpr) => {
    const buttons = [];
    let time = 0;
    // const now = new Date(); // get the current time
    for (let i = 0; i < round; i++) {
      let hours = Math.floor(time / 3600).toString().padStart(2, '0');
      let minutes = Math.floor((time % 3600) / 60).toString().padStart(2, '0');
      const roundStartTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
      if (roundStartTime < now) {
        buttons.push(
          <Route key={uuidv4()} path={`/${type}/${i + 1}`} element={<Resultnum type={type} resultdate={todaydate} round={i + 1} />} />
        );
      } else {
      }
      time += mpr; // add 5 minutes
    }
    return buttons;
  }

  function getNextRoutine() {
    const today = new Date();
    const dayOfWeek = today.toLocaleDateString('en-US', { weekday: 'long' });

    switch (dayOfWeek) {
      case "Monday":
        if (today.getHours() >= 21) {
          const nextWednesday = new Date();
          nextWednesday.setDate(today.getDate() + (3 + 7 - today.getDay()) % 7);
          return nextWednesday;
        } else {
          return today;
        }
        break;
      case "Tuesday":
        const nextWednesday = new Date();
        nextWednesday.setDate(today.getDate() + (3 + 7 - today.getDay()) % 7);
        return nextWednesday;
        break;
      case "Wednesday":
        if (today.getHours() >= 21) {
          const nextFriday = new Date();
          nextFriday.setDate(today.getDate() + (5 + 7 - today.getDay()) % 7);
          return nextFriday;
        } else {
          return today;
        }
        break;
      case "Thursday":
        const nextFriday = new Date();
        nextFriday.setDate(today.getDate() + (5 + 7 - today.getDay()) % 7);
        return nextFriday;
        break;
      case "Friday":
        if (today.getHours() >= 21) {
          const nextMonday = new Date();
          nextMonday.setDate(today.getDate() + (1 + 7 - today.getDay()) % 7);
          return nextMonday;
        } else {
          const nextToday = new Date(today);
          nextToday.setDate(today.getDate() + 1);
          return nextToday;
        }
        break;
      case "Saturday":
        const nextMonday = new Date();
        nextMonday.setDate(today.getDate() + (1 + 7 - today.getDay()) % 7);
        return nextMonday;
        break;
      case "Sunday":
        const nextMonday2 = new Date();
        nextMonday2.setDate(today.getDate() + (1 + 7 - today.getDay()) % 7);
        return nextMonday2;
        break;
    }
  }


  const targetDate = moment(`${date} 15:30`, 'YYYY/MM/DD HH:mm');
  const [countdownEnded, setCountdownEnded] = useState(false);

  const hanoiresultdate = moment().format('YYYY-MM-DD')
  const targetDatehanoi = moment(`${hanoiresultdate} 18:15`, 'YYYY/MM/DD HH:mm');
  const [countdownEndedhanoi, setCountdownEndedhanoi] = useState(false);

  const laosresultdate = moment(getNextRoutine()).format('YYYY-MM-DD')
  const targetDatelaos = moment(`${laosresultdate} 21:00`, 'YYYY/MM/DD HH:mm');
  const [countdownEndedlaos, setCountdownEndedlaos] = useState(false);


  useEffect(() => {
    dateGet()
    setInterval(() => {
      const diff = targetDate.diff(moment());
      const diffhn = targetDatehanoi.diff(moment());
      const diffla = targetDatelaos.diff(moment());

      if (diff <= 0) {
        setCountdownEnded(true);
      }
      if (diffhn <= 0) {
        setCountdownEndedhanoi(true);
      }
      if (diffla <= 0) {
        setCountdownEndedlaos(true);
      }

    }, 1000);
  }, []);

  function getDate(time) {
    const now = moment();
    const [hours, minutes] = time.toString().split('.').map(parseFloat);
    const targetTime = moment().set({ hours, minutes, seconds: 0 });

    if (now > targetTime) {
      const tomorrow = moment(now).add(1, 'day');
      return tomorrow.format('YYYY-MM-DD');
    } else {
      return now.format('YYYY-MM-DD');
    }
  }
  const [datethschedule, setDatethschedule] = useState();

  const dateGet = () => {
    fetch(`${process.env.REACT_APP_API_KEY}/nextresultdate`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          setDatethschedule(result)
          setLoading(false)
        }
      )
  }


  return (
    <>
      <Routes>
        <Route path='/' element={<Lotteryselect resultdate={date} />} />

        {countdownEnded ? <Route path='/thgov' element={<h1>ปิดรับแทง</h1>} />
          : <Route path='/thgov' element={<Numadd balance={props.balance} type='th_gov' resultdate={datethschedule} round={1} />} />}



        <Route path='/hanoi' element={<Hanoiroundselect />} />
        <Route path='/hanoi/1' element={<Numadd balance={props.balance} type='hanoy_PhuQua' resultdate={getDate(16.30)} round={1} />} />
        <Route path='/hanoi/2' element={<Numadd balance={props.balance} type='hanoy_PhuQua' resultdate={getDate(17.30)} round={2} />} />
        <Route path='/hanoi/3' element={<Numadd balance={props.balance} type='hanoy_PhuQua' resultdate={getDate(18.30)} round={3} />} />
        <Route path='/hanoi/4' element={<Numadd balance={props.balance} type='hanoy_PhuQua' resultdate={getDate(19.30)} round={4} />} />
        <Route path='/hanoi/5' element={<Numadd balance={props.balance} type='hanoy_PhuQua' resultdate={getDate(20.30)} round={5} />} />

        {countdownEndedlaos ? <Route path='/laosgov' element={<h1>ปิดรับแทง</h1>} />
          : <Route path='/laosgov' element={<Numadd balance={props.balance} type='laos_gov' resultdate={laosresultdate} round={1} />} />}


        <Route path='/Yeekee_5' element={<Roundselect type='Yeekee_5' resultdate={date} numberofrond={288} timeperround={300} />} />
        {roundgen('Yeekee_5', 288, 300)}
        {resultroundgen('Yeekee_5', 288, 300)}
        <Route path='/Yeekee_10' element={<Roundselect type='Yeekee_10' resultdate={date} numberofrond={144} timeperround={600} />} />
        {roundgen('Yeekee_10', 144, 600)}
        {resultroundgen('Yeekee_10', 144, 600)}
        <Route path='/Yeekee_15' element={<Roundselect type='Yeekee_15' resultdate={date} numberofrond={96} timeperround={900} />} />
        {roundgen('Yeekee_15', 96, 900)}
        {resultroundgen('Yeekee_15', 96, 900)}
        <Route path='/orderlist' element={<Orderlist />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/lottery' element={<Lotteryselect />} />
        <Route path='/result' element={<Lottoresult />} />

      </Routes>
    </>
  )
}

export default Innerdashboard