import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
// import {BrowserRouter,Routes,Route} from 'react-router-dom';
// import Dashboard from './Component/dashboard';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <div className="root">
    <BrowserRouter>
    <App/>
    </BrowserRouter>
   
  </div>
);

